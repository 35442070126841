import { Link, useLocation, useNavigate } from "react-router-dom"
import { FormEvent }                      from "react"
import { useAuth }                        from "../../auth"


export function Login() {
    let navigate = useNavigate();
    let location = useLocation();
    let params   = new URLSearchParams(location.search);
    let from     = location.state?.from?.pathname || params.get("from") || "/";

    const { login, loading, error } = useAuth()

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()
        const formData = new FormData(e.target as HTMLFormElement)    
        // console.log(login, loading, error, formData)
        login(formData, () => {
        
            // console.log(login, loading, error)

            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page. This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for
            // the user experience.
            // if (!error) {
                navigate(from, { replace: true });
            // }
        })
    }

    return (
        <form
            className="d-flex flex-grow-1 align-self-center align-items-center"
            method="post"
            encType="application/x-www-form-urlencoded"
            autoCapitalize="none"
            onSubmit={handleSubmit}
        >
            <div>
                <div className="justify-content-center">
                    <div className="mx-3" style={{ width: "24em", maxWidth: "90vw" }}>
                        <div className="px-4 pt-4">
                            { error ? (
                                <div className="alert alert-danger">{ error + "" }</div>
                            ) : null}
                            { location.state?.message && <div className="alert alert-info">{ location.state.message }</div> }
                            <div className="mb-3">
                                <label className="form-label fw-bold text-primary-emphasis" htmlFor="email">Email address</label>
                                <input className="form-control" id="email" type="email" name="email" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold text-primary-emphasis" htmlFor="password">Password</label>
                                <input className="form-control" id="password" type="password" name="password" />
                            </div>
                            <div className="form-check mb-3">
                                <input className="form-check-input" id="remember" type="checkbox" value="1" name="remember" />
                                <label className="form-check-label" htmlFor="remember">Remember Me</label>
                            </div>
                            <div className="text-center">
                                <hr/>
                                <button className="btn btn-primary px-5 mt-2" type="submit" disabled={loading}>
                                {loading ? "Logging in..." : "Login"}
                                </button>
                            </div>
                        </div>
                        <div className="py-4 px-0 d-flex justify-content-center flex-wrap">
                            <Link to="/register" className="my-2 mx-4">Create&nbsp;Account</Link>
                            <Link to="/reset" className="my-2 mx-4">Reset&nbsp;Password</Link>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}