export function humanizeDuration(n: number, {
    limit     = 2,
    showZero  = false,
    separator = ", ",
    short     = false,
    humanJoin = false
}: {
    limit    ?: number
    showZero ?: boolean
    separator?: string
    short    ?: boolean
    humanJoin?: boolean
} = {}) {

    if (n <= 0) {
        return "0s"
    }
    
    const META = [
        { name: " week"       , short: "wk" , q : 1 * 1000 * 60 * 60 * 24 * 7 },
        { name: " day"        , short: "d"  , q : 1 * 1000 * 60 * 60 * 24     },
        { name: " hour"       , short: "hr" , q : 1 * 1000 * 60 * 60          },
        { name: " minute"     , short: "min", q : 1 * 1000 * 60               },
        { name: " second"     , short: "s"  , q : 1 * 1000                    },
        { name: " millisecond", short: "ms" , q : 1                           }
    ]

    const str = []

    for (const meta of META) {

        const floor = Math.floor( n / meta.q )
        const abbr  = floor === 1 ? 
            short ? meta.short : meta.name :
            short ? meta.short : meta.name + "s";

        if (floor || showZero) {
            str.push(String(floor) + abbr);
        }
        n -= floor * meta.q;

        if (str.length >= limit) {
            break;
        }
    }

    if (humanJoin && str.length > 1) {
        let last = str.pop()
        str.push("and " + last)
    }

    return str.join(separator);
};

export async function request<T=any>(url: string | URL, options: RequestInit = {}): Promise<{ response: Response, body: T }> {
    
    const token = document.querySelector('meta[name="csrf-token"]')!.getAttribute("content")
    
    const response = await fetch(url, {
        mode: "cors",
        credentials: "include",
        ...options,
        headers: {
            "x-csrf-token": token,
            ...options.headers
        }
    });
    
    let type = response.headers.get("Content-Type") + "";

    let body = await response.text();

    if (body.length && type.match(/\bjson\b/i)) {
        body = JSON.parse(body);
    }

    if (!response.ok) {
        // throw new HttpError(response.status, body || response.statusText)
        // @ts-ignore
        throw new Error(body?.message || body || response.statusText)
    }

    return {
        response,
        body: body as T
    };
}

export function classList(map: Record<string, boolean>): string | undefined {
    let cls: string[] = [];
    for (let name in map) {
        if (name && name !== "undefined" && map[name]) {
            cls.push(name)
        }
    }
    return cls.join(" ") || undefined
}

export function getPath(obj: Record<string, any>, path = ""): any {
    path = path.trim();
    if (!path) {
        return obj;
    }

    let segments = path.split(".");
    let result = obj;

    while (result && segments.length) {
        const key = segments.shift();
        if (!key && Array.isArray(result)) {
            return result.map(o => getPath(o, segments.join(".")));
        } else {
            result = result[key as string];
        }
    }

    return result;
}

export function wait(ms: number, signal?: AbortSignal): Promise<void> {

    // If waiting is aborted resolve immediately
    if (signal?.aborted) {
        return Promise.resolve()
    }

    return new Promise(resolve => {
        
        const timer = setTimeout(doResolve, ms);

        function doResolve() {
            clearTimeout(timer)
            if (signal) {
                signal.removeEventListener("abort", doResolve)
            }
            resolve()
        }

        if (signal) {
            signal.addEventListener("abort", doResolve)
        }
    });
}
export function pipe<T=any>({
    url,
    onData,
    requestOptions = {},
    waitBeforeRetry = 1000
}: {
    url: string | URL
    requestOptions?: RequestInit
    waitBeforeRetry?: number
    onData: (body: T) => void
}) {
    const abortController = new AbortController()
    const { signal } = abortController

    async function ping() {
        if (!signal.aborted) {
            try {
                let { response, body } = await request<T>(url, { ...requestOptions, signal });
                if (response.status !== 200) {
                    await wait(waitBeforeRetry, signal);
                    await ping();
                } else {
                    onData(body)
                    await ping();
                }
            } catch {
                await wait(waitBeforeRetry, signal);
                await ping();
            }
        }
    }

    ping()

    return (reason?: any) => {
        abortController.abort(reason)
    }
}

export function objectDiff<T=Record<string, any>|any[]>(a: T, b: T): T {
    
    const isArray = Array.isArray(a);

    let out = isArray ? [] as any[] : {} as Record<string, any>;

    forEach(a, (value, key) => {
        const valueA = value;
        // @ts-ignore
        const valueB = b[key];
        const typeA  = Object.prototype.toString.call(valueA);
        const typeB  = Object.prototype.toString.call(valueB);

        if (typeA !== typeB) {
            // @ts-ignore
            out[key] = valueA
        } else if (typeA === "[object Object]" || typeA === "[object Array]") {
            const child = objectDiff(valueA, valueB)
            if (!isEmptyObject(child)) {
                // @ts-ignore
                out[key] = child
            }
        } else if (valueA !== valueB) {
            // @ts-ignore
            out[key] = valueA
        }
    })

    return out as T;
}

export function isEmptyObject(x: Record<string, any>): boolean {
    for (let _ in x) {
        return false
    }
    return true
}

export function forEach<T=Record<string, any>|any[]>(o: T, cb: (item: any, key: string | number, all: T) => void) {
    if (Array.isArray(o)) {
        for ( let i = 0; i < o.length; i++ ) {
            cb(o[i], i, o);
        }
    } else {
        for(let key in o) {
            cb(o[key], key, o)
        }
    }
}

export const NameValuePairsList = {
    toObject: (list: [string, string][], duplicatesStrategy: "replace" | "list" | "ignore"): Record<string, string> => {
        const result = {}
        for (const [name, value] of list) {
            if (name in result) {
                if (value) {
                    if (duplicatesStrategy === "replace") {
                        result[name] = value
                    } else if (duplicatesStrategy === "list") {
                        result[name] += ", " + value
                    }
                }
            } else {
                result[name] = value
            }
        }
        return result
    },
    fromObject: (obj: Record<string, string>): [string, string][] => {
        const out = []
        for (const key in obj) {
            out.push([key, obj[key]])
        }
        return out
    },
    fromURLSearchParams: (q: URLSearchParams) => {
        const out = []
        for (const [key, value] of q.entries()) {
            out.push([key, value])
        }
        return out
    },
    toURLSearchParams: (list: [string, string][]) => {
        const query = new URLSearchParams()
        for (const [key, value] of list) {
            query.append(key, value)
        }
        return query
    }
}

/**
 * Rounds the given number @n using the specified precision.
 */
export function roundToPrecision(n: string | number, precision?: number) {
    n = parseFloat(n + "");

    if ( isNaN(n) || !isFinite(n) ) {
        return NaN;
    }

    if ( !precision || isNaN(precision) || !isFinite(precision) || precision < 1 ) {
        n = Math.round( n );
    }
    else {
        const q = Math.pow(10, precision);
        n = Math.round( n * q ) / q;
    }

    return n;
}