import Alert from "../Alert"


export default function ErrorAlert({ children = "Unknown error", className }: {
    children ?: JSX.Element | string | Error | (JSX.Element | string | Error)[]
    className?: string
}) {
    children = Array.isArray(children) ? children : [children]
    return (
        <Alert type="danger" icon={<i className="bi bi-exclamation-circle-fill me-2" />} heading="Error" className={className}>
            { children.map((c, i) => {
                if (c instanceof Error) {
                    return <span key={i}>{ c.message }</span>
                }
                return <span key={i}>{ c }</span>
            })}
        </Alert>
    )
}