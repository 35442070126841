import { Link, useNavigate, useParams } from "react-router-dom"
import { useCallback, useState }        from "react"
import NavigationButtons                from "./NavigationButtons"
import { request }                      from "../../lib"
import { useBackend }                   from "../../hooks"
import ErrorAlert                       from "../../Components/generic/ErrorAlert"
import Loader                           from "../../Components/generic/Loader"
import Breadcrumbs                      from "../../Components/generic/Breadcrumbs"


export default function Delete() {
    const navigate          = useNavigate()
    const params            = useParams()
    const [deleteError, setDeleteError] = useState(null)

    const confirmDelete = useCallback(() => {
        request(`/api/v1/checks/${params.id}`, { method: "DELETE" }).then(
            () => navigate("../../"),
            err => setDeleteError(err)
        )
    }, [params.id])

    const { error, loading, result } = useBackend(async () => {
         const { body } = await request(`/api/v1/checks/${params.id}`)
         return body
    }, true)

    const errors = [ error, deleteError ];
    const hasError = errors.some(e => !!e)
    const name = result?.name || (result?.requestOptions.method || "GET") + " " + result?.requestOptions?.url

    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "My Monitors",
                    href: "/my/monitors"
                },
                {
                    name,
                    href: `/my/monitors/${params.id}`
                },
                {
                    name: "Delete"
                }
            ]}/>
            { loading || !result ?
                <div className="text-center my-5"><Loader className="text-center my-5" /></div> :
                <>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h2 className="pe-2 text-truncate my-0 text-danger">Delete Monitor</h2>
                        <NavigationButtons />
                    </div>
                    <hr className="my-1" />
                    <br/>
                    { hasError && <ErrorAlert>{ errors }</ErrorAlert> }
                    <div className="alert border-3 border-danger">
                        <h4>Are you sure you want to delete {result.name}?</h4>
                        <p className="text-danger">All the history collected for this monitor will also be deleted!</p>
                        <hr />
                        <div className="d-flex flex-wrap justify-content-center">
                            <Link style={{ width: "10em" }} to="../../" className="btn btn-outline-secondary px-5 m-2">Cancel</Link>
                            <button style={{ width: "10em" }} className="btn btn-danger px-4 m-2" onClick={() => confirmDelete()}>Confirm Delete</button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
