import { useState }           from "react"
import Pagination             from "../Pagination"
import Loader                 from "../Loader"
import ErrorAlert             from "../ErrorAlert"
import Alert                  from "../Alert"
import CheckBox               from "../CheckBox"
import { classList, getPath } from "../../../lib"
import {
    DataGridColumn,
    DataGridColumnDataType,
    DataGridProps
} from "./types"
import "./DataGrid.scss"


export default function StaticDataGrid(props: DataGridProps) {
    
    const {
        onSelectionChange,
        onFilterChange,
        onSortChange,
        selection,
        offset     = 0,
        limit      = 15,
        rows       = [],
        sortColumn = "",
        sortDir    = "desc",
        filters    = {},
        count      = rows.length,
        identity,
        loading,
        error
    } = props
    
    const [showFilters, setShowFilters] = useState(false)

    const totalPages = Math.ceil(count / limit)

    const shouldHavePagination = totalPages > 1 && props.onPaginationChange
    
    const shouldHaveSelection = identity && onSelectionChange

    function renderDataGridHeader() {
        return (
            <thead>
                <tr className="bg-light">
                { shouldHaveSelection && <th style={{ width: "1em", verticalAlign: "bottom" }}>
                    <CheckBox
                        indeterminate={selection.length > 0 && selection.length < rows.length}
                        className="selection-checkbox"
                        checked={ selection.length === rows.length }
                        onChange={() => {
                            if (selection.length) {
                                onSelectionChange([])
                            } else {
                                onSelectionChange(rows.map(r => r[identity]))
                            }
                        }}
                    />
                </th> }
                { props.columns.map((col, i) => (
                    <th {...col.thProps} key={i} className={ classList({
                        [col.thProps?.className ?? ""]: true,
                        "sorted" : col.sortProp && col.sortProp === sortColumn && rows.length > 0
                    })}>
                        <div style={{ cursor: col.sortProp && onSortChange ? "pointer" : "default" }} onClick={ () => {
                            if (col.sortProp && onSortChange) {
                                onSortChange(col.sortProp, sortDir === "asc" ? "desc" : "asc")
                            }
                        }} className="header-line">
                            <div className="header-label">
                                { col.renderHeader ? col.renderHeader() : col.label ?? (col.propName ?? "") }
                            </div>
                            { col.propName && col.dataType && onFilterChange && <i className="ms-2 bi bi-funnel" data-tooltip="Toggle column filters" onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                setShowFilters(!showFilters)
                            }} /> }
                            { col.sortProp && col.sortProp === sortColumn && rows.length > 0 ?
                                sortDir === "desc" ?
                                    <i className="bi bi-caret-down" /> :
                                    <i className="bi bi-caret-up" /> : null }
                        </div>
                        { col.propName && showFilters && col.dataType && onFilterChange && <ColumnFilter
                            operator={ filters[col.propName]?.operator ?? "eq" }
                            value={ filters[col.propName]?.value ?? "" }
                            dataType={col.dataType || "string"}
                            onChange={(operator, value) => {
                                filters[col.propName] = { operator, value: value || "" }
                                onFilterChange(filters)
                            }}
                        /> }
                    </th>
                ))}
                </tr>
            </thead>
        )
    }

    function renderDataGridBody() {
        return (
            <tbody className="table-group-divider">
                { rows.map((row, rowIndex) => {
                    const selected = !!selection?.includes(row[identity])
                    return (
                        <tr key={ rowIndex }>
                            { shouldHaveSelection && <td className={ classList({ selected }) }>
                                <input
                                    type="checkbox"
                                    className="selection-checkbox"
                                    checked={selected}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            onSelectionChange([...selection, row[identity]])
                                        } else {
                                            onSelectionChange(selection.filter(id => id !== row[identity]))
                                        }
                                    }} />
                            </td> }
                            { props.columns.map((col, colIndex) => (
                                <td { ...col.tdProps } key={colIndex} className={ classList({
                                    [col.tdProps?.className ?? ""]: true,
                                    selected
                                }) }>{ renderDataGridCell(row, col) }</td>
                            ))}
                        </tr>
                    )
                }) }
            </tbody>
        )
    }

    function renderDataGridCell(row: any, col: DataGridColumn) {
        if (col.renderCell) {
            return col.renderCell(row, rows)
        }

        const value = getPath(row, col.propName)

        if (value === undefined)
            return <code className="text-secondary">undefined</code>
        if (value === null)
            return <code className="text-secondary">null</code>

        switch (col.dataType) {
            case "boolean":
                return !!value ? "true" : "false";
            case "json":
                return JSON.stringify(value)
            case "number":
                return <div className="text-end"><code>{ Number(value).toLocaleString() }</code></div>
            case "date":
                return <time dateTime={value}>{new Date(value).toLocaleString()}</time>
            case "id":
                return <code>{ value }</code>
        }

        if (typeof value === "object") {
            return JSON.stringify(value)
        }

        return value + ""
    }

    return (
        <div className="data-grid">
            { error && <ErrorAlert className="mt-2">{ error }</ErrorAlert>}
            { loading && <Loader className="data-grid-loader" /> }
            <div className="table-responsive">
                <table className={ classList({ "table": true, [props.className]: true })}>
                    { renderDataGridHeader() }
                    { renderDataGridBody() }
                </table>
            </div>
            <div className="d-flex justify-content-between align-items-center py-1">
            { shouldHavePagination && <>
                <span className="text-secondary">
                    Records { offset + 1 } to { offset + rows.length } of {count.toLocaleString() ?? "unknown" }
                </span>
                <Pagination
                    offset={offset}
                    limit={limit}
                    total={count}
                    onChange={offset => props.onPaginationChange(offset)}
                />
                <span className="text-secondary">
                    { totalPages.toLocaleString() } page{ totalPages === 1 ? "" : "s" }
                </span>
            </> }
            { !rows.length && <Alert type="warning" className="m-auto px-5 mt-2">No records found</Alert>}
            </div>
        </div>
    )
}


function ColumnFilter({
    operator,
    value,
    dataType,
    onChange
}: {
    operator: string
    value: string
    dataType: DataGridColumnDataType
    onChange: (operator: string, value: string) => void
}) {
    return (
        <div className="input-group w-100">
            <select className="form-control form-control-sm text-center text-secondary" style={{ maxWidth: "2.5em" }} value={operator} onChange={e => onChange(e.target.value, value)}>
                <option value="eq">&nbsp;= &nbsp;Equal</option>
                <option value="ne">&nbsp;≠ &nbsp;Not equal</option>
                { (dataType === "number" || dataType === "date") && <option value="gt">&nbsp;&gt; &nbsp;Greater than</option> }
                { (dataType === "number" || dataType === "date") && <option value="gte">&nbsp;≥ &nbsp;Greater than or equal</option> }
                { (dataType === "number" || dataType === "date") && <option value="lt">&nbsp;&lt; &nbsp;Less than</option> }
                { (dataType === "number" || dataType === "date") && <option value="lte">&nbsp;≤ &nbsp;Less than or equal</option> }
                <option value="in">[=] In list</option>
                <option value="notIn">[≠] Not in list</option>
                { dataType === "string" && <option value="iLike">&nbsp; ≈ Like (case insensitive)</option> }
                { dataType === "string" && <option value="notILike">&nbsp;!≈ Not like (case insensitive)</option> }
            </select>
            <input type="text" className="form-control form-control-sm" value={value} onChange={e => onChange(operator, e.target.value)} />
        </div>
    )
}
