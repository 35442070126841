import { Link }   from "react-router-dom"
import Page       from "../Page"
import Hr         from "../../Components/generic/Hr"
import PageHeader from "../../Components/PageHeader"


export function PingMonitoring() {
    return (
        <Page header={
            <PageHeader
                style={{ padding: "1rem 0" }}
                title="Introducing Your Ultimate HTTP Monitor App"
                description="A ping monitor is a tool used to check the
                availability and responsiveness of network devices or servers by
                sending ICMP echo requests (pings) and receiving ICMP echo
                replies. It works by periodically sending ping requests to the
                target device and measuring the round-trip time for the response.
                The main purpose of a ping monitor is to detect network
                connectivity issues, latency, and packet loss."
            />
        }>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Configurable Frequency</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Customize how often the monitor runs according to your needs.
                        Whether you need frequent checks every minute or less frequent
                        ones, the choice is yours.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Enable/Disable Functionality</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Easily manage your monitoring activities with a simple toggle.
                        Whether you need to pause checks during maintenance or
                        re-enable them afterward, this feature ensures you stay in control.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Ping Hostname or IP</h5>
                    <p className="text-primary-emphasis opacity-75">
                        We allow pinging both IP addresses and host names to make
                        it easier for the end users.
                    </p>
                </div>
            </div>
            <Hr className="my-4 text-secondary opacity-25 fade-out"/>
            <div className="text-center">
                <Link className="btn btn-brand-2 bg-gradient px-4" to="/my/monitors/new?type=ping">Create Ping Monitor</Link>
            </div>
        </Page>
    )
}