/*
Size limits
The standard imposes no limits to the size of each header field name or value,
or to the number of fields. However, most servers, clients, and proxy software
impose some limits for practical and security reasons. For example, the
Apache 2.3 server by default limits the size of each field to 8,190 bytes,
and there can be at most 100 header fields in a single request.

Request fields
Standard request fields
Name	Description	Example	Status	Standard
*/
export const httpRequestHeaders = [
    { name: "A-IM"           , description: "Acceptable instance-manipulations for the request."    , example: "A-IM: feed"            },
    { name: "Accept"         , description: "Media type(s) that is/are acceptable for the response.", example: "Accept: text/html"     },
    { name: "Accept-Charset" , description: "Character sets that are acceptable."                   , example: "Accept-Charset: utf-8" },
    { name: "Accept-Datetime", description: "Acceptable version in time."                           , example: "Accept-Datetime: Thu, 31 May 2007 20:35:00 GMT" },
    { name: "Accept-Encoding", description: "List of acceptable encodings."                         , example: "Accept-Encoding: gzip, deflate" },
    { name: "Accept-Language", description: "List of acceptable human languages for response."      , example: "Accept-Language: en-US" },
    { name: "Access-Control-Request-Method" },
    {
        name: "Access-Control-Request-Headers",
        description: "Initiates a request for cross-origin resource sharing with Origin.",
        example: "Access-Control-Request-Method: GET"
    },
    {
        name: "Authorization",
        description: "Authentication credentials for HTTP authentication.",
        example: "Authorization: Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ=="
    },
    {
        name: "Cache-Control",
        description: "Used to specify directives that must be obeyed by all caching mechanisms along the request-response chain.",
        example: "Cache-Control: no-cache"
    },
    {
        name: "Connection",
        description: "Control options for the current connection and list of hop-by-hop request fields. Must not be used with HTTP/2.",
        example: "Connection: keep-alive"
    },
    { name: "Content-Encoding", description: "The type of encoding used on the data.", example: "Content-Encoding: gzip" },
    { name: "Content-Length", description: "The length of the request body in octets (8-bit bytes).", example: "Content-Length: 348" },
    { name: "Content-Type", description: "The Media type of the body of the request (used with POST and PUT requests).", example: "Content-Type: application/x-www-form-urlencoded" },
    { name: "Cookie", description: "An HTTP cookie previously sent by the server with Set-Cookie.", example: "Cookie: $Version=1; Skin=new;" },
    { name: "Date", description: "The date and time at which the message was originated.", example: "Date: Tue, 15 Nov 1994 08:12:31 GMT" },
    { name: "Expect", description: "Indicates that particular server behaviors are required by the client.", example: "Expect: 100-continue" },
    { name: "Forwarded", description: "Disclose original information of a client connecting to a web server through an HTTP proxy.", example: "Forwarded: for=192.0.2.60;proto=http;by=203.0.113.43 Forwarded: for=192.0.2.43, for=198.51.100.17" },
    { name: "From", description: "The email address of the user making the request.", example: "From: user@example.com" },
    {
        name: "Host",
        description: "The domain name of the server (for virtual hosting), and the TCP port number on which the server is listening. The port number may be omitted if the port is the standard port for the service requested.",
        example: "Host: en.wikipedia.org:8080"
    },
    {
        name: "If-Match",
        description: "Only perform the action if the client supplied entity matches the same entity on the server. This is mainly for methods like PUT to only update a resource if it has not been modified since the user last updated it.",
        example: 'If-Match: "737060cd8c284d8af7ad3082f209582d"'
    },
    {
        name: "If-Modified-Since",
        description: "Allows a 304 Not Modified to be returned if content is unchanged.",
        example: "If-Modified-Since: Sat, 29 Oct 1994 19:43:31 GMT"
    },
    {
        name: "If-None-Match",
        description: "Allows a 304 Not Modified to be returned if content is unchanged, see HTTP ETag.",
        example: 'If-None-Match: "737060cd8c284d8af7ad3082f209582d"'
    },
    {
        name: "If-Range",
        description: "If the entity is unchanged, send me the part(s) that I am missing; otherwise, send me the entire new entity.",
        example: 'If-Range: "737060cd8c284d8af7ad3082f209582d"'
    },
    {
        name: "If-Unmodified-Since",
        description: "Only send the response if the entity has not been modified since a specific time.",
        example: "If-Unmodified-Since: Sat, 29 Oct 1994 19:43:31 GMT"
    },
    {
        name: "Max-Forwards",
        description: "Limit the number of times the message can be forwarded through proxies or gateways.",
        example: "Max-Forwards: 10"
    },
    {
        name: "Origin",
        description: "Initiates a request for cross-origin resource sharing (asks server for Access-Control-* response fields).",
        example: "Origin: http://www.example-social-network.com"
    },
    {
        name: "Pragma",
        description: "Implementation-specific fields that may have various effects anywhere along the request-response chain.",
        example: "Pragma: no-cache"
    },
    {
        name: "Prefer",
        description: "Allows client to request that certain behaviors be employed by a server while processing a request.",
        example: "Prefer: return=representation"
    },
    {
        name: "Proxy-Authorization",
        description: "Authorization credentials for connecting to a proxy.",
        example: "Proxy-Authorization: Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ=="
    },
    {
        name: "Range",
        description: "Request only part of an entity. Bytes are numbered from 0.",
        example: "Range: bytes=500-999"
    },
    {
        name: "Referer",
        description: "This is the address of the previous web page from which a link to the currently requested page was followed. " +
            '(The word "referrer" has been misspelled in the RFC as well as in most implementations to the point that it has become ' +
            'standard usage and is considered correct terminology)'
    }
].sort((a, b) => a.name.localeCompare(b.name))

/*
HTTP2-Settings	A request that upgrades from HTTP/1.1 to HTTP/2 MUST include exactly one HTTP2-Settings header field. The HTTP2-Settings header field is a connection-specific header field that includes parameters that govern the HTTP/2 connection, provided in anticipation of the server accepting the request to upgrade.[19][20]	HTTP2-Settings: token64	Obsolete	RFC 7540, 9113
TE	The transfer encodings the user agent is willing to accept: the same values as for the response header field Transfer-Encoding can be used, plus the "trailers" value (related to the "chunked" transfer method) to notify the server it expects to receive additional fields in the trailer after the last, zero-sized, chunk.
Only trailers is supported in HTTP/2.[14]

TE: trailers, deflate	Permanent	RFC 9110
Trailer	The Trailer general field value indicates that the given set of header fields is present in the trailer of a message encoded with chunked transfer coding.	Trailer: Max-Forwards	Permanent	RFC 9110
Transfer-Encoding	The form of encoding used to safely transfer the entity to the user. Currently defined methods are: chunked, compress, deflate, gzip, identity.
Must not be used with HTTP/2.[14]

Transfer-Encoding: chunked	Permanent	RFC 9110
User-Agent	The user agent string of the user agent.	User-Agent: Mozilla/5.0 (X11; Linux x86_64; rv:12.0) Gecko/20100101 Firefox/12.0	Permanent	RFC 9110
Upgrade	Ask the server to upgrade to another protocol.
Must not be used in HTTP/2.[14]

Upgrade: h2c, HTTPS/1.3, IRC/6.9, RTA/x11, websocket	Permanent	RFC 9110
Via	Informs the server of proxies through which the request was sent.	Via: 1.0 fred, 1.1 example.com (Apache/1.1)	Permanent	RFC 9110
Warning	A general warning about possible problems with the entity body.	Warning: 199 Miscellaneous warning	Obsolete[21]	RFC 7234, 9111
*/

export default httpRequestHeaders;
