import { useCallback, useEffect }           from "react"
import { Outlet, useLocation, useNavigate, useRouteError } from "react-router"
import { useAuth }                          from "../../auth"
import { Layout }                           from "../../Layout/Layout"


const roles = ["admin", "manager"]

const title = process.env.MODE === "construction" ?
    "Site Under Construction" :
    "Site Under Maintenance";

const message = process.env.MODE === "construction" ?
    <>We are working hard to bring you a new experience.<br />Stay tuned!</> :
    <>We are currently performing scheduled maintenance.<br />Please check back soon.</>;


export default function Maintenance()
{
    let auth     = useAuth();
    let location = useLocation();
    const navigate = useNavigate()
    const error: any = useRouteError();

    const onKeyPress = useCallback((e: KeyboardEvent) => {
        if (e.key === "l" && e.ctrlKey) {
            navigate("/login", {
                state: {
                    from   : location,
                    message: "Only site administrators allowed!"
                }
            })
        }
    }, [])

    useEffect(() => {
        if (!auth.user || !roles.includes(auth.user.role)) {
            window.addEventListener("keypress", onKeyPress)
        }
        return () => {
            window.removeEventListener("keypress", onKeyPress)
        }
    }, [auth.user, roles])
  
    if (!auth.user || !roles.includes(auth.user.role)) {
        return (
            <div className="d-flex flex-grow-1 align-self-center align-items-center text-center">
                <div className="row justify-content-center p-4">
                    <h1>{ title }</h1>
                    <p className="text-muted">{ message }</p>
                    <br />
                    { error && <div className="text-danger">
                        <div className="text-muted"><b>We also detected the following error:</b></div>
                        {error.status} {error.statusText || error.message}
                    </div> }
                </div>
            </div>
        )
    }
  
    return <Layout><Outlet /></Layout>;
}
