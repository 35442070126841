import Breadcrumbs from "../../Components/generic/Breadcrumbs"
import DataGrid    from "../../Components/generic/DataGrid"


export default function AllHistoryList() {
    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "Administration"
                },
                {
                    name: "All History Records"
                }
            ]}/>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="pe-2 text-truncate my-0">All History Records</h2>
            </div>
            <hr className="mt-2 mb-0" />
            <DataGrid
                url="/api/v1/history"
                className="table-hover table-sm"
                columns={[
                    {
                        renderCell: row => <div className="text-center">{ row.failure ?
                            <i className="bi bi-exclamation-circle-fill text-danger" /> :
                            <i className="bi bi-check-circle-fill text-success" />
                        }</div>
                    },
                    {
                        propName: "id",
                        sortProp: "id",
                        dataType: "id",
                        label   : "ID"
                    },
                    {
                        propName: "monitorId",
                        sortProp: "monitorId",
                        dataType: "id",
                        label   : "Monitor ID"
                    },
                    {
                        propName: "incidentId",
                        sortProp: "incidentId",
                        dataType: "id",
                        label   : "Incident ID"
                    },
                    {
                        propName: "startedAt",
                        sortProp: "startedAt",
                        dataType: "date",
                        label   : "Started"
                    },
                    {
                        propName: "endedAt",
                        sortProp: "endedAt",
                        dataType: "date",
                        label   : "Ended"
                    },
                    {
                        propName: "failure",
                        sortProp: "failure",
                        dataType: "string",
                        label   : "Error"
                    }
                ]}
            />
        </div>
    );
}