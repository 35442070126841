import { createCreatePage } from "../../Components/generic/Scaffolding/EndpointCreateWrapper"
import { Team }             from "../../types"
import Form                 from "./Form"


export default function Create()
{
    return createCreatePage<Team>({
        endpoint    : "/api/v1/teams",
        namePlural  : "Teams",
        basePath    : "/teams",
        nameSingular: "Team",
        renderForm  : props => <Form { ...props } />
    })
}
