import { useNavigate } from "react-router-dom"
import { useAuth } from "../../auth"
import { useEffect } from "react"

export function Logout() {
    const { logout } = useAuth()
    const navigate = useNavigate()
    useEffect(() => {
        logout().then(() => navigate("/", { replace: true }))
    }, [])
    return null
}