import { Link } from "react-router-dom"
import "./Monitoring.scss"
import Page from "../Page"
import Hr from "../../Components/generic/Hr"
import PageHeader from "../../Components/PageHeader"


export default function AllTypes() {
    return <Page header={
        <PageHeader
            title="Monitoring"
            description="Get started today and experience the peace of mind that
            comes with knowing your web services are being thoroughly monitored
            and maintained with our Monitor Suite. Whether for development,
            testing, or production environments, this tool is your ultimate
            solution for web reliability and performance monitoring."
        />
    }>
        <div className="monitoring-page">
            <section>
                <h2>
                    <i className="bi bi-globe-americas" />HTTP Monitoring
                </h2>
                <div>
                    <p>
                        Checks the availability and response time of your web pages.
                        You can create various HTTP requests, send them to your
                        services and verify that the response matches all expectations.
                    </p>
                    <Link to="./http" className="stretched-link link-underline link-underline-opacity-0">Find out more<i className="bi bi-arrow-right-circle-fill ms-1" /></Link>
                </div>
            </section>
            <Hr className="text-brand-2 opacity-50 fade-out" />
            <section>
                <h2>
                    <i className="bi bi-ethernet" />Port Monitoring
                </h2>
                <div>
                    <p>
                        Port monitoring is crucial for maintaining network security and performance.
                        By capturing and analyzing network traffic on specific ports, administrators
                        can detect anomalies, respond to threats, and optimize network usage effectively.
                    </p>
                    <Link to="./port" className="stretched-link link-underline link-underline-opacity-0">Find out more<i className="bi bi-arrow-right-circle-fill ms-1" /></Link>
                </div>
            </section>
            <Hr className="text-brand-2 opacity-50 fade-out" />
            <section>
                <h2><i className="bi bi-hdd-network-fill" />Ping Monitoring</h2>
                <div>
                    <p>
                        Ping monitoring is a fundamental technique for ensuring network availability
                        and performance. By measuring latency, packet loss, and jitter, it helps in
                        detecting network issues and maintaining optimal operation of networked systems.
                        Despite its simplicity, it provides essential insights for network administrators
                        and is widely used in conjunction with other monitoring tools for comprehensive
                        network management.
                    </p>
                    <Link to="./ping" className="stretched-link link-underline link-underline-opacity-0">Find out more<i className="bi bi-arrow-right-circle-fill ms-1" /></Link>
                </div>
            </section>
            <Hr className="text-brand-2 opacity-50 fade-out" />
            <section>
                <h2><i className="bi bi-shield-fill-check" />Domain/SSL Monitoring</h2>
                <div>
                    <p>
                        Domain monitoring is essential for maintaining the health and
                        security of a website. By using various monitoring tools and
                        techniques, domain owners can proactively manage their domains,
                        SSL certificates, quickly address issues, and maintain a secure
                        online presence. and quickly address any issues that arise.
                    </p>
                    <Link to="./domain" className="stretched-link link-underline link-underline-opacity-0">Find out more<i className="bi bi-arrow-right-circle-fill ms-1" /></Link>
                </div>
            </section>
            {/* <Hr className="text-brand-2 opacity-50 fade-out" />
            <section>
                <h2><i className="bi bi-shield-fill-check" />SSL Monitoring</h2>
                <div>
                    <p>
                        SSL monitoring is crucial for maintaining website security and
                        ensuring trustworthiness. By using SSL monitoring tools,
                        administrators can proactively manage SSL certificates, quickly
                        address issues, and maintain a secure online presence.
                    </p>
                    <Link to="." className="stretched-link link-underline link-underline-opacity-0">Find out more<i className="bi bi-arrow-right-circle-fill ms-1" /></Link>
                </div>
            </section> */}
            {/* <Hr className="text-brand-2 opacity-50 fade-out" />
            <section>
                <h2><i className="bi bi-browser-chrome" />Browser Monitoring</h2>
                <div>
                    <p>
                        Checks the availability and response time of your web pages.
                        You can create various HTTP requests, send them to your
                        services and verify that the response matches all expectations.
                    </p>
                    <Link to="." className="stretched-link link-underline link-underline-opacity-0">Find out more<i className="bi bi-arrow-right-circle-fill ms-1" /></Link>
                </div>
            </section> */}
        </div>
    </Page>
}