import Breadcrumbs from "../../Components/generic/Breadcrumbs"
import DataGrid    from "../../Components/generic/DataGrid"


export default function AllIncidentsList() {
    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "Administration"
                },
                {
                    name: "All Incident Records"
                }
            ]}/>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="pe-2 text-truncate my-0">All Incident Records</h2>
            </div>
            {/* <hr className="mt-2 mb-0" /> */}
            <DataGrid
                url="/api/v1/incidents"
                className="table-hover table-sm"
                columns={[
                    {
                        propName: "id",
                        sortProp: "id",
                        dataType: "number",
                        label   : "ID"
                    },
                    {
                        propName: "monitorId",
                        sortProp: "monitorId",
                        dataType: "number",
                        label   : "Monitor ID"
                    },
                    {
                        propName: "cause",
                        sortProp: "cause",
                        dataType: "string",
                        label   : "Root Cause",
                        // renderCell: row => <span className="text-danger">{ row.cause }</span>
                    },
                    {
                        propName: "startedAt",
                        sortProp: "startedAt",
                        label   : "Started",
                        dataType: "date",
                        // renderCell: row => new Date(row.startedAt).toLocaleString()
                    },
                    {
                        propName: "endedAt",
                        sortProp: "endedAt",
                        dataType: "date",
                        label   : "Ended",
                        // renderCell: row => new Date(row.endedAt).toLocaleString()
                    }
                ]}
            />
        </div>
    );
}