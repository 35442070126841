import { useEffect, useState } from "react";
import { humanizeDuration } from "../../../lib";

export default function CountDown({ initialCount, short }: { initialCount: number, short?: boolean }) {
    const [count, setCount] = useState(initialCount);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
        }, 1000);

        // Cleanup the interval when the component is unmounted or count becomes zero
        return () => {
            clearInterval(intervalId);
        };
    }, [initialCount]); // Re-run the effect when count changes

    return humanizeDuration(count * 1000, { short: true, humanJoin: true })
}