import { useCallback, useEffect, useState } from "react"
import ErrorAlert                           from "../../../Components/generic/ErrorAlert"
import Loader                               from "../../../Components/generic/Loader"
import { isEmptyObject, objectDiff, request }                          from "../../../lib"
import { ConfigRecord }                     from "../../../types"
import "./tree.scss"


export default function ConfigManager() {

    const [initialLoading, setInitialLoading] = useState(false)
    const [loadingError  , setLoadingError  ] = useState<Error | string | null>(null)
    const [records       , setRecords       ] = useState<ConfigRecord[] | null>()
    const [state         , setState         ] = useState<Record<number, any> | null>(null)
    const [lastState     , setLastState     ] = useState<Record<number, any> | null>(null)

    const fetch = useCallback(async () => {
        setInitialLoading(true)
        setLoadingError(null)
        try {
            const { body } = await request<ConfigRecord[]>("/api/v1/config?order=pid:asc,id:asc")
            setRecords(body)
            const state = {}
            body.forEach(rec => {
                if (rec.dataType) {
                    state[rec.id] = rec.value
                }
            })
            setState(state)
            setLastState(state)
        }
        finally {
            setInitialLoading(false)
        }
    }, [])

    useEffect(() => {
        fetch()
    }, [])

    if (initialLoading || !records) {
        return <div className="position-relative vh-100"><Loader className="loader-cover"/></div>
    }

    if (loadingError) {
        return <ErrorAlert>{ loadingError + "" }</ErrorAlert>
    }

    if (!records.length) {
        return <ErrorAlert>No records found</ErrorAlert>
    }

    const groups = records.filter(r => r.dataType === null)


    function onChange(key, value) {
        setState({ ...state, [key]: value })
    }

    const diff = objectDiff(lastState, state)
    const hasChanges = !isEmptyObject(diff)

    async function save() {
        if (hasChanges) {
            const { body } = await request<boolean>("/api/v1/config", {
                method : "PUT",
                headers: { "content-type": "application/json" },
                body   : JSON.stringify(state)
            })
            
            if (body) {
                setLastState(state)
            }
        }
    }

    return (
        <div className="tree">
            <GroupNode rec={{ id: null, dataType: null, displayName: "General Options" } as any} all={ records } state={state} onChange={onChange} />
            { groups.map((g, i) => {
                return <GroupNode rec={g} key={i} all={records} state={state} onChange={onChange} />
            }) }
            <hr/>
            <div className="text-center">
                <button className="btn btn-primary bg-gradient px-5" onClick={() => save()} disabled={!hasChanges}>Save</button>
            </div>
        </div>
    )
}

function GroupNode({ rec, all, state, onChange }: { rec: ConfigRecord, all: ConfigRecord[], state: Record<number, any>, onChange: (key: any, value: any) => void }) {
    return (
        <>
            <div className="heading">
                <h4 className="text-primary-emphasis"><i className="bi bi-folder2 me-2" />{ rec.displayName }</h4>
                <hr/>
            </div>
            <div className="grid mb-5">
                { all.filter(r => r.pid === rec.id && r.dataType).map((r, i) => <LeafNode key={i} rec={r} value={state[r.id]} onChange={(value: any) => onChange(r.id, value)} />) }
            </div>
        </>
    )
}

function LeafNode({ rec, value, onChange }: { rec: ConfigRecord, value: any, onChange: (value: any) => void }) {
    return (
        <div>
            <label className="form-label text-nowrap"><b>{ rec.displayName || rec.name }</b></label>
            <Editor record={rec} value={value} onChange={onChange} />
            <div className="form-text small">{ rec.description }</div>
        </div>
    )
}

function Editor({
    record,
    value,
    onChange
} : {
    record  : ConfigRecord
    value   : any
    onChange: (value: any) => void
}) {
    const { dataType, defaultValue, options } = record
    if (dataType === "number") {
        if (options.type === "range") {
            return <div>
                <div className="small text-end" style={{ lineHeight: 0 }}><small className="badge rounded-pill text-bg-secondary bg-opacity-1">{value}</small></div>
                <input type="number" className="form-control" value={+value} {...options} onChange={e => onChange(e.target.valueAsNumber)} />
            </div>

        }
        return <input type="number" className="form-control" value={+value} {...options} onChange={e => onChange(e.target.valueAsNumber)} />
    }
    if (dataType === "boolean") {
        return <input type="checkbox" className="form-control" checked={!!value} {...options} onChange={e => onChange(e.target.checked)} />
    }
    if (dataType === "string") {
        return <input type="text" className="form-control" value={value + ""} {...options} onChange={e => onChange(e.target.value)} />
    }
    return null
}
