import { ReactNode } from "react"
import "./Page.scss"
import Hr from "../../Components/generic/Hr"


export default function Page({
    header,
    children
}: {
    header?: ReactNode
    children?: ReactNode
}) {
    return (
        <>
            { header ? typeof header === "string" ?
                <h1 className="page-header">
                    { header }
                    <Hr className="text-primary opacity-50 my-4 fade-out" />
                </h1> :
                <>
                    { header }
                    <Hr className="text-brand-3 opacity-25 mb-4 fade-out" />
                </> : null
            }
            <div className="container pb-5">{ children }</div>
        </>
    )
}