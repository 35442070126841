import { useEffect, useState } from "react"
import { classList, humanizeDuration, pipe, request, roundToPrecision } from "../../../lib"
import { Chart as NewChart } from "../../../Components/generic/Chart"



type SystemStatus = {
    timestamp : number
    memory    : NodeJS.MemoryUsage
    osTotalmem: number
    cpu       : NodeJS.CpuUsage,
    cpuPercentage: number
}
  
const toMB = (bytes: number) => Math.round(bytes / 1024 / 1024 * 100) / 100

function CPUChart2({ data } : { data: SystemStatus[] }) {
    return <div className="mb-4">
        <h5 className="text-primary-emphasis"><i className="bi bi-cpu-fill me-2" />CPU Usage</h5>
        <hr className="mt-0 border-primary"/>
        <NewChart
            id="cpu-chart"
            height={100}
            width={600}
            grid
            crossHair="xy"
            xAxis={{
                enabled: true,
                labelFormat: n => new Date(n).toLocaleTimeString()
            }}
            yAxis={{
                enabled: true,
                labelFormat: n => roundToPrecision(n, 2) + "%",
                width: 50
            }}
            series={[
                {
                    color: "#d63384",
                    // type : "column",
                    // type : "areaspline",
                    type : "spline",
                    label: "CPU Usage",
                    data: data.map(d => ({
                        y: d.cpuPercentage,
                        x: d.timestamp
                    })),
                    // fill: "#6D0C",
                    // fill: {
                    //     x1: 0,
                    //     x2: 0,
                    //     y1: 0,
                    //     y2: 1,
                    //     stops: [
                    //         { offset: "0%", stopColor: "#6C0", stopOpacity: 0.5 },
                    //         { offset: "100%", stopColor: "#6C0", stopOpacity: 0.2 }
                    //     ]
                    // }
                }
            ]}
        />
    </div>
}

function MemoryChart({ data } : { data: SystemStatus[] }) {

    if (data.length < 2) {
        return null
    }

    return (
        <div className="mb-4">
            <h5 className="text-primary-emphasis"><i className="bi bi-memory me-2" />Memory</h5>
            <hr className="mt-0 border-primary"/>
            <NewChart
                id="memory-chart"
                height={100}
                width={600}
                grid
                crossHair="y"
                xAxis={{ enabled: true, labelFormat: n => new Date(n).toLocaleTimeString() }}
                yAxis={{ enabled: true, labelFormat: n => n + "MB", width: 50 }}
                series={[
                    // {
                    //     type : "line",
                    //     label: "RSS",
                    //     data : data.map(d => ({
                    //         y: toMB(d.memory.rss),
                    //         x: +d.timestamp
                    //     }))
                    // },
                    // {
                    //     type: "line",
                    //     label: "External Memory",
                    //     data: data.map(d => ({
                    //         y: toMB(d.memory.external),
                    //         x: +d.timestamp
                    //     }))
                    // },
                    {
                        type : "line",
                        label: "Heap Total",
                        step: "middle",
                        color: "#0d6efd",
                        lineProps: { strokeWidth: 1.5 },
                        data: data.map(d => ({
                            y: toMB(d.memory.heapTotal),
                            x: d.timestamp
                        }))
                    },
                    {
                        type: "area",
                        label: "Heap Used",
                        step: "middle",
                        color: "#fd7e14",
                        lineProps: { strokeWidth: 1 },
                        data: data.map(d => ({
                            y: toMB(d.memory.heapUsed),
                            x: +d.timestamp
                        }))
                    }
                ]}
            />
        </div>
    )
}

function SlowRequests()
{
    const [data, setData] = useState<any[]>([])

    const fetch = async () => {
        try {
            const { body } = await request("/api/status/slowest-requests")
            setData(body)
        } catch {}
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetch();
        }, 2000);

        return () => {
            clearInterval(intervalId);
        };
    }, [])

    return (
        <div className="pb-4">
            <h5 className="text-primary-emphasis"><i className="bi bi-globe-americas" /> Top 10 Slowest requests</h5>
            <hr className="mt-0 mb-0 border-primary"/>
            <table className="table table-sm table-hover small w-100" style={{ tableLayout: "fixed" }}>
                <thead>
                    <tr>
                        <th>Request</th>
                        <th style={{ width: "6em" }} className="text-end">Time</th>
                        <th style={{ width: "5em" }} className="text-center d-none d-md-table-cell">Status</th>
                        <th style={{ width: "10ch" }}>Date</th>
                    </tr>
                </thead>
                <tbody>
                    { data.map((rec, i) => (
                        <tr key={i}>
                            <td className="text-truncate">
                                <b className={classList({
                                    "badge" : true,
                                    "text-bg-success"  : rec.method === "GET",
                                    "text-bg-warning"  : rec.method === "PUT" || rec.method === "POST",
                                    "text-bg-danger"   : rec.method === "DELETE",
                                    "text-bg-secondary": !["GET","PUT","POST","DELETE"].includes(rec.method),
                                })}>{ rec.method }</b> { decodeURIComponent(rec.url) }
                            </td>
                            <td className="text-end">
                                <code>{ humanizeDuration(rec.duration, { limit: 2, short: true }) }</code>
                            </td>
                            <td className={classList({
                                "text-center" : true,
                                "d-none"      : true,
                                "text-danger" : rec.statusCode >= 400,
                                "text-info"   : rec.statusCode >= 300 && rec.statusCode < 400,
                                "text-success": rec.statusCode >= 200 && rec.statusCode < 300,
                                "d-md-table-cell": true
                            })}>{ rec.statusCode }</td>
                            <td data-tooltip={new Date(rec.timestamp).toLocaleString()} className="text-muted">
                                {new Date(rec.timestamp).toLocaleTimeString()}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

function Monitors()
{
    const [data, setData] = useState<{ count: number, data: number[], currentConcurrency: number }>({ count: 0, data: [], currentConcurrency: 0 })
    const [rpm, stRpm] = useState(0)
    
    useEffect(() => {
        const abort = pipe({
            url: "/api/v1/dashboard/long-pool",
            onData: (e) => {
                // console.log("===> ", type, data)
                if (e.type === "runningMonitorIDs") {
                    setData({
                        count: e.data.ids.length,
                        data: e.data.ids,
                        currentConcurrency: e.data.currentConcurrency
                    })
                    stRpm(e.data.rpm)
                    // const len = data.push(e.data.join(","))
                    // if (len > 10) {
                    //     setData(data.slice(len - 10))
                    // } else {
                    //     setData(data)
                    // }
                }
            }
        })
        return () => abort("Component unmounted")
    }, [])

    return (
        <div>
            <h5 className="text-primary-emphasis"><i className="bi bi-play-circle" /> Running Monitors</h5>
            <hr className="mt-0 mb-0 border-primary"/>
            <table className="table table-sm table-hover small w-100" style={{ tableLayout: "fixed" }}>
                <thead>
                    <tr>
                        <th className="text-center">Count</th>
                        <th className="text-center">RPM</th>
                        <th className="text-center">RPS</th>
                        <th className="text-center" title="Concurrency">Conc.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center"><code>{ data.count }</code></td>
                        <td className="text-center"><code>{ rpm }</code></td>
                        <td className="text-center"><code>{ (rpm / 60).toFixed(2) }</code></td>
                        <td className="text-center"><code>{ data.currentConcurrency }</code></td>
                    </tr>
                </tbody>
            </table>
            <MonitorsChart data={data.data} />
            {/* <pre className="small">
                { JSON.stringify(data, null, 4) }
            </pre> */}
        </div>
    )
}


export function Dashboard() {

    const [data, setData] = useState<SystemStatus[]>([]);

    const fetch = async () => {
        try {
            const { body } = await request("/api/status/system-info")
            setData(body)
        } catch {}
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetch();
        }, 2000);

        return () => {
            clearInterval(intervalId);
        };
    }, [])


    return (
        <div>
            <div className="row">
                <div className="col-xl-6">
                    <SlowRequests />
                </div>
                <div className="col-xl-6 pb-4">
                    <CPUChart2 data={data} />
                    <br />
                    <MemoryChart data={data} />
                </div>
            </div>
            <br />
            <Monitors />
        </div>
    )
}

function MonitorsChart({
    width  = 100,
    height = 100,
    data   = []
}: {
    width ?: number
    height?: number
    data  ?: number[]
}) {
    return (
        <svg viewBox={`0 0 ${width} ${height}`} style={{ border: "1px solid #CCC", width: "100%", height: 200, background: "#F9F9F9" }}>
            {data.map(n => {
                const y = Math.floor((n - 1) / width)
                const x = (n - 1) - (y * width)
                return <circle
                    key={n}
                    cx={0.5 + x}
                    cy={0.5 + y}
                    r={0.5}
                    fill="#3C0"
                />
            })}
        </svg>
    );
}
