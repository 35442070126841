import { FormEvent, useState } from "react"
import { request }             from "../../lib"
import Loader                  from "../../Components/generic/Loader"
import ErrorAlert              from "../../Components/generic/ErrorAlert"
import { PasswordInput }       from "../../Components/Auth/PasswordInput"
import { PasswordValidation }  from "../../Components/Auth/PasswordValidation"
import {
    validatePassword,
    validatePasswordSet
} from "../../../../isomorphic/validation/password-validation"
import { useAuth } from "../../auth"
import { Link } from "react-router-dom"


export default function ChangePassword() {
    const { user } = useAuth()
    const [password0, setPassword0] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [error    , setError    ] = useState<Error | string | null>(null)
    const [loading  , setLoading  ] = useState<boolean>(false)
    const [success  , setSuccess  ] = useState<boolean>(false)

    const isValid = !validatePassword(password0) && !validatePasswordSet(password1, password2);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        setError(null)

        try {
            const { response, body } = await request(`/api/v1/users/${user.id}/password`, {
                method : "PUT",
                headers: { "content-type": "application/json" },
                body   : JSON.stringify({ password0, password1, password2 })
            })

            if (response.status !== 200) {
                setError(body.message || body)
            } else {
                setSuccess(true)
            }

        } catch (ex) {
            setError(ex)
        }

        setLoading(false)
    }

    if (success) {
        return (
            <div className="d-flex flex-grow-1 align-items-center justify-content-center">
                <div className="flex-grow-1 text-center card shadow-sm" style={{ maxWidth: "38em" }}>
                    <div className="card-body p-5">
                        <h2>
                            <i className="bi bi-check-circle-fill text-success me-2" />
                            Password changed successfully!
                        </h2>
                        <hr/>
                        <p className="my-4">You will have to you this new password next time you login.</p>
                        <div className="text-center">
                            <Link className="btn btn-primary px-5" to="/my/account">OK</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={onSubmit} style={{ maxWidth: "40rem", margin: "0 auto" }} autoComplete="off">
            <div className="text-center mt-5 mb-4">
                <h2>Change Password</h2>
                <hr />
            </div>
            { error && <ErrorAlert>{ error }</ErrorAlert> }
            <div className="row my-3 justify-content-center">
                <div className={ "col" + (validatePassword(password0) ? " is-valid" : " is-invalid")}>
                    <PasswordInput
                        label="Current Password"
                        id="password0"
                        autoComplete="off"
                        value={password0}
                        onChange={e => setPassword0(e.target.value)}
                        validate
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col pt-3 pb-2">
                    <div className="card border-3 border-warning">
                        <div className="card-body">
                            <div className="row nowrap">
                                <div className="col-sm-6 mb-4">
                                    <PasswordInput
                                        label="New Password"
                                        id="password1"
                                        autoComplete="off"
                                        value={password1}
                                        onChange={e => setPassword1(e.target.value)}
                                        validate
                                    />
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <PasswordInput
                                        label="Repeat New Password"
                                        id="password2"
                                        autoComplete="off"
                                        value={password2}
                                        onChange={e => setPassword2(e.target.value)}
                                        validate
                                    />
                                </div>
                            </div>
                            <div className="text-muted small">
                                <PasswordValidation password1={password1} password2={password2} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col col-12 text-center">
                    <button type="submit" className="btn px-5 btn-warning m-auto" disabled={loading || !isValid}>
                        { loading ? <Loader small color="white" className="me-2" /> : <i className="bi bi-floppy2-fill me-2" /> }Save
                    </button>
                </div>
            </div>
        </form>
    )
}
