
export function PasswordValidation({ password1, password2 }: { password1: string, password2: string }) {
    return <div className="small color-muted mt-05">
        <CheckMark key="1" condition={password1.length >= 8} label="At least 8 characters" />
        <CheckMark key="2" condition={password1.length <= 100} label="Less than 100 characters" />
        <CheckMark key="3" condition={password1.match(/[A-Z]/)} label="At least one upper-case letter" />
        <CheckMark key="4" condition={password1.match(/[a-z]/)} label="At least one lower-case letter" />
        <CheckMark key="5" condition={password1.match(/[0-9]/)} label="At least one digit" />
        <CheckMark key="6" condition={password1.match(/(\$|@|,|-|#|!|%|~|&|\*|\^)/)} label={ <>At least one special character (<code><b>$ @ , % - # ! % ~ &amp; * ^</b></code>)</>} />
        <CheckMark key="7" condition={password1 === password2} label="Two passwords must match" />
    </div>
}

export function CheckMark({ condition, label }: { condition: any, label: any }) {
    return condition ?
        <div><i className="bi me-1 bi-check-circle-fill text-success" /> { label }</div> :
        <div><i className="bi me-1 bi-x-circle-fill text-danger" /> { label }</div>;
}
