export default function Invoices() {
    return (
        <>
            <h3 className="border-bottom border-2 py-1">My Invoices</h3>
            <div className="d-flex flex-grow-1 align-items-center justify-content-center">
                <div className="flex-grow-1" style={{ maxWidth: "30em" }}>
                    <div className="card shadow-sm">
                        <div className="card-body p-4 text-center text-secondary fw-semibold">
                            You don't currently have any invoices
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}