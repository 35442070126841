import Page from "../Page";

export function Terms() {
    const serviceName = "Vigilant Monitor"
    const companyName = "Vigilant"
    const country     = "United States of America"
    return (
        <Page header="Terms and Conditions">
            <section className="mb-5">
                <h4 className="text-primary-emphasis">1. Introduction</h4>
                <p>
                    Welcome to {serviceName} ("Service"). These terms and
                    conditions ("Terms") govern your use of our uptime monitoring
                    service, which provides HTTP, domain name, port, and ping
                    monitoring. By using our Service, you agree to these Terms.
                    If you do not agree to these Terms, please do not use our
                    Service.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">2. Definitions</h4>
                <p>
                    <b>Service:</b> The uptime monitoring service provided by {serviceName}.<br />
                    <b>User:</b> Any individual or entity that uses the Service.<br />
                    <b>Account:</b> The account created by the User to access and use the Service.<br />
                    <b>Content:</b> Any data, information, or material provided by the User or generated by the Service.<br />
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">3. User Accounts</h4>
                <p>
                    <b>3.1 Account Creation:</b> To use the Service, you must create
                    an Account. You agree to provide accurate, current, and complete
                    information during the registration process.
                </p>
                <p>
                    <b>3.2 Account Security:</b> You are responsible for maintaining
                    the confidentiality of your Account credentials. You agree to
                    notify us immediately of any unauthorized use of your Account.
                </p>
                <p>
                    <b>3.3 Eligibility:</b> You must be at least 18 years old to
                    create an Account and use the Service.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">4. Use of Service</h4>
                <p>
                    <b>4.1 Permitted Use:</b> You may use the Service for lawful
                    purposes and in accordance with these Terms.
                </p>
                <p>
                    <b>4.2 Prohibited Use:</b> You agree not to use the Service:
                    In any way that violates any applicable federal, state, local,
                    or international law or regulation. For the purpose of exploiting,
                    harming, or attempting to exploit or harm minors in any way.
                    To transmit, or procure the sending of, any advertising or
                    promotional material without our prior written consent. To
                    impersonate or attempt to impersonate {serviceName}, a
                    {serviceName} employee, another User, or any other person or
                    entity.
                </p>
                <p>
                    <b>4.3 Monitoring:</b> We reserve the right to monitor your use
                    of the Service to ensure compliance with these Terms.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">5. Fees and Payment</h4>
                <p>
                    <b>5.1 Subscription Fees:</b> The Service may be provided on a
                    subscription basis with fees as described on our website. By
                    subscribing to the Service, you agree to pay the subscription
                    fees and any applicable taxes.
                </p>
                <p>
                    <b>5.2 Payment Methods:</b> We accept various payment methods as
                    indicated on our website. You authorize us to charge your chosen
                    payment method for the subscription fees.
                </p>
                <p>
                    <b>5.3 Changes to Fees:</b> We reserve the right to change our
                    fees at any time. We will notify you of any changes to the fees
                    in advance.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">6. Intellectual Property</h4>
                <p>
                    <b>6.1 Ownership:</b> All intellectual property rights in the
                    Service and any related content are owned by {companyName}
                    or its licensors.
                </p>
                <p>
                    <b>6.2 License:</b> We grant you a limited, non-exclusive,
                    non-transferable license to access and use the Service in
                    accordance with these Terms.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">7. Disclaimer of Warranties</h4>
                <p>
                    The Service is provided "as is" and "as available" without any
                    warranties of any kind, either express or implied, including but
                    not limited to the implied warranties of merchantability,
                    fitness for a particular purpose, and non-infringement. We do
                    not warrant that the Service will be uninterrupted, error-free,
                    or free of harmful components.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">8. Limitation of Liability</h4>
                <p>
                    To the fullest extent permitted by law, {companyName} shall not
                    be liable for any indirect, incidental, special, consequential,
                    or punitive damages, or any loss of profits or revenues, whether
                    incurred directly or indirectly, or any loss of data, use,
                    goodwill, or other intangible losses, resulting from (a) your
                    use or inability to use the Service; (b) any unauthorized access
                    to or use of our servers and/or any personal information stored
                    therein; (c) any interruption or cessation of transmission to or
                    from the Service; (d) any bugs, viruses, trojan horses, or the
                    like that may be transmitted to or through the Service by any
                    third party; (e) any errors or omissions in any content or for
                    any loss or damage incurred as a result of your use of any
                    content posted, emailed, transmitted, or otherwise made
                    available through the Service; and/or (f) the defamatory,
                    offensive, or illegal conduct of any third party.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">9. Indemnification</h4>
                <p>
                    You agree to indemnify, defend, and hold harmless {companyName},
                    its affiliates, and their respective officers, directors,
                    employees, agents, licensors, and suppliers from and against any
                    claims, liabilities, damages, judgments, awards, losses, costs,
                    expenses, or fees (including reasonable attorneys' fees) arising
                    out of or relating to your violation of these Terms or your use
                    of the Service.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">10. Termination</h4>
                <p>
                    We may terminate or suspend your Account and access to the
                    Service at our sole discretion, without prior notice, if you
                    violate these Terms or for any other reason. Upon termination,
                    your right to use the Service will immediately cease.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">11. Changes to Terms</h4>
                <p>
                    We reserve the right to modify these Terms at any time. We will
                    notify you of any changes by posting the new Terms on our
                    website. Your continued use of the Service after any such
                    changes constitutes your acceptance of the new Terms.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">12. Governing Law</h4>
                <p>
                    These Terms and your use of the Service shall be governed by and
                    construed in accordance with the laws of {country},
                    without regard to its conflict of law principles.
                </p>
            </section>
            <section className="mb-5">
                <h4 className="text-primary-emphasis">13. Contact Us</h4>
                <p>
                    If you have any questions about these Terms, please contact us
                    at [Your Contact Information].
                </p>
            </section>
        </Page>
    );
}