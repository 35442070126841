import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useCallback, useState }              from "react"
import NavigationButtons                      from "./NavigationButtons"
import { request }                            from "../../lib"


export default function DeleteMany() {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const ids      = params.get("id")
    const [deleteError, setDeleteError] = useState()

    const confirmDelete = useCallback(() => {
        request(`/api/v1/checks/?id=` + ids, { method: "DELETE" }).then(
            () => navigate("../"),
            err => setDeleteError(err)
        )
    }, [ids])

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h2 className="pe-2 text-truncate my-0 text-danger">Delete Monitors</h2>
                <NavigationButtons />
            </div>
            <hr className="my-1" />
            <br/>
            { deleteError && <div className="alert alert-danger">{ deleteError + "" }</div> }
            <div className="alert border-3 border-danger">
                { ids ? (<>
                    <h4>Are you sure you want to delete these monitors?</h4>
                    <p className="text-danger">All the history collected for this monitor will also be deleted!</p>
                    <hr />
                    <div className="d-flex flex-wrap justify-content-center">
                        <Link to="../" className="btn btn-outline-secondary m-2" style={{ width: "10em" }}>Cancel</Link>
                        <button className="btn btn-danger m-2" style={{ width: "10em" }} onClick={() => confirmDelete()}>Confirm Delete</button>
                    </div>
                </>) : <h5 className="m-0">Invalid URL</h5> }
            </div>
        </div>
    )
}