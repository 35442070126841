import { useRouteError } from "react-router-dom";

export function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <div className="d-flex flex-grow-1 align-items-center">
            <div className="container-fluid text-center">
                <h1 className="text-danger" style={{ fontSize: 70 }}>Oops!</h1>
                <h3 className="text-muted">Sorry, an unexpected error has occurred.</h3>
                <p className="text-muted">
                    <i>{error.status} {error.statusText || error.message}</i>
                </p>
            </div>
        </div>
    );
}