import { useCallback, useEffect, useMemo, useState } from "react"
import { Helmet, HelmetProvider }                    from "react-helmet-async"
import { useNavigate, useParams }                    from "react-router"
import { Link }                                      from "react-router-dom"
import { request }                                   from "../../../lib"
import { useBackend }                                from "../../../hooks"
import ErrorAlert                                    from "../ErrorAlert"
import Breadcrumbs                                   from "../Breadcrumbs"
import Loader                                        from "../Loader"


export default function EndpointDeleteWrapper({
    endpoint,
    query,
    children,
    redirect = ".."
}: {
    endpoint: string
    query?: string
    redirect?: string
    children: (props: {
        loading : boolean
        data    : any
        onSubmit: () => void
        error: Error | string | null
    }) => JSX.Element
})
{
    const { id } = useParams()
    const navigate = useNavigate()
    const [working, setWorking] = useState(false)
    const [saveError, setSaveError] = useState<Error|string|null>(null)
    const abortController = useMemo(() => new AbortController(), [])

    let url = `${endpoint}/${id}`

    if (query) {
        url += "?" + encodeURI(query.replace(/^\?/, ""))
    }

    let { result, loading, error } = useBackend(
        useCallback(signal => request(url, { signal }), [url]),
        true
    )

    function onSubmit() {
        setWorking(true)
        setSaveError(null)
        request(`${endpoint}/${id}`, { method: "DELETE", signal: abortController.signal })
        .then(() => {
            if (!abortController.signal.aborted) {
                navigate(redirect)
            }
        })
        .catch(error => {
            if (!abortController.signal.aborted) {
                setSaveError(error)
                setWorking(false)
            }
        })
    }

    useEffect(() => () => abortController.abort(), [ abortController ]);

    if (loading) {
        return <Loader message="Loading data..." />
    }

    if (error) {
        return <ErrorAlert>{ error }</ErrorAlert>
    }

    if (!result) {
        return <ErrorAlert>Failed fetching data from the server</ErrorAlert>
    }

    return children({
        loading: working,
        data   : result.body,
        error  : saveError,
        onSubmit
    })
}

export function createDeletePage<T = unknown>({
    namePlural,
    nameSingular,
    endpoint,
    nameField = "name",
    renderView,
    icon = null,
    query

}: {
    namePlural       : string
    nameSingular     : string
    endpoint         : string
    nameField       ?: string
    query           ?: string
    icon            ?: JSX.Element | null
    renderView       : (data: T) => JSX.Element
})
{
    return (
        <EndpointDeleteWrapper endpoint={ endpoint } query={query} redirect="../..">
            {({ loading, data, onSubmit, error }) => {
                const name = data[nameField as keyof T] + ""
                return (
                    <>
                        <HelmetProvider>
                            <Helmet>
                                <title>Delete { nameSingular }</title>
                            </Helmet>
                        </HelmetProvider>
                        <Breadcrumbs links={[
                            { name: "Home"    , href: "/" },
                            { name: namePlural, href: "./../.." },
                            { name: name      , href: "./.." },
                            { name: "Delete " + nameSingular }
                        ]} />
                        <h1 className="text-danger text-center my-2">Please Confirm!</h1>
                        { error && <ErrorAlert>{ error }</ErrorAlert> }
                        <div className="panel border border-5 border-danger mt-1 rounded rounded-4 bg-danger bg-opacity-10 bg-gradient p-4">
                            <h4 className="text-truncate my-0">
                                { icon && <span className="me-2">{ icon }</span> }{ data.name }
                            </h4>
                            <hr className="mt-1" style={{ marginBottom: 1 }} />
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 bg-dark small bg-opacity-10 bg-gradient py-1 px-2 rounded-bottom opacity-75" style={{
                                textShadow: "0 1px 0 #FFF"
                            }}>
                                <div className="nowrap me-1">
                                    <span className="text-muted">Created: </span>
                                    <span className="color-brand-2">{ new Date(data.createdAt).toLocaleString() }</span>
                                </div>
                                <div className="nowrap">
                                    <span className="text-muted">Updated: </span>
                                    <span className="color-brand-2">{ new Date(data.updatedAt).toLocaleString() }</span>
                                </div>
                            </div>
                            { renderView(data) }
                        </div>
                        <div className="text-center py-3">
                            <Link style={{ width: "12em" }} className="btn btn-light border border-success px-2 me-4 text-success bg-gradient fw-bold" to="..">Cancel</Link>
                            <button style={{ width: "12em" }} className="btn btn-danger pl-2 pr-2 bg-gradient fw-bold" onClick={() => onSubmit()} disabled={loading}>
                                Delete {nameSingular}{ loading && <>&nbsp;<Loader message="" /></> }
                            </button>
                        </div>                            
                    </>
                )
            }}
        </EndpointDeleteWrapper>
    )
}
