import { Route, Routes } from "react-router"
import Info              from "./Info"
import ChangePassword    from "./ChangePassword"
// import Create            from "./Create"
// import Delete            from "./Delete"
// import Edit              from "./Edit"
// import View              from "./View"
// import DeleteMany        from "./DeleteMany"


export default function Endpoint() {
    return (
        <Routes>
            <Route path="/">
                <Route index Component={Info} />
                <Route path="password" Component={ChangePassword} />
                {/* <Route path="new" Component={ Create } />
                <Route path="delete" Component={ DeleteMany } />
                <Route path=":id">
                    <Route index Component={ View } />
                    <Route path="edit" Component={ Edit } />
                    <Route path="delete" Component={ Delete } />
                </Route> */}
            </Route>
        </Routes>
    )
}