import { Link, NavLink } from "react-router-dom"
import logo              from "../../../isomorphic/images/logo.svg"
import { useAuth }       from "../auth"
import "../style.scss"


export function PublicNavbar()
{
    const { user } = useAuth()
    return (
        <nav className="navbar navbar-expand-lg bg-primary-subtle bg-gradient sticky-top" style={{ textShadow: "0 1px 0px #FFF8" }}>
            <div className="container">
                <div className="d-flex">
                    <button className="navbar-toggler d-lg-none me-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <Link className="nav-link navbar-brand text-brand-3 fw-bold" to="/" style={{ textShadow: "0 1px 0px #FFFC" }}>
                        <img src={logo} style={{ height: "1.5em", width: "1.5em", verticalAlign: "bottom", lineHeight: "inherit" }} /> VIGILANT
                    </Link>
                </div>
                {/* <span /> */}
                <div className="collapse navbar-collapse justify-content-md-between">
                    <ul className="navbar-nav text-brand-3">
                        <li><NavLink className="nav-link text-brand-3 px-3" to="/monitoring">Monitoring</NavLink></li>
                        <li><NavLink className="nav-link text-brand-3 px-3" to="/pricing">Pricing</NavLink></li>
                        <li><NavLink className="nav-link text-brand-3 px-3 d-none d-xl-block" to="/faq">FAQ</NavLink></li>
                        <li><NavLink className="nav-link text-brand-3 px-3 d-none d-xl-block" to="/contact">Contact</NavLink></li>
                        <li><NavLink className="nav-link text-brand-3 px-3 d-none d-xl-block" to="/affiliate">Affiliate</NavLink></li>
                        <li className="nav-item dropdown px-3 d-xl-none">
                            <div className="nav-link dropdown-toggle text-brand-3" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Resources
                            </div>
                            <ul className="dropdown-menu shadow" style={{ zIndex: 1200 }}>
                                <li><NavLink className="dropdown-item" to="/affiliate">Affiliate&nbsp;program</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/faq">FAQ</NavLink></li>
                                {/* <li><NavLink className="dropdown-item" to="/help">Help&nbsp;center</NavLink></li> */}
                                {/* <li><NavLink className="dropdown-item" to="/api">API</NavLink></li> */}
                                <li><NavLink className="dropdown-item" to="/contact">Contact&nbsp;us</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/terms">Terms&nbsp;&amp;&nbsp;Privacy</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        { user && <li><NavLink className="nav-link text-brand-3 px-3" to="/my/monitors">My App</NavLink></li> }
                        { user && ["admin", "manager"].includes(user.role) && <li><NavLink className="nav-link text-brand-3 px-3" to="/admin/monitors">Administration</NavLink></li> }
                        { !user && <li><Link className="nav-link text-brand-3 px-3" to="/login">Log in</Link></li> }
                        { !user && <li><Link className="nav-link text-brand-3 px-3" to="/register">Register</Link></li> }
                        { user && <li><Link className="nav-link text-brand-3 px-3" to="/logout">Logout</Link></li> }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export function PublicSidebar()
{
    const { user } = useAuth()

    return (
        <div className="vi-sidebar" key={window.location.href}>
            <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">Navigation</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body flex-column p-3">
                    <nav className="nav nav-pills flex-column">
                        <NavLink className="nav-link" to="/monitoring">Monitoring</NavLink>
                        <NavLink className="nav-link" to="/pricing">Pricing</NavLink>
                        <NavLink className="nav-link" to="/affiliate">Affiliate&nbsp;program</NavLink>
                        <NavLink className="nav-link" to="/terms">Terms&nbsp;&amp;&nbsp;Privacy</NavLink>
                        <NavLink className="nav-link" to="/contact">Contact&nbsp;us</NavLink>
                        <NavLink className="nav-link" to="/faq">FAQ</NavLink>
                        {/* <NavLink className="nav-link" to="/api">API</NavLink> */}
                        {/* <NavLink className="nav-link" to="/help">Help&nbsp;center</NavLink> */}
                        <hr/>
                        { user && <NavLink className="nav-link" to="/my/monitors">My App</NavLink> }
                        { user && ["admin", "manager"].includes(user.role) && <NavLink className="nav-link" to="/admin/monitors">Administration</NavLink> }
                        { !user && <Link className="nav-link" to="/login">Log in</Link> }
                        { !user && <Link className="nav-link" to="/register">Register</Link> }
                        { user && <Link className="nav-link" to="/logout">Logout</Link> }
                    </nav>
                </div>
            </div>
        </div>
    )
}

export function PublicFooter() {
    return (
        <div className="bg-light border-top">
            <div className="container py-3">
                <div className="d-flex flex-wrap justify-content-center">
                    <Link className="d-block p-2 text-decoration-none" to="/pricing">Pricing</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/affiliate">Affiliate&nbsp;program</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/terms">Terms&nbsp;&amp;&nbsp;Privacy</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/faq">FAQ</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/contact">Contact&nbsp;us</Link>
                    {/* <Link className="d-block p-2 text-decoration-none" to="/api">API</Link> */}
                    {/* <Link className="d-block p-2 text-decoration-none" to="/help">Help&nbsp;center</Link> */}
                </div>
            </div>
        </div>
    )
}

export function PublicLayout({ children })
{
    return (
        <>
            <PublicNavbar/>
            <div className="d-flex flex-grow-1">
                <PublicSidebar/>
                <div className="d-flex flex-grow-1 flex-column" id="main">
                    { children }
                </div>
            </div>
            <PublicFooter />
        </>
    );
}
