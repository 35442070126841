import { Link } from "react-router-dom"
import "./Breadcrumbs.scss"


export default function Breadcrumbs({ links }: { links: { name: string, href?: string }[] })
{
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                { links.map((link, i) => {
                    return link.href ? 
                        <li className="breadcrumb-item" key={i}>
                            <Link to={link.href} title={link.name}>{link.name}</Link>
                        </li> :
                        <li className="breadcrumb-item active" key={i}>
                            <span title={link.name}>{link.name}</span>
                        </li>
                })}
            </ol>
        </nav>
    )
}