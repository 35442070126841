import { ReactNode, useEffect, useRef, useState }  from "react"
import "./Collapse.scss"
import { classList } from "../../../lib";


export default function Collapse({
    header,
    children,
    collapsed,
    className = "",
    headerClassName = "",
    bodyClassName = ""
}: {
    header    : ReactNode
    children  : ReactNode
    collapsed?: boolean
    className?: string
    headerClassName?: string
    bodyClassName?: string
}) {
    const [isCollapsed, setCollapsed] = useState(!!collapsed)

    const refBody = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (refBody.current) {
            refBody.current.style.height = refBody.current.scrollHeight + "px";
            if (isCollapsed) {
                refBody.current.style.height = "0"
            }
        }
    }, [isCollapsed, refBody])

    
    const onTransitionEnd = () => {
        if (refBody.current) {
            refBody.current.style.height = isCollapsed ? "0" : "auto"
            refBody.current.classList.remove("animating")
        }
    }
    
    const onTransitionStart = () => {
        if (refBody.current) {
            refBody.current.classList.add("animating")
            refBody.current.style.height = refBody.current.scrollHeight + "px";
        }
    }
    
    const toggle = () => {
        onTransitionStart()
        setCollapsed(!isCollapsed)
    }
    
    // eslint-disable-next-line
    useEffect(() => onTransitionEnd(), []);

    return (
        <div className={classList({ "vm-collapse": true, [className]: true, collapsed : isCollapsed })}>
            <div
                className={classList({ "vm-collapse-header": true, [headerClassName]: true })}
                onClick={toggle}
                tabIndex={0}
                onKeyDown={e => {
                    if (e.key === " " || e.key === "Enter") {
                        e.preventDefault();
                        toggle()
                    }
                }}>
                {/* { typeof header === "string" && <i className={ "bi bi-caret-" + (isCollapsed ? "right" : "down") + "-fill" }/> } */}
                {header}
            </div>
            <div
                className={classList({ "vm-collapse-body": true, [bodyClassName]: true })}
                ref={refBody}
                onTransitionEnd={onTransitionEnd}>
                {children}
            </div>
        </div>
    )
}