import { useState }           from "react"
import validator              from "validator"
import FrequencySelector      from "./FrequencySelector"
import StatusCodesSelector    from "./StatusCodesSelector"
import RequestBodyEditor      from "./RequestBodyEditor"
import ParamList              from "./ParamList"
import httpRequestHeaders     from "./httpRequestHeaders"
import Toggle                 from "../../../Components/generic/Toggle"
import ValidatedInput         from "../../../Components/generic/ValidatedInput/"
import Collapse               from "../../../Components/generic/Collapse"
import { NameValuePairsList } from "../../../lib"
import {
    HttpMonitorOptions,
    MonitorModel,
    MonitorRequestMethod
} from "../../../types"


export function Form({
    data = {},
    onSubmit
}: {
    data?: Partial<MonitorModel<HttpMonitorOptions>>
    onSubmit: (props: typeof data) => void
}) {
    const [rec, setRec] = useState(data)

    // console.log(JSON.stringify(rec, null, 4))

    function handleSubmit(e) {
        e.preventDefault()
        onSubmit({ ...rec, options: { ...rec.options, type: "http" }})
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="mb-4 col-md">
                    <label htmlFor="name" className="form-label">
                        <b className="text-primary-emphasis">Name</b>
                    </label> 
                    <input
                        className="form-control"
                        id="name"
                        type="text"
                        name="name"
                        maxLength={100}
                        value={rec.name || ""}
                        onChange={e => setRec({ ...rec, name: e.target.value })}
                    />
                    <div className="form-text small">Give your monitor a descriptive name</div>
                </div>
                <div className="mb-4 col-md-auto">
                    <label className="form-label text-primary-emphasis"><b>Monitor Status</b></label>
                    <div>
                        <Toggle
                            offLabel={ <span data-tooltip="Use this to temporarily stop your monitor">Paused</span> }
                            onLabel="Active"
                            checked={rec.enabled !== false}
                            onChange={enabled => setRec({ ...rec, enabled })}
                            className="text-primary"
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <label htmlFor="description" className="form-label">
                    <b className="text-primary-emphasis">Description</b>
                </label>
                <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    rows={3}
                    value={rec.description || ""}
                    onChange={e => setRec({ ...rec, description: e.target.value })}
                />
                <div className="form-text small">Give your monitor a short description</div>
            </div>
            <div className="mb-4">
                <label className="form-label">
                    <b className="text-primary-emphasis">Check Frequency</b>
                </label>
                <FrequencySelector value={ rec.frequency ?? 60 * 60 } onChange={frequency => setRec({ ...rec, frequency })} />
            </div>
            <div className="row">
                <div className="mb-4 col-md-3">
                    <label htmlFor="method" className="form-label">
                        <b className="text-primary-emphasis">Request Method</b>
                    </label>
                    <select
                        className="form-select"
                        id="method"
                        value={rec.options?.method || "GET"}
                        onChange={e => setRec({ ...rec, options: { ...rec.options, method: e.target.value as MonitorRequestMethod }})}
                    >
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="HEAD">HEAD</option>
                        <option value="OPTIONS">OPTIONS</option>
                        <option value="PUT">PUT</option>
                        <option value="PATCH">PATCH</option>
                    </select>
                    <div className="form-text small">
                        Some methods are not allowed for security reasons
                    </div>
                </div>
                <div className="mb-4 col-md">
                    <label htmlFor="url" className="form-label">
                        <b className="text-primary-emphasis">URL</b>
                    </label> 
                    <ValidatedInput
                        className="form-control"
                        id="url"
                        type="url"
                        name="url"
                        required
                        placeholder="Example: https://domain.com"
                        value={rec.options?.url}
                        onChange={e => setRec({ ...rec, options: { ...rec.options, url: e.target.value }})}
                        aria-errormessage={ !validator.isURL(rec.options?.url ?? "") ? "Invalid URL" : undefined }
                    />
                    <div className="form-text small">Give your monitor a descriptive name</div>
                </div>
                {/* <div className="mb-4 col-md-6">
                    <label htmlFor="retry" className="form-label">
                        <b className="text-primary-emphasis">Retry</b>
                    </label>
                    <input
                        className="form-control"
                        id="retry"
                        type="number"
                        min={0}
                        max={10}
                        value={rec.options?.retry || 0}
                        onChange={e => setRec({ ...rec, options: { ...rec.options, retry: e.target.valueAsNumber }})}
                    />
                    <div className="form-text small">How many times to retry failed requests</div>
                </div> */}
            </div>
            <Collapse
                header="Advanced"
                // header={<div className="bg-primary-subtle bg-gradient border-primary color-primary px-1" style={{
                //     borderBottom: "1px solid"
                // }}>Advanced</div>}
                // headerClassName="bg-primary-subtle bg-gradient"
                collapsed
            >
                <>
                    <div className="row mt-4">
                        <div className="mb-4 col-md-3">
                            <label htmlFor="redirects" className="form-label">
                                <b className="text-primary-emphasis">Redirects</b>
                            </label>
                            <input
                                className="form-control"
                                id="redirects"
                                type="number"
                                min={0}
                                max={10}
                                value={rec.options?.redirects || 0}
                                onChange={e => setRec({ ...rec, options: { ...rec.options, redirects: e.target.valueAsNumber }})}
                            />
                            <div className="form-text small">How many redirects to follow</div>
                        </div>
                        <div className="mb-4 col-md-3">
                            <label htmlFor="redirects" className="form-label">
                                <b className="text-primary-emphasis">Timeout</b>
                            </label>
                            <input
                                className="form-control"
                                id="redirects"
                                type="number"
                                min={1}
                                max={60}
                                value={rec.options?.timeout || 30}
                                onChange={e => setRec({ ...rec, options: { ...rec.options, timeout: e.target.valueAsNumber }})}
                            />
                            <div className="form-text small">
                                Request timeout in seconds
                            </div>
                        </div>
                        <div className="mb-4 col-md">
                            <label htmlFor="search" className="form-label">
                                <b className="text-primary-emphasis">Search Text</b>
                            </label>
                            <div className="input-group">
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={rec.options?.search?.text || ""}
                                    onChange={e => setRec({
                                        ...rec,
                                        options: {
                                            ...rec.options,
                                            search: {
                                                ...rec.options?.search,
                                                text: e.target.value
                                            }
                                        }
                                    })}
                                />
                                <button
                                    className={ "input-group-text" + (rec.options?.search?.caseSensitive ? " btn btn-primary border-dark" : "") }
                                    type="button"
                                    title="Case-Sensitive Match"
                                    onClick={() => setRec({
                                        ...rec,
                                        options: {
                                            ...rec.options,
                                            search: {
                                                ...rec.options?.search,
                                                caseSensitive: !rec.options?.search?.caseSensitive
                                            }
                                        }
                                    })}
                                >Aa</button>
                                <button
                                    className={ "input-group-text" + (rec.options?.search?.wholeWord ? " btn btn-primary border-dark" : "") }
                                    type="button"
                                    title="Match Whole Words Only"
                                    onClick={() => setRec({
                                        ...rec,
                                        options: {
                                            ...rec.options,
                                            search: {
                                                ...rec.options?.search,
                                                wholeWord: !rec.options?.search?.wholeWord
                                            }
                                        }
                                    })}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="d-block">
                                        <path d="M40-199v-200h80v120h720v-120h80v200H40Zm342-161v-34h-3q-13 20-35 31.5T294-351q-49
                                        0-77-25.5T189-446q0-42 32.5-68.5T305-541q23 0 42.5 3.5T381-526v-14q0-27-18.5-43T312-599q-21
                                        0-39.5 9T241-564l-43-32q19-27 48-41t67-14q62 0 95 29.5t33 85.5v176h-59Zm-66-134q-32 0-49
                                        12.5T250-446q0 20 15 32.5t39 12.5q32 0 54.5-22.5T381-478q-14-8-32-12t-33-4Zm185
                                        134v-401h62v113l-3 40h3q3-5 24-25.5t66-20.5q64 0 101 46t37 106q0 60-36.5 105.5T653-351q-41
                                        0-62.5-18T563-397h-3v37h-59Zm143-238q-40 0-62 29.5T560-503q0 37 22 66t62 29q40 0
                                        62.5-29t22.5-66q0-37-22.5-66T644-598Z" stroke="currentColor" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>
                            <div className="form-text small">
                                Expect the response body to contain the specified string or
                                to match the given regular expression
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="method">
                            <b className="text-primary-emphasis">Request Headers</b>
                        </label>
                        <div className="form-text small mt-0 mb-1">
                            Some headers like <code>Host</code>, <code>Content-Length</code>, <code>Connection</code>, <code>Trailer</code>
                            , and <code>Upgrade</code> are automatically handled and should not be set manually.
                            <div className="text-warning-emphasis">
                                <i className="bi bi-exclamation-triangle me-1" /> Invalid header names or values can result in failing requests!
                            </div>
                        </div>
                        <ParamList
                            dataList={ httpRequestHeaders.map(x => ({ label: x.name, value: x.name, description: x.description })) }
                            params={ Object.keys(rec.options?.headers ?? {}).map(key => ([ key, rec.options.headers[key] ])) ?? [] }
                            onChange={ headers => {
                                setRec({
                                    ...rec,
                                    options: {
                                        ...rec.options,
                                        headers: NameValuePairsList.toObject(headers, "list")
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="method">
                            <b className="text-primary-emphasis">Request Body</b>
                        </label>
                        <div className="form-text small">
                            What payload should we send to this URL
                        </div>
                        <hr className="mt-1 mb-3" />
                        <RequestBodyEditor
                            type={ rec.options?.requestBodyType as any }
                            value={ rec.options?.body ?? null }
                            onChange={ ({ type, value }) => {
                                const nextState = {
                                    ...rec,
                                    options: {
                                        ...rec.options,
                                        requestBodyType: type,
                                        body: value
                                    }
                                }
                                if (type === "x-www-form-urlencoded") {
                                    nextState.options.headers = {
                                        ...nextState.options.headers,
                                        "content-type": "application/x-www-form-urlencoded"
                                    }
                                } else if (type === "raw") {
                                    // TODO: auto-detect type header
                                    try {
                                        JSON.parse(value)
                                        nextState.options.headers = {
                                            ...nextState.options.headers,
                                            "content-type": "application/json"
                                        }
                                    } catch {
                                        if (
                                            !nextState.options.headers["content-type"] ||
                                            nextState.options.headers["content-type"] === "application/json" ||
                                            nextState.options.headers["content-type"] === "application/x-www-form-urlencoded"
                                        ) {
                                            nextState.options.headers = {
                                                ...nextState.options.headers,
                                                "content-type": "text/plain"
                                            }
                                        }
                                    }
                                } else {
                                    if (nextState.options.headers["content-type"]) {
                                        delete nextState.options.headers["content-type"]
                                    }
                                }
                                setRec(nextState)
                            }}
                        />
                    </div>
                    <div className="mb-4">
                        <h4 className="mt-4 mb-0 text-center">Status Codes</h4>
                        <div className="form-text text-center">
                            Decide which response codes are treated as successful and
                            which should be considered errors
                        </div>
                        <StatusCodesSelector data={ rec.options?.statusCodes || {} } onChange={ statusCodes => setRec({ ...rec, options: { ...rec.options, statusCodes } })} />
                    </div>
                </>
            </Collapse>
            <br />
            
            <div className="mb-4 text-center border-secondary-subtle border-top rounded-bottom rounded-bottom-3 p-3">
                <button className="btn btn-primary bg-gradient px-5">Save</button>
            </div>
        </form>
    )
}
