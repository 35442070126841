import { classList } from "../../../lib"
import "./Loader.scss"


export default function Loader(
    {
        message,
        className,
        color = "secondary",
        type = "border",
        small,
        speed
    }:
    {
        message?: React.ReactNode
        className?: string | undefined
        color?: "primary"|"secondary"|"success"|"danger"|"warning"|"info"|"light"|"dark"|"white"
        type?: "grow" | "border",
        small?: boolean
        speed?: string
    }
) {
    return <span className={className}>
        <span className={ classList({
            ["spinner-" + type + "-sm"]: !!small,
            ["spinner-" + type]: true,
            ["text-" + color]: true,
            "me-1": !!message
        })} role="status" style={
            speed ? {
                // @ts-ignore
                "--bs-spinner-animation-speed": speed
            } : undefined
        }/>{ message }
    </span>
}