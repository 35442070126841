import { KeyboardEvent, ReactNode } from "react"
import { classList } from "../../../lib"
import "./Toggle.scss"


export default function Toggle({
    checked,
    onChange,
    className,
    disabled,
    offLabel,
    onLabel
}: {
    checked   : boolean
    onChange  : (checked: boolean) => void
    className?: string
    disabled ?: boolean
    onLabel  ?: ReactNode
    offLabel ?: ReactNode
}) {
    
    function onkeydown(e: KeyboardEvent) {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault()
            onChange(!checked)
        }
        if (e.key === "ArrowLeft") {
            e.preventDefault()
            onChange(false)
        }
        if (e.key === "ArrowRight") {
            e.preventDefault()
            onChange(true)
        }
    }

    return (
        <span className={ classList({
            toggleWrap: true,
            disabled
        })}>
            { offLabel && <label
                className={ classList({
                    "user-select-none": true,
                    "opacity-50": checked
                }) }
                onClick={() => onChange(false)}
            >{offLabel}</label> }
            <b
                className={ classList({
                    "toggle": true,
                    "focus-ring": true,
                    "user-select-none": true,
                    "bg-gradient": true,
                    [className || ""]: !!className,
                    "disabled": !!disabled,
                    "on": !!checked
                })}
                tabIndex={0}
                onKeyDown={onkeydown}
                onClick={() => onChange(!checked)}
            />
            { onLabel && <label
                className={ classList({
                    "user-select-none": true,
                    "opacity-50": !checked
                }) }
                onClick={() => onChange(true)}
            >{onLabel}</label> }
        </span>
    )
}