import { useEffect, useMemo, useState } from "react"
import { request }                      from "../../../lib"
import StaticDataGrid                   from "./StaticDataGrid"
import { DataGridProps }                from "./types"
import "./DataGrid.scss"


export interface BackendDataGridProps extends DataGridProps {
    url: string | URL
}

export default function DataGrid(props: BackendDataGridProps) {
    
    const {
        onSelectionChange,
        selection,
        className,
        columns,
        limit = 15
    } = props
    
    const [sortColumn , setSortColumn ] = useState("id")
    const [sortDir    , setSortDir    ] = useState<"asc"|"desc">("asc")
    const [offset     , setOffset     ] = useState(0)
    const [where      , setWhere      ] = useState<any>({})
    const [loading    , setLoading    ] = useState(false)
    const [error      , setError      ] = useState<Error | null>(null)
    const [result     , setResult     ] = useState<{ count: number, rows: any[] }>({ count: 0, rows: [] })
    
    const abortController = useMemo(() => new AbortController(), [])
    
    const { count, rows } = result;

    const fetch = (options: {
        sortColumn?: string
        sortDir   ?: "asc"|"desc"
        offset    ?: number
        where     ?: Record<string, { operator: string; value: string }>
    } = {}) => {
        const url = new URL(props.url, window.location.origin)
        url.searchParams.set("order", `${options.sortColumn || sortColumn}:${options.sortDir || sortDir}`)
        url.searchParams.set("limit", limit + "")
        url.searchParams.set("offset", String(options.offset ?? offset))

        if (options.where) {
            url.searchParams.set("where", Object.keys(options.where).map(key => {
                if (!options.where[key].value) return false
                return `${key}:${encodeURIComponent(options.where[key].operator)}:${encodeURIComponent(options.where[key].value)}`
            }).filter(Boolean).join("|"))
        }

        setLoading(true)
        request(url, { signal: abortController.signal})
            .then(res => {
                if (!abortController.signal.aborted) {
                    setResult(res.body)
                    setError(null)
                }
            })
            .catch(err => {
                if (!abortController.signal.aborted) {
                    setError(err)
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    if (options.sortColumn) setSortColumn(options.sortColumn)
                    if (options.sortDir) setSortDir(options.sortDir)
                    if (options.offset !== undefined) setOffset(options.offset)
                    if (options.where) setWhere(options.where)
                    setLoading(false)
                }
            })
    }

    useEffect(fetch, [])
    useEffect(() => () => abortController.abort(), [ abortController ]);

    return <StaticDataGrid
        columns={ columns }
        className={ className }
        count={ count }
        offset={ offset }
        limit={ limit }
        rows={ rows }
        sortColumn={ sortColumn }
        sortDir={ sortDir }
        selection={ selection }
        onSelectionChange={ onSelectionChange }
        identity="id"
        filters={ where }
        onPaginationChange={ offset => fetch({ offset }) }
        onSortChange={ (sortColumn, sortDir) => fetch({ sortColumn, sortDir, offset: 0 }) }
        onFilterChange={ where => fetch({ where }) }
        loading={ loading }
        error={ error }
    />
}
