import { Route, Routes } from "react-router"
import ListAllMonitors   from "../Monitors/ListAll"
import AllHistoryList    from "../History/ListAll"
import AllIncidentsList  from "../Incidents/ListAll"
import { Dashboard }     from "./Dashboard"
import ConfigManager     from "./ConfigManager"


export default function Admin() {
    return (
        <Routes>
            <Route path="monitors">
                <Route index Component={ListAllMonitors} />
                {/* <Route path="new" Component={ Create } />
                <Route path="delete" Component={ DeleteMany } />
                <Route path=":id">
                    <Route index Component={ View } />
                    <Route path="edit" Component={ Edit } />
                    <Route path="delete" Component={ Delete } />
                </Route> */}
            </Route>
            <Route path="history">
                <Route index Component={AllHistoryList} />
            </Route>
            <Route path="incidents">
                <Route index Component={AllIncidentsList} />
            </Route>
            <Route path="dashboard">
                <Route index Component={Dashboard} />
            </Route>
            <Route path="config">
                <Route index Component={ConfigManager} />
            </Route>
        </Routes>
    )
}