import { Link } from "react-router-dom"
import DataGrid from "../../Components/generic/DataGrid"
import ListPage from "../../Components/generic/Scaffolding/EndpointListWrapper"


export default function TeamsList() {
    return (
        <ListPage namePlural="Teams" icon={<i className="bi bi-people" />}>
            <DataGrid
                url="/api/v1/teams"
                identity="id"
                className="table-sm table-hover"
                columns={[
                    {
                        label     : "Name",
                        propName  : "name",
                        sortProp  : "name",
                        dataType  : "string",
                        renderCell: rec => <Link className="d-block text-truncate" to={`./${rec.id}`} style={{ maxWidth: "45vw" }}>{ rec.name }</Link>
                    },
                    {
                        label     : "Description",
                        propName  : "description",
                        sortProp  : "description",
                        dataType  : "string",
                        renderCell: row => <div className="text-muted">{row.description}</div>
                    },
                    {
                        label     : "Members",
                        dataType  : "number",
                        renderCell: row => row.Members.length
                    },
                    {
                        label     : "Created",
                        propName  : "createdAt",
                        sortProp  : "createdAt",
                        dataType  : "date",
                        renderCell: row => new Date(row.createdAt).toLocaleDateString()
                    }
                ]}
            />

        </ListPage>
    )
}