import { FormEvent } from "react"
import Slack         from "./Slack"
import EmailForm     from "./EmailForm"
import Section       from "../../Components/generic/Section"
// import MsTeamsForm   from "./MsTeamsForm"
// import SkypeForm     from "./SkypeForm"


export default function NotificationsForm({ })
{
    function handleSubmit(e: FormEvent) {
        e.preventDefault()
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md">
                        <Section className="mt-5" title={<><i className="bi bi-envelope me-2" />Email</>}>
                            <EmailForm />
                        </Section>
                    </div>
                    <div className="col-md">
                        <div className="heading mt-5">
                            <Section className="mt-5" title={<><i className="bi bi-slack me-2" />Slack</>}>
                                <Slack />
                            </Section>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-md">
                        <div className="heading mt-5">
                            <h5 className="text-primary"><i className="bi bi-microsoft-teams me-2" />Microsoft Teams</h5>
                            <hr className="mt-1 border-primary" />
                        </div>
                        <fieldset className="grid mb-5" disabled>
                            <MsTeamsForm />
                        </fieldset>
                    </div>
                    <div className="col-md">
                        <div className="heading mt-5">
                            <h5 className="text-primary"><i className="bi bi-skype me-2" />Skype</h5>
                            <hr className="mt-1 border-primary" />
                        </div>
                        <fieldset className="grid mb-5" disabled>
                            <SkypeForm />
                        </fieldset>
                    </div>
                </div> */}
            </form>
        </div>
    )
}
