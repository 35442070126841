import { useLocation }  from "react-router-dom";
import { useAuth }      from "../auth";
import { PublicLayout } from "./PublicLayout";
import { UserLayout }   from "./UserLayout";
import "../style.scss"


export function Layout({ children }) {
    const { user } = useAuth()
    const { pathname } = useLocation()

    if (user && (pathname.startsWith("/my") || pathname.startsWith("/admin"))) {
        return <UserLayout>{ children }</UserLayout>
    } else {
        return <PublicLayout>{ children }</PublicLayout>
    }
}