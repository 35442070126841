import { useEffect, useMemo, useState } from "react"
import { Helmet, HelmetProvider }       from "react-helmet-async"
import { useNavigate }                  from "react-router"
import { request }                      from "../../../lib"
import ErrorAlert                       from "../ErrorAlert"
import Breadcrumbs                      from "../Breadcrumbs"
import { Link } from "react-router-dom"


export default function EndpointCreateWrapper({
    endpoint,
    children
}: {
    endpoint: string
    children: (props: {
        loading : boolean
        data    : any
        onSubmit: (data: any) => void
        error   : Error | string | null
    }) => JSX.Element
})
{
    const navigate = useNavigate()
    const [saving, setSaving] = useState(false)
    const [saveError  , setSaveError] = useState<Error|string|null>(null)
    const abortController = useMemo(() => new AbortController(), [])

    function onSubmit(data: Record<string, any>) {
        setSaving(true)
        setSaveError(null)

        request(endpoint, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "content-type": "application/json" },
            signal: abortController.signal
        })
        .then(() => {
            if (!abortController.signal.aborted) {
                navigate("..")
            }
        })
        .catch(error => {
            if (!abortController.signal.aborted) {
                setSaveError(error)
                setSaving(false)
            }
        })
    }

    useEffect(() => () => abortController.abort(), [ abortController ]);

    return children({
        loading : saving,
        data    : {},
        onSubmit,
        error   : saveError
    })
}

export function createCreatePage<T = unknown>({
    nameSingular,
    namePlural,
    endpoint,
    basePath,
    renderForm
}: {
    nameSingular     : string
    namePlural       : string
    endpoint         : string
    basePath         : string
    renderForm       : (props: {
        loading : boolean
        data    : T
        onSubmit: (data: Partial<T>) => void
    }) => JSX.Element
})
{
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Create { nameSingular }</title>
                </Helmet>
            </HelmetProvider>
            <EndpointCreateWrapper endpoint={ endpoint }>
                {({ data, error, loading, onSubmit }) => (
                    <>
                        <Breadcrumbs links={[
                            { name: "Home", href: "/" },
                            { name: namePlural, href: basePath },
                            { name: "Create " + nameSingular }
                        ]} />
                        <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
                            <h4 className="pe-2 text-truncate my-0 text-primary-emphasis">
                                <i className="bi bi-plus-circle me-2" />Create { nameSingular }
                            </h4>
                            <div className="d-flex">
                                <Link to="../../" className="btn btn-sm btn-virtual">
                                    <i className="bi bi-card-list text-primary me-1" />
                                    View All
                                </Link>
                            </div>
                        </div>
                        <hr className="mt-1" />
                        { error && <ErrorAlert>{ error }</ErrorAlert> }
                        { renderForm({ data: data as T, onSubmit, loading }) }
                    </>
                )}
            </EndpointCreateWrapper>
        </div>
    )
}
