import LocalDataGrid        from "../../Components/generic/DataGrid/LocalDataGrid"
import { createDeletePage } from "../../Components/generic/Scaffolding/EndpointDeleteWrapper"
import { Team }             from "../../types"


export default function Delete()
{
    return createDeletePage<Team>({
        endpoint    : "/api/v1/teams",
        namePlural  : "Teams",
        nameSingular: "Team",
        icon        : <i className="bi bi-people" />,
        // query       : "creator=true&graphs=true&subscriptions=true",
        renderView  : data => <>
            { data.description && <p>{ data.description }</p> }
            <div className="row gap-2 mt-2 wrap">
                <div className="col mb-2">
                    <h5><i className="bi bi-people-fill text-primary-emphasis me-2" />Team Members</h5>
                    <hr className="mt-0" />
                    <p className="text-danger">
                        Note that team members will NOT be deleted. They will remain
                        as users but will no longer be members of this team. 
                    </p>
                    <LocalDataGrid
                        className="table-sm bg-opacity-100 border m-0"
                        columns={[
                            {
                                propName: "name",
                                sortProp: "name",
                                label   : "Name",
                                dataType: "string"
                            },
                            {
                                propName: "email",
                                sortProp: "email",
                                label   : "Email",
                                dataType: "string",
                                renderCell: row => <span className="text-primary">{ row.email }</span>
                            },
                            {
                                propName: "TeamMember.status",
                                sortProp: "TeamMember.status",
                                label   : "Status",
                                dataType: "string",
                                renderCell: row => row.TeamMember.status === "pending" ?
                                    <span className="badge text-bg-warning">pending</span> :
                                    row.TeamMember.status === "rejected" ?
                                        <span className="badge text-bg-danger">rejected</span> :
                                        <span className="badge text-bg-success">accepted</span>
                            },
                            {
                                propName: "TeamMember.createdAt",
                                sortProp: "TeamMember.createdAt",
                                label   : "Invited",
                                dataType: "string",
                                renderCell: row => new Date(row.TeamMember.createdAt).toLocaleDateString()
                            }
                        ]}
                        rows={ data.Members }
                    />
                </div>
            </div>
        </>
    })
}
