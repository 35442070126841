import TypeAhead, { TypeAheadOption } from "../../../Components/generic/TypeAhead"


export default function ParamList({
    params,
    onChange,
    dataList = []
}: {
    /**
     * TypeAhead suggestions
     */
    dataList?: TypeAheadOption[]

    /**
     * Current list
     */
    params: [string, string][]

    /**
     * To be called with the new params list when it changes
     */
    onChange: (p: typeof params) => void
}) {
    const rows = []
    const size = params.length


    params.forEach(([name, value], i) => {
        rows.push(
            <tr key={i}>
                <td className="w-50 small">
                    <TypeAhead
                        className="form-control-sm form-select-sm"
                        value={ name }
                        options={ dataList }
                        onChange={name => {
                            params[i] = [ name, value ]
                            onChange(params)
                        }}
                    />
                </td>
                <td className="w-50">
                    <input
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Value"
                        value={ value }
                        onChange={e => {
                            params[i] = [ name, e.target.value ]
                            onChange(params)
                        }}
                    />
                </td>
                <td className="p-0">
                    <button
                        className="btn btn-sm vi-remove-row"
                        type="button"
                        data-tooltip="Remove Entry"
                        onClick={() => {
                            params.splice(i, 1)
                            onChange(params)
                        }}
                    >
                        <i className="bi bi-trash text-danger" />
                    </button>
                </td>
            </tr>
        )
    })

    return (
        <div className="vi-param-list">
            <div className=" border-top border-2 my-1"></div>
            <table className="table align-middle table-sm table-borderless mb-0">
                <tbody>
                    { size ? rows : <tr>
                        <td className="p-0" colSpan={3}>
                            <div className="text-center small text-secondary">
                                No entries
                            </div>   
                        </td>
                    </tr> }
                </tbody>
                <tfoot>
                    <tr>
                        <td className="p-0" colSpan={3}>
                            <div className="text-center rounded-bottom mt-1 p-1 border-top border-2">
                                <button className="btn btn-sm text-success" type="button" onClick={() => {
                                    onChange([ ...params, ["", ""]])
                                }}>
                                    <i className="bi bi-plus-circle" /> Add Entry
                                </button>
                            </div>   
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}
