
export default function PageHeader({ title, description, style }: { title: string, description?: string, style?: React.CSSProperties }) {
    return (
        <div style={{ background: "linear-gradient(0deg, #ADF0, #cfe2ff)", textShadow: "0 1px 1px #FFFC", ...style }}>
            <div className="container text-center p-4 px-3 px-md-5">
                <h3 className="text-primary-emphasis mb-3">{ title }</h3>
                { description && <p className="text-primary-emphasis opacity-75">{ description }</p> }
            </div>
        </div>
    )
}