import { useLayoutEffect, useRef, useState } from "react"

export default function Tooltip({
    points,
    width,
    height,
    children
}: {
    width: number
    height: number
    children?: JSX.Element
    points: {
        x: number
        y: number
    }[]
})
{
    const tooltipRef = useRef<HTMLDivElement>(null);

    const [pos, setPos] = useState(null);

    useLayoutEffect(() => {
        const tooltip = tooltipRef.current;
        if (tooltip) {
            const rect = tooltip.getBoundingClientRect()
            let { x, y } = rect
            if (points[0].x + 20 + rect.width > width - 10) {
                x = points[0].x - 20 - rect.width
            } else {
                x = points[0].x + 20
            }

            const minY = points.reduce((prev, cur) => Math.min(prev, cur.y), Infinity)
            const maxY = points.reduce((prev, cur) => Math.max(prev, cur.y), -Infinity)
            const midY = minY + (maxY - minY) / 2
            y = midY - rect.height / 2
            setPos({ x, y });
        }
    }, [points]);

    return (
        <div ref={tooltipRef} style={{
            position      : "absolute",
            // opacity       : pos ? 1 : 0,
            left          : pos ? pos.x : undefined,//width/2,
            top           : pos ? pos.y : undefined,//height/2,
            boxShadow     : "0 0 0.5px 0.5px #0008, 0 0 0 1px #FFF6 inset, 0 2px 7px -1px #0006",
            zIndex        : 200,
            background    : "#DDD8",
            pointerEvents : "none",
            backdropFilter: "blur(7px)",
            borderRadius  : 4,
            padding       : "16px",
            transition    : "all 0.2s ease",
            fontSize      : "0.8em",
            lineHeight    : 1.3,
            textShadow    : "0 1px 1px #FFF8",
            whiteSpace    : "nowrap"
        }}>
            { children }
        </div>
    )
}