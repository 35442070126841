import { FormEvent, useState }                from "react"
import validator                              from "validator"
import FrequencySelector                      from "./FrequencySelector"
import { MonitorModel, DomainMonitorOptions } from "../../../types"
import Toggle                                 from "../../../Components/generic/Toggle"
import ValidatedInput                         from "../../../Components/generic/ValidatedInput"


export function Form({
    data = { frequency: 60 * 60 * 24 * 7 },
    onSubmit
}: {
    data?: Partial<MonitorModel<DomainMonitorOptions>>
    onSubmit: (props: typeof data) => void
}) {
    const [rec, setRec] = useState(data)

    function handleSubmit(e: FormEvent) {
        e.preventDefault()
        onSubmit({ ...rec, options: { ...rec.options, type: "domain" }})
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="mb-4 col-md">
                    <label htmlFor="name" className="form-label">
                        <b className="text-primary-emphasis">Name</b>
                    </label> 
                    <input
                        className="form-control"
                        id="name"
                        type="text"
                        name="name"
                        maxLength={100}
                        value={rec.name || ""}
                        onChange={e => setRec({ ...rec, name: e.target.value })}
                        placeholder="Give your monitor a descriptive name"
                        required
                    />
                </div>
                <div className="mb-4 col-md-auto">
                    <label className="form-label text-primary-emphasis"><b>Monitor Status</b></label>
                    <div>
                        <Toggle
                            offLabel={ <span data-tooltip="Use this to temporarily stop your monitor">Paused</span> }
                            onLabel="Active"
                            checked={rec.enabled !== false}
                            onChange={enabled => setRec({ ...rec, enabled })}
                            className="text-primary"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                        <b className="text-primary-emphasis">Description</b>
                    </label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        rows={3}
                        value={rec.description || ""}
                        onChange={e => setRec({ ...rec, description: e.target.value })}
                        placeholder="Give your monitor a short description"
                    />
                </div>
            </div>
            <div className="row">
                <div className="mb-4 col-md">
                    <label htmlFor="host" className="form-label">
                        <b className="text-primary-emphasis">Domain</b>
                    </label>
                    <ValidatedInput
                        value={ rec.options?.domain }
                        onChange={e => setRec({ ...rec, options: { ...rec.options, domain: e.target.value }})}
                        className="form-control"
                        aria-errormessage={validator.isFQDN(rec.options?.domain ?? "") ? undefined : "Invalid domain name"}
                        placeholder="Hostname or IP address"
                        required
                    />
                </div>
                <div className="mb-4 col-md">
                    <label className="form-label text-primary-emphasis"><b>Protocol</b></label>
                    <select
                        value={ rec.options?.protocol }
                        onChange={ e => setRec({ ...rec, options: { ...rec.options, protocol: e.target.value as any }}) }
                        className="form-select"
                    >
                        <option value="https">HTTPS</option>
                        <option value="http">HTTP</option>
                    </select>
                </div>
                <div className="mb-4 col-auto">
                    <label className="form-label text-primary-emphasis"><b>Certificate Expiration</b></label>
                    <div>
                        <Toggle
                            offLabel="Don't Check"
                            onLabel="Check"
                            disabled={rec.options?.protocol === "http"}
                            checked={rec.options?.checkCertificate }
                            onChange={checkCertificate => setRec({ ...rec, options: { ...rec.options, checkCertificate } })}
                            className="text-primary disabled"
                        />
                    </div>
                </div>
            </div>
            
            <div className="mb-4">
                <label className="form-label">
                    <b className="text-primary-emphasis">Check Frequency</b>
                </label>
                <FrequencySelector value={ rec.frequency ?? 60 * 60 * 24 * 7 } onChange={frequency => setRec({ ...rec, frequency })}
                options={[
                    { frequency: 60 * 60 * 24 * 7 * 4 },
                    { frequency: 60 * 60 * 24 * 7 },
                    { frequency: 60 * 60 * 24 },
                    { frequency: 60 * 60 * 12 },
                    { frequency: 60 * 60, label: <span className="badge rounded-pill d-block text-bg-warning text-truncate" data-tooltip="Included with the Advanced or the Pro plan">Advanced</span> },
                    { frequency: 60 * 30, label: <span className="badge rounded-pill d-block text-bg-danger  text-truncate" data-tooltip="Included with the Pro plan">Pro</span> },
                ]}
                />
            </div>
            <div className="mb-4 text-center bg-body-secondary bg-gradient border-secondary-subtle border-top rounded-bottom rounded-bottom-3 p-3">
                <button className="btn btn-primary bg-gradient px-5">Save</button>
            </div>
        </form>
    )
}
