import { useLocation }     from "react-router-dom"
import ResetPasswordStage2 from "./ResetPasswordStage2"
import ResetPasswordStage1 from "./ResetPasswordStage1"


export function ResetPassword() {
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const code  = query.get("code")

    if (code) {
        return <ResetPasswordStage2 />
    }

    return <ResetPasswordStage1 />
}
