import PageHeader from "../../Components/PageHeader"
import Page from "../Page"


export function Pricing() {
    return <Page header={
        <PageHeader title="Quality Service, Competitive Rates" description="Choose the Perfect Plan for You"/>
    }>
        <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
                <div className="card h-100">
                    <div className="card-body">
                        <h2 className="card-title text-center">Free</h2>
                        <h4 className="text-center">
                            <span className="text-muted">$</span>
                            <span>0</span>
                            <span className="text-muted">/month</span>
                        </h4>
                        <h4 className="text-center">
                            <span className="text-muted">$</span>
                            <span>0</span>
                            <span className="text-muted">/year</span>
                        </h4>
                        <p className="card-text text-muted text-center small">Good for hobby projects. No credit card required!</p>
                        <hr/>
                        <li>50 monitors</li>
                        <li>5 min. monitoring interval</li>
                        <li>Included HTTP, port &amp; ping monitor</li>
                        <li>Included Keyword monitor</li>
                        <li>No SSL &amp; Domain exp. monitor</li>
                        <li>Only 5 integrations</li>
                        <li>Basic status pages</li>
                        <li>Notify seats unavailable</li>
                        <li>No login seats available</li>
                    </div>
                    <div className="card-footer">
                        <div className="text-body-secondary text-center p-2">
                            <a className="btn btn-success w-75" href="/sign-up">Register</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card h-100">
                <div className="card-body">
                    <h2 className="card-title text-center">Advanced</h2>
                    <h4 className="text-center">
                        <span className="text-muted">$</span>
                        <span>5</span>
                        <span className="text-muted">/month</span>
                    </h4>
                    <h4 className="text-center"><span className="text-muted">$</span><span>50</span><span className="text-muted">/year</span></h4>
                    <p className="card-text text-muted text-center small">Great for entrepreneurs and hobbyists.</p>
                    <hr/>
                    <li>100 monitors</li>
                    <li>60 sec. monitoring interval</li>
                    <li>HTTP, port &amp; ping monitor</li>
                    <li>Included Keyword monitor</li>
                    <li>SSL &amp; Domain exp. monitor</li>
                    <li>Only 8 integrations</li>
                    <li>Basic status pages</li>
                    <li>Notify seats available </li>
                    <li>No login seats available</li>
                </div>
                <div className="card-footer">
                    <div className="text-body-secondary text-center p-2">
                        <a className="btn btn-primary w-75" href="/sign-up">Subscribe</a>
                    </div>
                </div>
                </div>
            </div>
            <div className="col">
                <div className="card h-100">
                <div className="card-body">
                    <h2 className="card-title text-center">Pro</h2>
                    <h4 className="text-center"><span className="text-muted">$</span><span>25</span><span className="text-muted">/month</span></h4>
                    <h4 className="text-center"><span className="text-muted">$</span><span>260</span><span className="text-muted">/year</span></h4>
                    <p className="card-text text-muted text-center small">For a small team who needs to collaborate.</p>
                    <hr/>
                    <li>100 monitors</li>
                    <li>60 sec. monitoring interval</li>
                    <li>HTTP, port &amp; ping monitor</li>
                    <li>Included Keyword monitor</li>
                    <li>SSL &amp; Domain exp. monitor</li>
                    <li>All 11 integrations</li>
                    <li>Included Full-featured status pages</li>
                    <li>3 notify seats incl. </li>
                    <li>3 login seats incl. </li>
                </div>
                <div className="card-footer">
                    <div className="text-body-secondary text-center p-2">
                        <a className="btn btn-primary w-75" href="/sign-up">Subscribe</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </Page>
}
