import { Link }    from "react-router-dom"
import { useAuth } from "../../../auth"


export default function CreateMonitorMenuButton() {
    const { user } = useAuth()
    return (
        <div className="dropdown">
            <button className="btn btn-sm btn-virtual border-secondary border-opacity-10 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-plus-circle me-2 text-success" />
                Create
            </button>
            <ul className="dropdown-menu dropdown-menu-end shadow">
                <li>
                    <Link className="dropdown-item" to="/my/monitors/new?type=http">
                        <i className="bi bi-plus-circle text-success me-2" />
                        Create <b>HTTP</b> Monitor
                    </Link>
                </li>
                <li>
                    <Link className="dropdown-item" to="/my/monitors/new?type=port">
                        <i className="bi bi-plus-circle text-danger me-2" />
                        Create <b>Port</b> Monitor
                    </Link>
                </li>
                <li>
                    <Link className="dropdown-item" to="/my/monitors/new?type=ping">
                        <i className="bi bi-plus-circle text-primary me-2" />
                        Create <b>Ping</b> Monitor
                    </Link>
                </li>
                <li>
                    <Link className="dropdown-item" to="/my/monitors/new?type=domain">
                        <i className="bi bi-plus-circle text-info me-2" />
                        Create <b>Domain</b> Monitor
                    </Link>
                </li>
                { user.role === "admin" && <>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <Link className="dropdown-item" to="/my/monitors/new?type=browser">
                            <i className="bi bi-plus-circle text-warning me-2" />
                            Create <b>Browser</b> Monitor
                        </Link>
                    </li>
                </> }
            </ul>
        </div>
    )
}
