import { ReactNode } from "react"


export default function Section({
    title,
    className,
    children,
    color = "primary"
}: {
    title?: ReactNode
    className?: string
    color?: "black" | "primary" | "secondary" | "info" | "warning" | "danger"
    children?: ReactNode
}) {
    return (
        <div className={ className }>
            <h5 className={`fw-semibold text-${color}`}>{ title }</h5>
            <div className={`border-top border-2 border-${color}`}>
                <div className="py-3">{ children }</div>
            </div>
        </div>
    )
}