import { Fragment }         from "react/jsx-runtime"
import { useState }         from "react"
import ScalableSVG          from "./ScalableSVG"
import { Point, Series }    from "./types"
import Rectangle            from "./Rectangle"
import Tooltip              from "./Tooltip"
import { roundToPrecision } from "../../../lib"
import {
    svgPath,
    bezierCommand,
    lineCommand,
    generateTicks,
    scale,
    stepStart,
    stepEnd,
    stepMiddle
} from "./utils"


const DEFAULT_COLORS   = ["#06C", "#C60", "#490", "#B6B", "#808", "#880"]
const COLOR_AXIS       = "#CCC"
const COLOR_AXIS_LABEL = "#000A"
const COLOR_GRID       = "#0003"
const FONT_SIZE        = 10
const MAX_COLUMN_WIDTH = 20


class ChartData
{
    minX  : number =  Number.MAX_VALUE
    maxX  : number = -Number.MAX_VALUE
    minY  : number =  Number.MAX_VALUE
    maxY  : number = -Number.MAX_VALUE
    rangeX: number = 0
    rangeY: number = 0

    length: number = 0

    ticksY: number[] = []
    ticksX: number[] = []

    series: Series[]

    setData(series: Series[])
    {
        series.forEach(s => {
            for (let i = 0; i < s.data.length; i++) {
                const point = s.data[i]
                if (point.y < this.minY) this.minY = point.y
                if (point.y > this.maxY) this.maxY = point.y
                if (point.x < this.minX) this.minX = point.x
                if (point.x > this.maxX) this.maxX = point.x
            }
            this.length = Math.max(this.length, s.data.length)
        })

        this.rangeX = this.maxX - this.minX
        this.rangeY = this.maxY - this.minY
        this.series = series
    }

    generateTicksY(count: number)
    {
        if (this.rangeY) {
            this.ticksY = generateTicks(this.minY, this.maxY, count)
            this.minY = Math.min(...this.ticksY)
            this.maxY = Math.max(...this.ticksY)
            this.rangeY = this.maxY - this.minY
        } else {
            this.ticksY = []
        }
        return this.ticksY
    }

    generateTicksX(count: number)
    {
        this.ticksX = []
        for (let i = 0; i <= count; i++) {
            this.ticksX.push(this.minX + this.rangeX / count * i)
        }
        return this.ticksX
    }

    plot(width: number, height: number, offsetX = 0, offsetY = 0)
    {
        return this.series.map(s => ({
            ...s,
            data: s.data.map(p => ({
                ...p,
                plotX: offsetX + width           * scale(p.x, this.minX, this.maxX),
                plotY: offsetY + height - height * scale(p.y, this.minY, this.maxY)
            }))
        }))
    }
}


export function Chart({
    width,
    height,
    grid  = false,
    xAxis,
    yAxis,
    series,
    crossHair,
    tooltip,
    id
}: {
    id: string
    width : number
    height: number
    grid ?: boolean
    crossHair?: "x" | "y" | "xy"
    xAxis?: {
        enabled?: boolean
        labelFormat?: (x:number|string) => string
    }
    yAxis?: {
        enabled?: boolean
        lineColor?: string
        lineWidth?: number
        width?: number
        labelFormat?: (x:number) => string
    },
    tooltip?: {
        render: (points: any) => any
    }
    series: Series[]
})
{
    const [mousePos, setMousePos] = useState<Point>({ x: 0, y: 0 })

    const dataset = new ChartData()

    dataset.setData(series)

    function getContents({ width, height }: { width: number, height: number }) {

        // compute plot coordinated
        const plot = new Rectangle(0, 0, width, height)

        // X Axis
        if (xAxis?.enabled) {
            plot.y2 -= FONT_SIZE * 2.6
            plot.x2 -= FONT_SIZE * 3
        }

        // Y Axis
        if (yAxis?.enabled) {
            const { width = 38 } = yAxis
            plot.x1 += width
            plot.y1  = FONT_SIZE / 2 // half line for the topmost label
        }

        const numTicksY = Math.max(Math.floor(plot.height / (FONT_SIZE * 2.5)), 2)
        const numTicksX = Math.max(Math.floor(plot.width  / (FONT_SIZE * 8)), 2)
        const ticksY = dataset.generateTicksY(numTicksY)
        const ticksX = dataset.generateTicksX(numTicksX)
        const columnWidth = Math.min((plot.width / dataset.length) * 0.7, MAX_COLUMN_WIDTH)
        const borderWidth = Math.min(Math.max(columnWidth / 10, 0.1), 1)
        const paddingX = series.find(s => s.type === "column") ? columnWidth / 2 + borderWidth * 4 : 0
        
        let { minY, maxY, minX, maxX, rangeX, rangeY } = dataset

        const _series = dataset.plot(plot.width - paddingX * 2, plot.height, paddingX)
        
        function scaleX(n: number): number {
            return paddingX + plot.x1 + (plot.width - paddingX * 2)  * scale(n, minX, maxX)
        }
        
        function scaleY(n: number): number {
            return plot.y1 + plot.height * scale(n, minY, maxY)
        }
        
        const nodes: any[] = []
        const defs : any[] = []
        const activePoints: any[] = []

        // Y Axis --------------------------------------------------------------
        if (yAxis?.enabled) {
            const format = yAxis?.labelFormat || ((n: number) => roundToPrecision(n, 2))
            nodes.push(
                <path
                    key="y-axis-line"
                    stroke={ yAxis?.lineColor ?? COLOR_AXIS }
                    strokeWidth={ yAxis?.lineWidth ?? 1 }
                    fill="none" d={`M ${plot.x1} ${plot.y1} v ${plot.height}`}
                />,
                <g fill={COLOR_AXIS_LABEL} fontSize={FONT_SIZE} textAnchor="end" key="y-axis-labels">
                    { rangeY > 0 && ticksY.map((n, i) => {
                        const y = (plot.height + plot.y1 + plot.y1) - scaleY(n)
                        return <Fragment key={i}>
                            <text alignmentBaseline="central" x={plot.x1 - 15} y={y}>{format(n)}</text>
                            <line x1={plot.x1 - 10} x2={plot.x1} y1={y} y2={y}
                                stroke={COLOR_AXIS} strokeWidth={1}
                                style={{ shapeRendering: "crispEdges" }} />
                        </Fragment>
                    })}
                </g>
            )
        }

        // X Axis --------------------------------------------------------------
        if (xAxis?.enabled) {

            const format = xAxis?.labelFormat || ((n: number) => n)

            nodes.push(
                <path
                    key="x-axis-line"
                    stroke={COLOR_AXIS}
                    strokeWidth={1}
                    fill="none" d={`M ${plot.x1} ${plot.y2} h ${plot.width}`}
                />
            )
            
            nodes.push(
                <g fill={COLOR_AXIS_LABEL} fontSize={FONT_SIZE} key="x-axis-labels">
                    { rangeX > 0 && ticksX.map((n, i) => {
                        const x = scaleX(n)
                        return <Fragment key={i}>
                            <text textAnchor="middle" x={x} y={plot.y1 + plot.height + 15} alignmentBaseline="hanging">{format(n)}</text>
                            <line x1={x} x2={x} y1={plot.y1 + plot.height} y2={plot.y1 + plot.height + 10} stroke={COLOR_AXIS} strokeWidth={1} style={{ shapeRendering: "crispEdges" }} />
                        </Fragment>
                    })}
                </g>
            )
        }
    
        // Grid ----------------------------------------------------------------
        if (grid) {
            ticksY.forEach((n, i) => {
                if (n || !xAxis?.enabled) {
                    const y = (plot.height + plot.y1 + plot.y1) - scaleY(n)                
                    nodes.push(
                        <path
                            stroke={COLOR_GRID}
                            strokeWidth="0.5"
                            fill="none"
                            strokeDasharray="4 2"
                            d={`M ${plot.x1} ${y} h ${plot.width}`}
                            style={{ shapeRendering: "crispEdges" }}
                            key={`grid-line-x-${i}`}
                        />
                    )
                }
            })

            ticksX.forEach((n, i) => {
                if (rangeX > 0) {
                    const x = scaleX(n)
                    nodes.push(
                        <path
                            stroke={COLOR_GRID}
                            strokeWidth="0.5"
                            fill="none"
                            strokeDasharray="4 2"
                            d={`M ${x} ${plot.y1} v ${plot.height}`}
                            style={{ shapeRendering: "crispEdges" }}
                            key={`grid-line-y-${i}`}
                        />
                    )
                }
            })
        }

        // Series --------------------------------------------------------------
        _series.forEach((s, i) => {
            let {
                data,
                type,
                color = DEFAULT_COLORS[i % DEFAULT_COLORS.length],
                fill,
                step,
                lineProps = {}
            } = s

            if (data.length === 0) {
                return
            }

            // console.log(data)

            const points = data.map(p => ({
                x: plot.x1 + p.plotX,
                y: plot.y1 + p.plotY,
                dataY: p.y,
                dataX: p.x,
                color
            }))

            if (fill && typeof fill === "object") {
                const fillId = id + "-fill-" + i
                defs.push(
                    <linearGradient key={fillId} id={fillId} x1={fill.x1} x2={fill.x2} y1={fill.y1} y2={fill.y2}>
                        { fill.stops.map((s, y) => (
                            <stop key={y} offset={s.offset} stopColor={s.stopColor} stopOpacity={s.stopOpacity ?? 1} />
                        ))}
                    </linearGradient>
                )
                fill = "url(#" + fillId + ")"
            }

            if (type === "column") {
                nodes.push(
                    <g key={`series-${i}-columns`}
                    clipPath="url(#plotClipRect)"
                    >
                        { points.map((p, y) => {
                            return <rect
                                key={y}
                                x={p.x - columnWidth/2}
                                y={plot.y2 + borderWidth/2 - (plot.y2 - p.y)}
                                height={plot.y2 - p.y - borderWidth}
                                width={columnWidth}
                                fill={fill || color}
                                stroke={color}
                                strokeWidth={borderWidth}
                                rx={2}
                            />
                        })}
                    </g>
                )
            }
            
            if (type === "line" || type === "area") {
                if (data.length > 1) {
                    const path1 = svgPath(
                        points, step === "start" ?
                            stepStart :
                            step === "end" ?
                                stepEnd :
                                step === "middle" ?
                                    stepMiddle :
                                    lineCommand
                    )
                    nodes.push(<path strokeWidth="2" {...lineProps} key={`series-line-${i}`} stroke={color} fill="none" d={"M" + path1} clipPath="url(#plotClipRect)" />)
                    if (type === "area") {
                        const path2 = `M ${plot.x1} ${plot.y2} L ${path1} L ${plot.x2} ${plot.y2} Z`
                        nodes.push(<path key={`series-fill-${i}`} strokeWidth="0" fill={ fill || color} opacity={fill ? 1 : 0.1 } d={path2} />)
                    }
                }
            }
    
            if (type === "spline" || type === "areaspline") {
                if (data.length > 1) {
                    const path1 = svgPath(points, bezierCommand)
                    nodes.push(<path key={`series-line-${i}`} stroke={color} strokeWidth="2" fill="none" d={"M" + path1} clipPath="url(#plotClipRect)" />)
                    if (type === "areaspline") {
                        const path2 = `M ${plot.x1} ${plot.y1 + plot.height} L ${path1} L ${plot.width + plot.x1} ${plot.y1 + plot.height} Z`
                        nodes.push(
                            <path
                                key={`series-fill-${i}`}
                                strokeWidth="0"
                                fill={ fill || color}
                                opacity={fill ? 1 : 0.1 } d={path2}
                            />
                        )
                    }
                }
            }

            if (plot.containsPoint(mousePos)) {
                const pt = points.reduce((prev, cur) => {
                    if (Math.abs(cur.x - mousePos.x) < Math.abs(prev.x - mousePos.x)) {
                        return cur
                    }
                    return prev
                }, { x: Number.MAX_VALUE, y: Number.MAX_VALUE })

                activePoints.push({ ...pt, series: s })
            }
        })

        if (activePoints.length > 0) {
            if (crossHair?.includes("x")) {
                nodes.push(<line key={`crosshair-x`} x1={plot.x1} x2={plot.x2} y1={activePoints[0].y} y2={activePoints[0].y} stroke="#0006" />)
            }
            if (crossHair?.includes("y")) {
                nodes.push(<line key={`crosshair-y`} x1={activePoints[0].x} x2={activePoints[0].x} y1={plot.y1} y2={plot.y2} stroke="#0006" />)
            }
            activePoints.forEach((p, i) => {
                nodes.push(<circle key={`series-${i}-active-marker`} cx={p.x} cy={p.y} r={4} stroke="#000C" fill="#FFF" strokeWidth={2} />)
            })
        }

        return {
            svg: <g>
                <defs>
                    <clipPath id="plotClipRect">
                        <rect x={plot.x1} y={0} width={plot.width} height={height} />
                    </clipPath>
                    <>{ defs }</>
                </defs>
                { nodes }
            </g>,
            html: activePoints.length ?
                <Tooltip points={activePoints} width={width} height={height}>
                    {tooltip?.render?.(activePoints) ?? <>
                        <b>{new Date(activePoints[0].dataX).toLocaleString()}</b>
                        <hr className="my-1" />
                        { activePoints.sort((a, b) => a.y - b.y).map((p, i) => (
                            <div key={i}>
                                <b style={{ color: p.color }}>●</b> { p.series.label }: {
                                   yAxis?.labelFormat ? yAxis.labelFormat(p.dataY) : p.dataY
                                }
                            </div>
                        )) }
                    </>}
                </Tooltip> :
                <></>
        }
    }

    return (
        <ScalableSVG attributes={{ height, width }} onMouseMove={(x, y) => setMousePos({ x, y })}>
            { getContents }
        </ScalableSVG>
    )
}
