import { Link }   from "react-router-dom"
import Page       from "../Page"
import Hr         from "../../Components/generic/Hr"
import PageHeader from "../../Components/PageHeader"


export function DomainMonitoring() {
    return (
        <Page header={
            <PageHeader
                style={{ padding: "1rem 0" }}
                title="Your Ultimate Domain and SSL Monitoring Solution"
                description="Ensure your online presence remains uninterrupted
                with Vigilant, a comprehensive monitoring application designed
                to keep track of your DNS records and SSL certificates. We offer
                a robust set of features to alert you before critical components
                expire, maintaining the security and availability of your online
                services."
            />
        }>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className=" text-brand-3">Monitor DNS Record Expiration</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Easily keep track of the expiration dates of your DNS
                        records. DomainGuard alerts you well in advance, so you
                        can renew them promptly and avoid potential downtime.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className=" text-brand-3">Track SSL Certificate Validity</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Never let an SSL certificate expire unnoticed. Vigilant
                        continuously checks the status of your SSL certificates,
                        sending notifications before they expire to maintain your
                        website's security and trustworthiness.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Configurable Frequency</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Customize how often the monitor runs according to your needs.
                        Whether you need frequent checks every minute or less frequent
                        ones, the choice is yours.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Enable/Disable Functionality</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Easily manage your monitoring activities with a simple toggle.
                        Whether you need to pause checks during maintenance or
                        re-enable them afterward, this feature ensures you stay in control.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Customizable Notification Settings</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Set the frequency and timing of alerts to suit your
                        requirements. DomainGuard gives you full control over
                        how and when you receive notifications, ensuring you
                        stay informed without being overwhelmed.
                    </p>
                </div>
            </div>
            <Hr className="my-4 text-secondary opacity-25 fade-out"/>
            <div className="text-center">
                <Link className="btn btn-brand-2 bg-gradient px-4" to="/my/monitors/new?type=domain">Create Domain Monitor</Link>
            </div>
        </Page>
    )
}