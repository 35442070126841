import { Link }   from "react-router-dom"
import Page       from "../Page"
import Hr         from "../../Components/generic/Hr"
import PageHeader from "../../Components/PageHeader"


export function HttpMonitoring() {
    return (
        <Page header={
            <PageHeader
                style={{ padding: "1rem 0" }}
                title="Introducing Your Ultimate HTTP Monitor App"
                description="Meet your new go-to tool for comprehensive web
                monitoring. This powerful HTTP Monitor App is designed to
                provide you with unmatched flexibility and control over your
                website's performance and reliability. Whether you're a
                developer, system administrator, or IT manager, this app is
                tailored to meet your unique monitoring needs."
            />
        }>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className=" text-brand-3">Multiple HTTP Methods</h5>
                    <p className="text-primary-emphasis opacity-75">
                        We supports GET, POST, PUT, PATCH, HEAD, and OPTIONS methods,
                        giving you the flexibility to monitor a wide range of web
                        interactions.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Configurable Frequency</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Customize how often the monitor runs according to your needs.
                        Whether you need frequent checks every minute or less frequent
                        ones, the choice is yours.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Enable/Disable Functionality</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Easily manage your monitoring activities with a simple toggle.
                        Whether you need to pause checks during maintenance or
                        re-enable them afterward, this feature ensures you stay in control.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Handle Redirects</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Configure the number of redirects the monitor should follow.
                        From 0 to multiple redirects, ensure your checks are precise
                        and thorough.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Customizable Timeouts</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Define custom timeout settings to ensure your monitoring
                        adapts to the performance characteristics of your site
                        or API.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Response Text/RegExp Search</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Search the response for specific text or regular expression
                        matches. Ensure that the responses contain exactly what you
                        expect.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Custom Request Headers</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Add and manage request headers to tailor your HTTP requests.
                        This feature is perfect for simulating various client types
                        and ensuring proper server responses.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Build and Send Request Body</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Construct and send your own request bodies for POST, PUT,
                        and PATCH methods. Test and monitor endpoints that require
                        payloads.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Configurable Response Codes</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Define which HTTP response codes are acceptable and which are
                        not. This helps in identifying issues based on specific status
                        codes returned by your server.
                    </p>
                </div>
            </div>
            <Hr className="my-4 text-secondary opacity-25 fade-out"/>
            <div className="text-center">
                <Link className="btn btn-brand-2 bg-gradient px-4" to="/my/monitors/new?type=http">Create Http Monitor</Link>
            </div>
        </Page>
    )
}