import { useEffect, useState }      from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ErrorAlert                   from "../../Components/generic/ErrorAlert"
import Loader                       from "../../Components/generic/Loader"
import { request }                  from "../../lib"
import { useAuth }                  from "../../auth"


interface SlackState {
    authorized     : boolean
    workspace      : { id: string, name: string } | null
    channels       : { id: string, name: string }[]
    selectedChannel: string
    error          : string
}

export default function Slack()
{
    const navigate                    = useNavigate()
    const { user }                    = useAuth()
    const { pathname, search }        = useLocation()
    const [loading, setLoading]       = useState(true)
    const [error  , setError  ]       = useState<Error | string | null>(null)
    const isRedirect                  = pathname.endsWith("/slack/redirect")
    const params                      = new URLSearchParams(search)
    const code                        = params.get("code")
    const state                       = params.get("state")
    const [slackState, setSlackState] = useState<SlackState>({
        authorized     : false,
        workspace      : null,
        channels       : [],
        selectedChannel: "",
        error          : ""
    })

    const { authorized, channels, workspace } = slackState

    const sendCodeAndState = async (code: string, state: string) => {
        setLoading(true)
        setError(null)
        try {
            await request(`/slack/redirect?code=${code}&state=${state}`)
            navigate("/my/notifications")
        } catch(ex) {
            setError(ex)
        } finally {
            setLoading(false)
        }
    }

    const load = async () => {
        try {
            setLoading(true)
            const { body } = await request("/slack")
            // console.log("load ===>", body)
            setSlackState(body)
            setError(null)
        } catch(ex) {
            setError(ex)
        } finally {
            setLoading(false)
        }
    }

    const setChannel = async (channel: string) => {
        const old = slackState.selectedChannel
        try {
            setSlackState({ ...slackState, selectedChannel: channel })
            setError(null)
            await request(`/api/v1/users/${user.id}`, {
                method : "PATCH",
                headers: { "content-type": "application/json" },
                body   : JSON.stringify({ settings: { slack: { channel }}})
            })
        } catch (ex) {
            setSlackState({ ...slackState, selectedChannel: old })
            setError(ex)
        }
    }

    useEffect(() => {
        if (isRedirect) {
            sendCodeAndState(code, state)
        } else if (!authorized) {
            load()
        }
    }, [isRedirect, authorized])

    return (
        <div className="position-relative">
            { error && <ErrorAlert>{ error + "" }</ErrorAlert> }
            { slackState.error && <ErrorAlert>{ slackState.error + "" }</ErrorAlert> }
            { isRedirect && (!code || !state)  && <ErrorAlert>
                Unexpected error: Invalid url. The Slack authorization was
                unsuccessful. Please try again later.
            </ErrorAlert> }
            { authorized && <>
                <label htmlFor="name" className="form-label fw-bold">Channel</label>
                <div className="input-group">
                    <select
                        className="form-select"
                        name="slackChannel"
                        disabled={loading}
                        value={slackState.selectedChannel}
                        onChange={e => setChannel(e.target.value)}
                    >
                        <option value="">None (do not notify me)</option>
                        { channels.map((c, i) => <option key={i} value={c.id}>{c.name}</option>) }
                    </select>
                    <button className="btn bg-light border" type="button" data-tooltip="Reload list" onClick={() => load()}>
                        { loading ? <Loader small /> : <i className="bi bi-arrow-clockwise" /> }
                    </button>
                </div>
                
                <div className="form-text small">
                    Select a Slack channel to send notifications to
                </div>

            </> }
            { workspace ?
                <div className="my-4">
                    You have currently installed our app to <b>{workspace.name}</b>. <a href="/slack/authorize">Change Workspace</a>
                </div> :
                <>
                    { loading && <Loader className="loader-cover" /> }
                    <div className="my-4">
                        You have not currently installed our app to any Slack workspace.
                        To receive Slack notifications you must begin by doing so using
                        the button below:
                        <br/>
                        <br/>
                        <a className="btn btn-warning" href="/slack/authorize">
                            <i className="bi bi-slack me-2" />Add Vigilant Monitor App to my Slack
                        </a>
                    </div>
                </>
            }
        </div>
    )
}