import { useNavigate, useParams } from "react-router-dom"
import { useState }               from "react"
import { Form }                   from "./Form"
import { Form as PortForm }       from "./Form/PortForm"
import { Form as PingForm }       from "./Form/PingForm"
import { Form as DomainForm }     from "./Form/DomainForm"
import NavigationButtons          from "./NavigationButtons"
import { request }                from "../../lib"
import { useAuth }                from "../../auth"
import { useBackend }             from "../../hooks"
import Breadcrumbs                from "../../Components/generic/Breadcrumbs"


export default function Edit() {
    const navigate          = useNavigate()
    const params            = useParams()
    const { user }          = useAuth()
    const [saveError, setSaveError] = useState()

    const { error, loading, result } = useBackend(async () => {
         const { body } = await request(`/api/v1/checks/${params.id}`)
         return body
    }, true)

    if (loading) {
        return <b>Loading...</b>
    }

    if (error) {
        return <b>{ error + "" }</b>
    }

    const name = result.name || (result.requestOptions.method || "GET") + " " + result.requestOptions.url

    const update = async (data: any) => {
        try {
            const payload = { ...data, userId: user.id }
            // @ts-ignore
            delete payload.Incidents
            await request(`/api/v1/checks/${params.id}`, {
                method : "PUT",
                headers: { "content-type": "application/json" },
                body   : JSON.stringify(payload)
            })
            navigate("../")
        } catch (ex) {
            setSaveError(ex)
        }
    }

    function renderForm() {
        if (result.options.type === "browser") {
            return <div className="alert alert-danger">Form not implemented</div>
        }
        else if (result.options.type === "domain") {
            return <DomainForm data={ result } onSubmit={ update } />
        }
        else if (result.options.type === "ping") {
            return <PingForm data={ result } onSubmit={ update } />
        }
        else if (result.options.type === "port") {
            return <PortForm data={ result } onSubmit={ update } />
        }
        else if (result.options.type === "http") {
            return <Form data={ result } onSubmit={ update } />
        }
        else {
            return <div className="alert alert-danger">Unknown monitor type parameter</div>
        }
    }
    
    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "My Monitors",
                    href: "/my/monitors"
                },
                {
                    name,
                    href: `/my/monitors/${params.id}`
                },
                {
                    name: "Edit"
                }
            ]}/>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h2 className="pe-2 text-truncate my-0">Edit Monitor</h2>
                <NavigationButtons />
            </div>
            <hr className="my-1" />
            <br/>
            { saveError && <div className="alert alert-danger">{ saveError + "" }</div> }
            { renderForm() }
        </div>
    )
}