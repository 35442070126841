import { FormEvent, useState } from "react";
import { request } from "../../lib";

export function Register() {

    const [error  , setError  ] = useState(null)
    const [name   , setName   ] = useState("")
    const [email  , setEmail  ] = useState("")
    const [planId , setPlanId ] = useState(1)
    const [loading, setLoading] = useState(false)
    const [registeredEmail, setRegisteredEmail] = useState(false)
    // let navigate = useNavigate();
    // let location = useLocation();
    // let params   = new URLSearchParams(location.search);
    // let from     = location.state?.from?.pathname || params.get("from") || "/";

    // const { login, loading, error } = useAuth()

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()

        try {
            setLoading(true)
            const { body, response } = await request("/auth/register", {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ name, email, planId })
            })
            if (response.status === 201) {
                setRegisteredEmail(body.email)
                setError(null)
            } else {
                setError(body.message || body)
            }
        } catch (ex) {
            setError(ex)
        }
        setLoading(false)

        // const formData = new FormData(e.target as HTMLFormElement)    
        // await login(formData)
        
        // // Send them back to the page they tried to visit when they were
        // // redirected to the login page. Use { replace: true } so we don't create
        // // another entry in the history stack for the login page. This means that
        // // when they get to the protected page and click the back button, they
        // // won't end up back on the login page, which is also really nice for
        // // the user experience.
        // navigate(from, { replace: true });
    }

    if (registeredEmail) {
        return (
            <div className="d-flex flex-grow-1 align-items-center justify-content-center">
                <div className="flex-grow-1 text-center card shadow-sm" style={{ maxWidth: "30em" }}>
                    <div className="card-body p-5">
                        <h2>Thank you for registering!</h2>
                        <hr />
                        <h5 className="text-success mb-5">
                            <i className="bi bi-envelope me-2" />Please check your email
                        </h5>
                        <p className="text-muted small">
                            We sent a confirmation email to <b className="text-primary">{registeredEmail}</b>.
                            It contains a link that you need to follow to complete
                            your registration. Please also check your <b>spam</b> folders.
                            Be aware that this activation email will expire in <b>24
                            hours</b>.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <form
                // className="align-self-center align-items-center"
                className="flex-grow-1"
                method="post"
                encType="application/x-www-form-urlencoded"
                autoCapitalize="none"
                // autoComplete="off"
                onSubmit={handleSubmit}
                style={{ maxWidth: "20em" }}
            >
                <div className="mt-4 mb-4 text-center">
                    <h2>Create Account</h2>
                    <hr />
                    <p className="text-muted">
                        It only takes a few minutes to register and start monitoring!
                    </p>
                </div>

                { error && <div className="alert alert-danger">{ error + "" }</div> }

                <div>
                    <label className="form-label text-primary-emphasis fw-bold" htmlFor="name">Your Name</label>
                    <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Full name or an alias"
                        autoComplete="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        disabled={loading}
                    />
                </div>
                <div className="mt-4">
                    <label className="form-label text-primary-emphasis fw-bold" htmlFor="email">Email address</label>
                    <input
                        className="form-control"
                        id="email"
                        type="email"
                        placeholder="Valid email is required"
                        autoComplete="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        disabled={loading}
                    />
                </div>
                <div className="my-4">
                    <label className="form-label text-primary-emphasis fw-bold" htmlFor="plan">Your Plan</label>
                    <select className="form-control" id="plan" value={planId} onChange={e => setPlanId(+e.target.value)}>
                        <option value={1}>Basic - free</option>
                        <option value={2} disabled>Advanced (coming soon)</option>
                        <option value={3} disabled>Pro (coming soon)</option>
                    </select>
                    <div className="form-text small">You can change your plan any time</div>
                </div>
                <hr />
                <div className="my-4 text-center">
                    <button className="btn btn-primary px-5" type="submit" disabled={loading}>Register</button>
                </div>
            </form>
        </div>
    );
}