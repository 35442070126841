import { useAuth } from "../../auth"


export default function EmailForm()
{
    const { user } = useAuth()

    return (
        <>
            <label htmlFor="name" className="form-label fw-bold">Primary Email</label>
            <input
                className="form-control"
                id="name"
                type="text"
                name="name"
                maxLength={100}
                readOnly
                disabled
                defaultValue={user.email}
                // value={rec.name || ""}
                // onChange={e => setRec({ ...rec, name: e.target.value })}
            />
            <div className="form-text small">
                Email is the default notification method. You can
                disable it as long as there is at least one other
                notification method enabled.
            </div>

            <div className="form-check mt-4">
                <input className="form-check-input" type="checkbox" />
                <label className="form-check-label">Allow notifications to this email</label>
            </div>
        </>
    )
}