import { FormEvent, useState } from "react";
import { useLocation }         from "react-router";
import CreatePassword          from "./CreatePassword";
import ReadyToLogIn            from "./ReadyToLogIn";
import { request }             from "../../lib";
import ErrorAlert              from "../../Components/generic/ErrorAlert";
import Loader                  from "../../Components/generic/Loader";
import { validatePasswordSet } from "../../../../isomorphic/validation/password-validation";


export default function Activate()
{
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const activationCode = query.get("activationCode")

    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [error    , setError    ] = useState<Error | string | null>(null)
    const [loading  , setLoading  ] = useState<boolean>(false)
    const [success  , setSuccess  ] = useState<boolean>(false)

    const validationError = validatePasswordSet(password1, password2);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        setError(null)

        try {
            const { response, body } = await request("/auth/activate", {
                method : "POST",
                headers: { "content-type": "application/json" },
                body   : JSON.stringify({
                    password1,
                    password2,
                    activationCode
                })
            })

            if (response.status !== 200) {
                setError(body.message || body)
            } else {
                setSuccess(true)
            }

        } catch (ex) {
            setError(ex)
        }

        setLoading(false)
    }

    if (!activationCode) {
        return <ErrorAlert className="my-5">Invalid URL - missing activation code parameter!</ErrorAlert>
    }

    if (success) {
        return <ReadyToLogIn message="Thank you for registering!" />
    }

    return (
        <form onSubmit={onSubmit} style={{ maxWidth: "30em", margin: "auto" }}>
            <div className="text-center mt-5 mb-4">
                <h2>Welcome to Vigilant Monitor!</h2>
                <hr />
                <p className="text-muted">Please create a password to activate your account</p>
            </div>
            { error && <ErrorAlert>{ error }</ErrorAlert> }
            <CreatePassword password1={password1} password2={password2} onPassword1Change={setPassword1} onPassword2Change={setPassword2} disabled={loading} />
            <hr />
            <div className="row mb-4">
                <div className="col col-12 text-center">
                    <button type="submit" className="btn px-4 btn-primary m-auto" disabled={loading || !!validationError}>
                        { loading ? <Loader small color="white" className="me-2" /> : <i className="bi bi-floppy2-fill me-2" /> }Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
