import moment                        from "moment"
import { useEffect }                 from "react"
import { useParams }                 from "react-router-dom"
import { useBackend }                from "../../hooks"
import { humanizeDuration, request } from "../../lib"
import Alert                         from "../../Components/generic/Alert"
import Loader                        from "../../Components/generic/Loader"
import ErrorAlert                    from "../../Components/generic/ErrorAlert"
import Breadcrumbs                   from "../../Components/generic/Breadcrumbs"
import { Incident }                  from "../../types"
import CountDown                     from "../../Components/generic/CountDown"
import NotifiedUsersList             from "./NotifiedUsersList"
import NavigationButtons             from "./NavigationButtons"


function dateFormat(x: any) {
    if (!x) {
        return "--"
    }
    const d = new Date(x)
    const now = new Date()
    if (d.getFullYear() === now.getFullYear() &&
        d.getMonth() === now.getMonth() &&
        d.getDate() === now.getDate()) {
        return d.toLocaleTimeString()
    }
    return d.toLocaleString()
}


export default function MonitorView() {

    const { id } = useParams()

    let fetchTimeout: any

    const { error, loading, result, execute } = useBackend(async () => {
        const { body } = await request(`/api/v1/checks/${id}?history=1`)
        const { frequency } = body || {}
        const nextRunRemaining = frequency * 1000
        if (nextRunRemaining > 0) {
            fetchTimeout = setTimeout(execute, nextRunRemaining)
        }

        return body
    }, true)

    useEffect(() => {
        // execute()
        return () => {
            if (fetchTimeout) {
                clearTimeout(fetchTimeout);
            }
        }
    }, [])

    if (error) {
        return <ErrorAlert>{ error }</ErrorAlert>
    }

    if (loading && !result) {
        return <Loader className="text-center my-5" />
    }

    if (!result) {
        return null
    }
    
    const {
        name,
        description,
        request: requestOptions,
        frequency,
        enabled,
        owner,
        // createdAt,
        // updatedAt,
        lastRun,
        nextRun,
        history,
        Incidents,
        incidentId,
        // options: {
        //     // frequency
        // }
    } = result

    

    let min = Infinity;
    let max = -Infinity;
    let sum = 0;

    history.forEach(cur => {
        const duration = +new Date(cur.endedAt) - +new Date(cur.startedAt)
        max  = Math.max(max, duration)
        min  = Math.min(min, duration)
        sum += duration
    })

    const avg = Math.round(sum/history.length)

    const axisMax = Math.ceil(max/100) * 100

    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "My Monitors",
                    href: "/my/monitors"
                },
                {
                    name: name || (requestOptions.method || "GET") + " " + requestOptions.url
                }
            ]}/>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h2 className="pe-2 my-0" style={{ maxWidth: "75vw"}}>{ name }</h2>
                <NavigationButtons />
            </div>
            { description && <>
                {/* <hr className="my-2" /> */}
                <p className="text-secondary mb-2">{ description || "" }</p>
            </> }
            <hr className="mt-1" style={{ marginBottom: 1 }} />

            <div className="bg-light bg-gradient py-3 rounded-bottom mb-3 border-bottom">
                <div className="row">
                    <div className="col">
                        <div className="text-center px-2">
                            <b className="text-secondary">Last Run</b>
                            <br />{ dateFormat(lastRun) }
                        </div>
                    </div>
                    <div className="col border border-secondary-subtle border-bottom-0 border-top-0">
                        <div className="text-center px-2">
                            <b className="text-secondary">Status</b><br />
                            { incidentId ?
                                <b className="text-danger">
                                    <i className="bi bi-x-circle-fill me-1" />FAILING
                                </b> :
                                <b className="text-success">
                                    <i className="bi bi-check-circle-fill me-1" />OK
                                </b>
                            }
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-center px-2">
                            <b className="text-secondary">Next Run</b><br />
                            { dateFormat(nextRun) }
                        </div>
                    </div>
                </div>
            </div>

            
            { history.length > 0 ? <>
                <div className="row mt-4 mb-4">
                    <div className="position-relative text-secondary">
                        <div className="text-center pb-1 d-flex justify-content-around">
                            <span>Last 100 Checks</span>
                            <small>Next refresh in <CountDown initialCount={frequency} key={history.length} /></small>
                        </div>
                        <div className="col">
                            <div className="d-flex">
                                <div className="
                                    d-flex
                                    flex-row-reverse
                                    flex-grow-1
                                    align-items-end
                                "
                                style={{ height: "10em", borderTop: "1px dashed #0003" }}>
                                { history.map((h, i) => {
                                    const endedAt = new Date(h.endedAt)
                                    const duration = +endedAt - +new Date(h.startedAt)
                                    const pct = duration / axisMax * 100
                                    return <div
                                        key={i}
                                        className="bg-gradient"
                                        style={{
                                            height: `${pct}%`,
                                            flex: "0 1 1%",
                                            margin: "1px",
                                            boxSizing: "border-box",
                                            minHeight: 5,
                                            background: h.failure ? "#E40" : "#3B0",
                                            borderRadius: "3px"
                                        }}
                                        data-bs-toggle="tooltip"
                                        data-bs-html="true"
                                        data-tooltip={'<div class="text-center">' + [
                                            endedAt.toLocaleString(),
                                            '<b>' + duration.toLocaleString() + " ms</b>",
                                            h?.data?.responseCode ? "Response Code: " + h.data.responseCode : "",
                                            h.failure ? "\n <b>Error</b>: " + h.failure : "",
                                            h.data?.error ? "\n <b>Error</b>: " + h.data.error : ""
                                        ].filter(Boolean).join("<br/>") + "</div>" }
                                    />
                                })}
                                </div>
                                <div className="d-flex flex-column justify-content-between ps-1 small" style={{ lineHeight: 1 }}>
                                    <small style={{ lineHeight: 0 }}>{axisMax.toLocaleString()} ms</small>
                                    <small>0 ms</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="row mb-4 text-center">
                    <div className="col">
                        <div className="bg-success bg-opacity-10 p-4 rounded">
                            <div className="text-success">Fastest Response</div>
                            <h3 className="m-0">{min} {humanizeDuration(min, { short: true })}</h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="bg-primary bg-opacity-10 p-4 rounded">
                            <div className="text-primary">Average Response</div>
                            <h3 className="m-0">{avg} {humanizeDuration(avg, { short: true })}</h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="bg-danger bg-opacity-10 p-4 rounded">
                            <div className="text-danger">Slowest Response</div>
                            <h3 className="m-0">{max} {humanizeDuration(max, { short: true })}</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-4 mt-4">
                    <div className="col">
                        <IncidentsView records={Incidents} />
                    </div>
                </div></> : 

                <div className="row my-4">
                    <div className="col text-center">
                        <h4>No runs yet</h4>
                        <p>
                            This monitor has not been executed yet. You can {
                                enabled ? "increase it's frequency" : "enable it in the settings"
                            } to see execution statistics. You can also run it
                            manually using the button below.
                        </p>
                        <button className="btn btn-primary px-4">Run Monitor</button>
                        <br/>
                        <br/>
                        <hr />
                    </div>
                </div>
            }

            <div className="row mb-4 mt-4">
                <div className="col text-center">
                    <b className="text-muted">Who will be notified: </b>
                    <NotifiedUsersList owner={owner} />
                </div>
            </div>
        </div>
    )
}

function IncidentsView({ records }: { records: Incident[] }) {
    if (!records.length) {
        return <Alert
            heading="Great Job!"
            icon={<i className="bi bi-hand-thumbs-up me-2" />}
            type="success"
            className="bg-success bg-opacity-10 border-0"
            // className="text-success-emphasis border-success-subtle bg-success bg-opacity-10"
            // dismissible
        >
            <p><b>No incidents have been detected so far.</b></p>
            <div>
                If and when the monitor checks fail, an incident will appear here.
                You can then review and annotate it to control how people will see
                it on your status page.
            </div>
        </Alert>
    }
    return (
        <>
            <h5 className="text-danger">Incidents</h5>
            <hr/>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th>Cause</th>
                        <th>Duration</th>
                        <th>Started At</th>
                        <th>Ended At</th>
                    </tr>
                </thead>
                <tbody>
                    { records.map((rec, i) => {
                        const duration = rec.endedAt ? new Date(rec.endedAt).valueOf() - new Date(rec.startedAt).valueOf() : new Date().valueOf() - new Date(rec.startedAt).valueOf()
                        const durationStr = duration < 60_000 ?
                            Math.ceil(duration / 1000) + " seconds" :
                            moment.duration(duration, "milliseconds").humanize()
                        return (
                            <tr key={i}>
                                <td className="text-danger"><i className="bi bi-exclamation-square-fill me-2" />{ rec.cause }</td>
                                <td>{ durationStr }</td>
                                <td><small>{ new Date(rec.startedAt).toLocaleString() }</small></td>
                                <td><small>{ rec.endedAt ? new Date(rec.endedAt).toLocaleString() : "ongoing" }</small></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}