import { Point } from "./types"

// see https://francoisromain.medium.com/smooth-a-svg-path-with-cubic-bezier-curves-e37b49d46c74


export function line(a: Point, b: Point) {
    const lengthX = b.x - a.x
    const lengthY = b.y - a.y
    return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
    }
}

export function controlPoint(current: Point, previous: Point, next: Point, reverse?: boolean) {
    // When 'current' is the first or last point of the array
    // 'previous' or 'next' don't exist replace with 'current'
    const p = previous || current
    const n = next || current
    // Properties of the opposed-line
    const o = line(p, n)
    const smoothing = Math.min(5/o.length, 0.2)
    // If is end-control-point, add PI to the angle to go backward
    const angle = o.angle + (reverse ? Math.PI : 0)
    const length = o.length * smoothing
    // The control point position is relative to the current point
    const x = current.x + Math.cos(angle) * length
    const y = current.y + Math.sin(angle) * length
    return [x, y]
}

export function lineCommand(point: Point) {
    return `L ${point.x} ${point.y}`
}

export function stepStart(point: Point) {
    return `V ${point.y} H ${point.x}`
}

export function stepEnd(point: Point) {
    return `H ${point.x} V ${point.y}`
}

export function stepMiddle(point: Point, i: number, arr: Point[]) {
    if (i === 0) {
        return `L ${point.x} ${point.y}`
    }
    const px = arr[i - 1].x
    const cx = px + (point.x - px) / 2
    return `H ${cx} V ${point.y} H ${point.x}`
}

export function bezierCommand(point: Point, i: number, arr: Point[]) {
    const [cpsX, cpsY] = controlPoint(arr[i - 1], arr[i - 2], point)
    const [cpeX, cpeY] = controlPoint(point, arr[i - 1], arr[i + 1], true)
    return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point.x},${point.y}`
}

export function svgPath(points: Point[], command: typeof bezierCommand) {
    return points.reduce((acc, point, i, a) => i === 0 ? `${point.x},${point.y}` : `${acc} ${command(point, i, a)}`, '')
}

export function generateTicks(min: number, max: number, numTicks: number) {
    
    function niceNumber(range: number, round: boolean) {
        const exponent = Math.floor(Math.log10(range));
        const fraction = range / Math.pow(10, exponent);
        let niceFraction: number;
        
        if (round) {
            if (fraction < 1.5) {
                niceFraction = 1;
            } else if (fraction < 3) {
                niceFraction = 2;
            } else if (fraction < 7) {
                niceFraction = 5;
            } else {
                niceFraction = 10;
            }
        } else {
            if (fraction <= 1) {
                niceFraction = 1;
            } else if (fraction <= 2) {
                niceFraction = 2;
            } else if (fraction <= 5) {
                niceFraction = 5;
            } else {
                niceFraction = 10;
            }
        }
    
        return niceFraction * Math.pow(10, exponent);
    }

    // Calculate the range and nice range
    const range = niceNumber(max - min, false);
    const tickSpacing = niceNumber(range / (numTicks - 1), true);
    const niceMin = Math.floor(min / tickSpacing) * tickSpacing;
    const niceMax = Math.ceil(max / tickSpacing) * tickSpacing;
  
    // Generate ticks
    const ticks = [];
    for (let tick = niceMin; tick <= niceMax; tick += tickSpacing) {
        ticks.push(tick);
    }
    
    return ticks;
}

export function scale(n: number, min: number, max: number): number {
    const range = Math.max(max - min, 1)
    return (n - min) / range
}
