import { useState }       from "react"
import { Team }           from "../../types"
import InviteMemberDialog from "./InviteMemberDialog"
import LocalDataGrid      from "../../Components/generic/DataGrid/LocalDataGrid"


export default function Form({
    data = {},
    onSubmit,
    loading = false
}: {
    data   ?: Partial<Team>
    loading?: boolean
    onSubmit: (props: typeof data) => void
}) {
    const [rec, setRec] = useState(data)

    const [inviteDialogOpen, setInviteDialogOpen] = useState(false)

    // console.log(JSON.stringify(rec, null, 4))

    function handleSubmit(e) {
        e.preventDefault()
        onSubmit(rec)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="mb-4 col-md">
                    <fieldset disabled={ loading }>
                        <label htmlFor="name" className="form-label mt-4">
                            <b className="text-primary-emphasis">Team Name</b>
                        </label>
                        <input
                            className="form-control"
                            id="name"
                            type="text"
                            name="name"
                            maxLength={100}
                            value={rec.name || ""}
                            onChange={e => setRec({ ...rec, name: e.target.value })}
                        />
                        <div className="form-text small">Give your team a descriptive name</div>

                        <label htmlFor="description" className="form-label mt-4">
                            <b className="text-primary-emphasis">Team Description</b>
                        </label> 
                        <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            rows={5}
                            value={rec.description || ""}
                            onChange={e => setRec({ ...rec, description: e.target.value })}
                        />
                        <div className="form-text small">Give your team a short description</div>
                    </fieldset>
                </div>
                <div className="mb-4 col-md-7">
                    <fieldset disabled={ loading }>
                        <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
                            <label className="">
                                <b className="text-primary-emphasis">Team Members</b>
                            </label>
                            <button
                                className="btn btn-sm bg-success bg-gradient bg-opacity-10 border-success border-opacity-10 text-success px-3"
                                type="button"
                                onClick={ () => setInviteDialogOpen(true) }
                            >
                                Invite Member
                            </button>
                        </div>
                        <hr className="mt-1 mb-0" />
                        <LocalDataGrid
                            className="table-sm table-hover align-middle"                            
                            columns={[
                                {
                                    propName: "name",
                                    sortProp: "name",
                                    label   : "Name",
                                    dataType: "string"
                                },
                                {
                                    propName: "email",
                                    sortProp: "email",
                                    label   : "Email",
                                    dataType: "string",
                                    renderCell: row => <span className="text-primary">{ row.email }</span>
                                },
                                {
                                    propName: "TeamMember.status",
                                    sortProp: "TeamMember.status",
                                    label   : "Status",
                                    dataType: "string",
                                    renderCell: row => row.TeamMember.status === "pending" ?
                                        <span className="badge text-bg-warning bg-opacity-50"
                                            data-tooltip="The team member was invited but has not accepted the invitation yet. Notification cannot be sent to this member before they accept the invitation.">pending</span> :
                                        row.TeamMember.status === "rejected" ?
                                            <span className="badge text-bg-danger bg-opacity-50">rejected</span> :
                                            <span className="badge text-bg-success bg-opacity-50">accepted</span>
                                },
                                {
                                    renderCell: row => <button className="btn btn-sm btn-virtual" data-tooltip="Remove member" type="button">
                                        <i className="bi bi-trash text-danger" />
                                    </button>
                                }
                            ]}
                            rows={ data.Members }
                        />
                        { inviteDialogOpen && <InviteMemberDialog onClose={() => setInviteDialogOpen(false) } /> }
                    </fieldset>
                </div>
            </div>
            <div className="my-4 text-center bg-gradient border-top rounded-bottom rounded-bottom-3 p-3" style={{ backgroundColor: "#EEE9" }}>
                <button className="btn btn-primary bg-gradient px-5" type="submit" disabled={ loading }>Save</button>
            </div>
        </form>
    )
}

