import { useState }                           from "react"
import { useNavigate, useSearchParams }       from "react-router-dom"
import { Form }                               from "./Form"
import { Form as PingForm   }                 from "./Form/PingForm"
import { Form as PortForm   }                 from "./Form/PortForm"
import { Form as DomainForm }                 from "./Form/DomainForm"
import NavigationButtons                      from "./NavigationButtons"
import { request }                            from "../../lib"
import { useAuth }                            from "../../auth"
import Breadcrumbs                            from "../../Components/generic/Breadcrumbs"
import { MonitorModel, MonitorOptions }       from "../../types"


export default function Create() {
    const navigate          = useNavigate()
    const { user }          = useAuth()
    const [error, setError] = useState()
    const [params]          = useSearchParams()

    const type = params.get("type") || "http"
    // console.log(type)

    async function create(data: Partial<MonitorModel<MonitorOptions>>) {
        try {
            const { body } = await request("/api/v1/checks", {
                method : "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    ...data,
                    userId: user.id
                })
            })
            console.log(body)
            navigate("../")
        } catch (ex) {
            setError(ex)
        }
    }

    function renderForm() {
        if (type === "browser") {
            return <div className="alert alert-danger">Form not implemented</div>
        }
        else if (type === "domain") {
            return <DomainForm onSubmit={create} />
        }
        else if (type === "ping") {
            return <PingForm onSubmit={create} />
        }
        else if (type === "port") {
            return <PortForm onSubmit={create} />
        }
        else if (type === "http") {
            return <Form onSubmit={create} />
        }
        else {
            return <div className="alert alert-danger">Unknown monitor type parameter</div>
        }
    }
    
    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "My Monitors",
                    href: "/my/monitors"
                },
                {
                    name: "Create New Monitor"
                }
            ]}/>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="pe-2 text-truncate my-0">Create Monitor</h2>
                <NavigationButtons />
            </div>
            <hr className="my-2" />
            { error && <div className="alert alert-danger">{ error + "" }</div> }
            <br />
            { renderForm() }
        </div>
    )
}