import { FormEvent, useState }              from "react"
import validator                            from "validator"
import FrequencySelector                    from "./FrequencySelector"
import { MonitorModel, PortMonitorOptions } from "../../../types"
import Toggle                               from "../../../Components/generic/Toggle"
import ValidatedInput                       from "../../../Components/generic/ValidatedInput"
import { classList }                        from "../../../lib"


export function Form({
    data = { frequency: 60 * 60 },
    onSubmit
}: {
    data?: Partial<MonitorModel<PortMonitorOptions>>
    onSubmit: (props: typeof data) => void
}) {
    const [rec, setRec] = useState(data)

    function handleSubmit(e: FormEvent) {
        e.preventDefault()
        onSubmit({ ...rec, options: { ...rec.options, type: "port" }})
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-secondary mb-5">
                A port monitor is a network monitoring tool used to check the
                availability and status of network ports on devices within a
                network. Its primary function is to verify whether specific
                ports on target devices are open or closed, allowing network
                administrators to detect connectivity issues, service
                availability, and potential security vulnerabilities.
            </div>
            <div className="row">
                <div className="mb-4 col-md">
                    <label htmlFor="name" className="form-label">
                        <b className="text-primary-emphasis">Name</b>
                    </label> 
                    <input
                        className="form-control"
                        id="name"
                        type="text"
                        name="name"
                        maxLength={100}
                        value={rec.name || ""}
                        onChange={e => setRec({ ...rec, name: e.target.value })}
                        placeholder="Give your monitor a descriptive name"
                        required
                    />
                </div>
                <div className="mb-4 col-md" style={{ maxWidth: "12em" }}>
                    <label className="form-label text-primary-emphasis"><b>Monitor Status</b></label>
                    <div>
                        <Toggle
                            offLabel={ <span data-tooltip="Use this to temporarily stop your monitor">Paused</span> }
                            onLabel="Active"
                            checked={rec.enabled !== false}
                            onChange={enabled => setRec({ ...rec, enabled })}
                            className="text-primary"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="mb-4 col-md">
                    <label htmlFor="host" className="form-label">
                        <b className="text-primary-emphasis">Host or IP</b>
                    </label>
                    <ValidatedInput
                        value={ rec.options?.host }
                        onChange={e => setRec({ ...rec, options: { ...rec.options, host: e.target.value }})}
                        className="form-control"
                        aria-errormessage={validator.isIP(rec.options?.host ?? "") || validator.isFQDN(rec.options?.host ?? "") ? undefined : "Invalid Host or IP Address"}
                        placeholder="Hostname or IP address"
                        required
                    />
                </div>
                <div className="mb-4 col-md">
                    <label htmlFor="port" className="form-label text-primary-emphasis"><b>Port</b></label>
                    <ValidatedInput
                        className="form-control"
                        id="port"
                        name="port"
                        type="number"
                        min={0}
                        max={65535}
                        value={rec.options?.port}
                        onChange={e => setRec({ ...rec, options: { ...rec.options, port: e.target.valueAsNumber }})}
                        aria-errormessage={validator.isPort(rec.options?.port + "") ? undefined : "Invalid Port Number"}
                        placeholder="The port to check"
                        required
                    />
                </div>
                <div className="mb-4 col-md" style={{ maxWidth: "12em" }}>
                    <label className="form-label text-primary-emphasis"><b>Expect Port to be</b></label>
                    <div>
                        <Toggle
                            checked={rec.options?.expectOpen !== false}
                            onChange={expectOpen => setRec({ ...rec, options: { ...rec.options, expectOpen } })}
                            onLabel="Open"
                            offLabel="Closed"
                            className={ classList({
                                "flip": true,
                                "text-success": rec.options?.expectOpen !== false,
                                "text-danger":  rec.options?.expectOpen === false
                            }) }
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <label htmlFor="description" className="form-label">
                    <b className="text-primary-emphasis">Description</b>
                </label>
                <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    rows={3}
                    value={rec.description || ""}
                    onChange={e => setRec({ ...rec, description: e.target.value })}
                    placeholder="Give your monitor a short description"
                />
            </div>
            <div className="mb-4">
                <label className="form-label">
                    <b className="text-primary-emphasis">Check Frequency</b>
                </label>
                <FrequencySelector value={ rec.frequency } onChange={frequency => setRec({ ...rec, frequency })}
                options={[
                    { frequency: 60 * 60 * 24 * 7 },
                    { frequency: 60 * 60 * 24 },
                    { frequency: 60 * 60 * 12 },
                    { frequency: 60 * 60      },
                    { frequency: 60 * 30, label: <span className="badge rounded-pill d-block text-bg-warning text-truncate" data-tooltip="Included with the Advanced or the Pro plan" data-tooltip-position="50% 100%">Advanced</span> },
                    { frequency: 60 * 5 , label: <span className="badge rounded-pill d-block text-bg-danger  text-truncate" data-tooltip="Included with the Pro plan" data-tooltip-position="50% 100%">Pro</span> },
                    { frequency: 60 , label: <span className="badge rounded-pill d-block text-bg-danger  text-truncate" data-tooltip="Included with the Pro plan" data-tooltip-position="50% 100%">Pro</span> },
                ]}
                />
            </div>
            <div className="mb-4 text-center bg-body-secondary bg-gradient border-secondary-subtle border-top rounded-bottom rounded-bottom-3 p-3">
                <button className="btn btn-primary bg-gradient px-5">Save</button>
            </div>
        </form>
    )
}
