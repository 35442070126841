import { createRoot }    from "react-dom/client"
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom"
import { Layout }        from "./Layout/Layout"
import { ErrorPage }     from "./Pages/ErrorPage"
import { Login }         from "./Pages/Login"
import { HomePage }      from "./Pages/Home"
import { FAQ }           from "./Pages/FAQ"
import { Help }          from "./Pages/Help"
import { Contact }       from "./Pages/Contact"
import { APIDocs }       from "./Pages/APIDocs"
import { Terms }         from "./Pages/Terms"
import { Affiliate }     from "./Pages/Affiliate"
import { Resources }     from "./Pages/Resources"
import { Pricing }       from "./Pages/Pricing"
import { Logout }        from "./Pages/Logout"
import Monitors          from "./Pages/Monitors"
import { Register }      from "./Pages/Register"
import Admin             from "./Pages/Admin"
import Teams             from "./Pages/Teams"
import Notifications     from "./Pages/Notifications"
import Account           from "./Pages/Account"
import Maintenance       from "./Pages/Maintenance"
import Invoices          from "./Pages/Invoices"
import Billing           from "./Pages/Billing"
import Tooltip           from "./Components/generic/Tooltip"
import Activate          from "./Components/Auth/Activate"
import { ResetPassword } from "./Components/Auth/ResetPassword"
import { AuthProvider, RequireAuth } from "./auth"
import Monitoring from "./Pages/Monitoring"


const router = createBrowserRouter([
    {
        id     : "root",
        path   : "/",
        element: process.env.MODE === "construction" || process.env.MODE === "maintenance" ? <Maintenance /> : <Layout><Outlet /></Layout>,
        errorElement: process.env.MODE === "construction" || process.env.MODE === "maintenance" ? <Maintenance /> : <Layout><ErrorPage /></Layout>,
        children: [
            { index: true             , Component: HomePage      },
            { path : "faq"            , Component: FAQ           },
            { path : "help"           , Component: Help          },
            { path : "contact"        , Component: Contact       },
            { path : "api"            , Component: APIDocs       },
            { path : "terms"          , Component: Terms         },
            { path : "affiliate"      , Component: Affiliate     },
            { path : "resources"      , Component: Resources     },
            { path : "pricing"        , Component: Pricing       },
            { path : "monitoring/*"   , Component: Monitoring    },

            { path : "register"       , Component: Register      },
            { path : "activate"       , Component: Activate      },
            { path : "reset"          , Component: ResetPassword },

            { path : "my/monitors/*"     , element  : <RequireAuth><Monitors /></RequireAuth> },
            { path : "my/teams/*"        , element  : <RequireAuth><Teams /></RequireAuth> },
            { path : "my/notifications/*", element  : <RequireAuth><Notifications /></RequireAuth> },
            { path : "my/account/*"      , element  : <RequireAuth><Account /></RequireAuth> },
            { path : "my/invoices/*"     , element  : <RequireAuth><Invoices /></RequireAuth> },
            { path : "my/billing/*"      , element  : <RequireAuth><Billing /></RequireAuth> },
            { path : "admin/*"           , element  : <RequireAuth roles={["admin", "manager"]}><Admin /></RequireAuth> },
        ]
    },
    { path: "/login" , element: process.env.MODE === "construction" || process.env.MODE === "maintenance" ? <Login /> : <Layout><Login /></Layout> },
    { path: "/logout", Component: Logout }
]);

function render() {
    createRoot(document.getElementById("app")).render(
        // <React.StrictMode>
            <AuthProvider>
                <Tooltip />
                <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
            </AuthProvider>
        // </React.StrictMode>
    );
}

// -----------------------------------------------------------------------------

if (process.env.NODE_ENV === "development") {
    fetch("/auth/csrf", {
        mode: "cors",
        credentials: "include"
    })
    .then(r => r.json())
    .then(j => {
        const node = document.querySelector('meta[name="csrf-token"]')!
        node.setAttribute("content", j.token)
    })
    .then(() => render())
} else {
    render()
}
