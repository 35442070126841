import { Link } from "react-router-dom"
// import { useBackend } from "../../hooks"
import Breadcrumbs from "../../Components/generic/Breadcrumbs"
import { humanizeDuration, request } from "../../lib"
import DataGrid from "../../Components/generic/DataGrid"
import { useState } from "react"


export default function AllMonitorsList() {

    const [selection, onSelectionChange] = useState<(number | string)[]>([])

    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "Administration"
                },
                {
                    name: "All Monitors"
                }
            ]}/>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="pe-2 text-truncate my-0">All Monitors</h2>
                {/* <div className="d-flex" style={{ marginRight: "-0.25rem", marginLeft: "-0.25rem" }}>
                    { selection.length > 0 && (
                        <Link className="btn btn-light border-dark border-opacity-10 m-1" to={ "./delete?id=" + selection.join(",") }>
                        <i className="bi bi-trash me-1 text-danger" />
                        Delete Selected
                        </Link>
                        ) }
                        <Link className="btn btn-light border-dark border-opacity-10 m-1" to="./new">
                        <i className="bi bi-plus-circle text-success me-1" />
                        New Monitor
                        </Link>
                        </div> */}
            </div>
            <hr className="mt-2 mb-4" />
            <DataGrid
                url="/api/v1/checks?all=true"
                identity="id"
                className="table-hover table-sm"
                selection={ selection }
                onSelectionChange={ onSelectionChange }
                columns={[
                    {
                        label     : "Name",
                        renderCell: row => <Link className="d-block text-truncate" to={`${row.id}`} >{ row.name }</Link>,
                        propName  : "name",
                        sortProp  : "name",
                        dataType  : "string",
                        tdProps: { style: { maxWidth: "20em" }}
                    },
                    {
                        label     : "Frequency",
                        sortProp  : "frequency",
                        propName  : "frequency",
                        dataType  : "number",
                        renderCell: row => <time dateTime={"PS" + row.frequency}>{humanizeDuration(row.frequency * 1000, { short: true })}</time>,
                        tdProps: { className: "d-none d-md-table-cell" },
                        thProps: { className: "d-none d-md-table-cell" }
                    },
                    {
                        label     : "Last Run",
                        sortProp  : "lastRun",
                        propName  : "lastRun",
                        dataType  : "date",
                        tdProps   : { className: "d-none d-md-table-cell" },
                        thProps   : { className: "d-none d-md-table-cell" }
                    },
                    {
                        label     : "Created",
                        sortProp  : "createdAt",
                        propName  : "createdAt",
                        dataType  : "date",
                        renderCell: row => <time dateTime={row.createdAt}>{ new Date(row.createdAt).toLocaleDateString() }</time>,
                        tdProps: { className: "d-none d-md-table-cell" },
                        thProps: { className: "d-none d-md-table-cell" }
                    }
                ]}
            />
        </div>
    );
}