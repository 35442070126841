import { useEffect, useLayoutEffect, useRef, useState } from "react"


interface ScalableSVGProps {
    attributes?: React.SVGProps<SVGSVGElement>
    children: (props: { width: number;  height: number }) => { svg: JSX.Element, html: JSX.Element }
    onMouseMove?: (x: number, y: number) => void
}

export default function ScalableSVG({ attributes, children, onMouseMove }: ScalableSVGProps)
{
    const svgRef = useRef(null);

    const [size, setSize] = useState({ width: 1000, height: 500, x: 0, y: 0 });
  
    const updateViewBox = () => {
      const svgElement = svgRef.current;
      if (svgElement) {
        const { width, height, x, y } = svgElement.getBoundingClientRect();
        setSize({ width, height, x, y });
      }
    };
  
    useLayoutEffect(() => {
        window.addEventListener('resize', updateViewBox);
        updateViewBox(); // Update viewBox on initial render
    
        return () => {
            window.removeEventListener('resize', updateViewBox);
        };
    }, []);

    useEffect(() => {
        if (onMouseMove) {
            const svgElement = svgRef.current;
        
            const handleMouseMove = (event) => {
                const rect       = svgElement.getBoundingClientRect();
                const scrollTop  = document.body.scrollTop;
                const scrollLeft = document.body.scrollLeft;
                const x          = event.clientX - rect.left + scrollLeft;
                const y          = event.clientY - rect.top  + scrollTop;
                onMouseMove(x, y)
            };

            const handleMouseOut = () => {
                onMouseMove(-1000, -1000)
            }
        
            svgElement.addEventListener('mousemove', handleMouseMove);
            svgElement.addEventListener('mouseout' , handleMouseOut );
        
            return () => {
                svgElement.removeEventListener('mousemove', handleMouseMove);
                svgElement.removeEventListener('mouseout' , handleMouseOut );
            };
        }
    }, [onMouseMove]);

    const { svg, html } = children(size)
  
    return (
        <div style={{ position: "relative" }}>
            <svg
                { ...attributes }
                ref={svgRef}
                viewBox={`0 0 ${size.width} ${size.height}`}
                preserveAspectRatio="xMidYMid meet"
                style={{
                    width: '100%',
                    height: 'auto',
                    overflow: "visible"
                }}
            >
                { svg }
            </svg>
            { html }
        </div>
    );
}