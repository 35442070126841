import { CSSProperties } from "react"


export default function Dialog({
    body,
    header,
    footer,
    className = "",
    style,
    onClose
} : {
    header     ?: JSX.Element | string
    className  ?: string
    body        : JSX.Element | string
    footer     ?: JSX.Element | string
    style      ?: CSSProperties
    onClose     : () => void
})
{
    return (
        <div className="modal fade show" tabIndex={-1} style={{ display: "block", background: "#0003" }}>
            <div className="modal-dialog">
                <div className="modal-content shadow" style={style}>
                    <div className={ className + " dialog" }>
                        { !!header && <div className="modal-header bg-light bg-gradient py-2">
                            <div className="modal-title fs-5">{ header }</div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Close" onMouseDown={() => onClose()}></button>
                        </div> }
                        <div className="modal-body">{ body }</div>
                        { !!footer && <div className="modal-footer bg-light py-2">{ footer }</div> }
                    </div>
                </div>
            </div>
        </div>
    )
}
