import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams }              from "react-router-dom"
import { Helmet, HelmetProvider }                    from "react-helmet-async"
import Loader                                        from "../Loader"
import ErrorAlert                                    from "../ErrorAlert"
import Breadcrumbs                                   from "../Breadcrumbs"
import { request }                                   from "../../../lib"
import { useBackend }                                from "../../../hooks"


export default function EndpointEditWrapper({
    endpoint,
    query,
    children
}: {
    endpoint: string
    query  ?: string
    children: (props: {
        loading : boolean
        data    : any
        response: Response
        onSubmit: (data: any) => void
        error: Error | string | null
    }) => JSX.Element
})
{
    const { id } = useParams()
    const navigate = useNavigate()
    const [saving, setSaving] = useState(false)
    const [saveError, setSaveError] = useState<Error|string|null>(null)
    const abortController = useMemo(() => new AbortController(), [])

    let url = `${endpoint}/${id}`

    if (query) {
        url += "?" + encodeURI(query.replace(/^\?/, ""))
    }

    let { result, loading, error } = useBackend(
        useCallback(signal => request(url, { signal }), [url]),
        true
    )

    useEffect(() => () => abortController.abort(), [ abortController ]);

    function onSubmit(data: Record<string, any>) {
        setSaving(true)
        setSaveError(null)
        request(`${endpoint}/${id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: { "content-type": "application/json" },
            signal: abortController.signal
        })
        .then(() => {
            if (!abortController.signal.aborted) {
                navigate("..")
            }
        })
        .catch(error => {
            if (!abortController.signal.aborted) {
                setSaveError(error)
                setSaving(false)
            }
        })
    }

    if (loading) {
        return <Loader message="Loading data..." />
    }

    if (error) {
        return <ErrorAlert>{ error }</ErrorAlert>
    }

    if (!result) {
        return <ErrorAlert>Failed fetching data from the server</ErrorAlert>
    }

    return children({
        loading : saving,
        data    : result.body,
        response: result.response,
        error   : saveError,
        onSubmit
    })
}

export function createEditPage<T = unknown>({
    nameSingular,
    namePlural,
    endpoint,
    nameField = "name",
    renderForm,
    query
}: {
    nameSingular     : string
    namePlural       : string
    endpoint         : string
    nameField       ?: string
    query           ?: string
    renderForm       : (props: {
        loading : boolean
        data    : T
        onSubmit: (data: Partial<T>) => void
    }) => JSX.Element
})
{
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Edit { nameSingular }</title>
                </Helmet>
            </HelmetProvider>
            <EndpointEditWrapper endpoint={ endpoint } query={ query }>
                {({ data, error, loading, onSubmit }) => {
                    console.log(data)
                    return (
                        <>
                            <Breadcrumbs links={[
                                { name: "Home", href: "/" },
                                { name: namePlural, href: "./../.." },
                                { name: data[nameField], href: "./.." },
                                { name: "Edit " + nameSingular }
                            ]} />
                            <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
                                <h4 className="pe-2 text-truncate my-0 text-primary-emphasis">
                                    <i className="bi bi-pencil-square me-2" />Edit { nameSingular }
                                </h4>
                                <div className="d-flex">
                                    <Link to="../" className="btn btn-sm btn-virtual">
                                        <i className="bi bi-card-list text-primary me-1" />
                                        View
                                    </Link>
                                    <div className="vr m-1" />
                                    <Link to="../../" className="btn btn-sm btn-virtual">
                                        <i className="bi bi-card-list text-primary me-1" />
                                        View All
                                    </Link>
                                    <div className="vr m-1" />
                                    <Link to="../../new" className="btn btn-sm btn-virtual">
                                        <i className="bi bi-plus-circle me-1 text-success" />
                                        Create
                                    </Link>
                                    <div className="vr m-1" />
                                    <Link to="../delete" className="btn btn-sm btn-virtual">
                                        <i className="bi bi-trash text-danger me-1" />
                                        Delete
                                    </Link>
                                </div>
                            </div>
                            <hr className="mt-1" style={{ marginBottom: 1 }} />
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 bg-dark small bg-opacity-10 bg-gradient py-1 px-2 rounded-bottom opacity-75" style={{
                                textShadow: "0 1px 0 #FFF"
                            }}>
                                <div className="nowrap me-1">
                                    <span className="text-muted">Created: </span>
                                    <span className="color-brand-2">{ new Date(data.createdAt).toLocaleString() }</span>
                                </div>
                                <div className="nowrap">
                                    <span className="text-muted">Updated: </span>
                                    <span className="color-brand-2">{ new Date(data.updatedAt).toLocaleString() }</span>
                                </div>
                            </div>
                            { error && <ErrorAlert>{ error }</ErrorAlert> }
                            { renderForm({ data: data as T, onSubmit, loading }) }
                        </>
                    )
                }}
            </EndpointEditWrapper>
        </div>
    )
}
