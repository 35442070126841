import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../auth";


export default function MenuButton({selection}: { selection?: (number | string)[] })
{
    const { user } = useAuth()
    const params   = useParams()
    const { id }   = params

    const isListAll = params["*"] === ""
    const isEdit    = params["*"] === id + "/edit"
    const isDelete  = params["*"] === id + "/delete"
    const isCreate  = params["*"] === "new"
    const isView    = params["*"] === id

    return (
        <div className="dropdown">
            <button className="btn btn-sm btn-virtual border-secondary border-opacity-10 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-list" />
            </button>
            <ul className="dropdown-menu dropdown-menu-end shadow">
                { !isListAll && <li>
                    <Link className="dropdown-item" to="/my/monitors">
                        <i className="bi bi-card-list text-muted me-2" />
                        List All
                    </Link>
                </li> }
                { !!id && !isView && <li>
                    <Link className="dropdown-item" to={`/my/monitors/${id}`}>
                        <i className="bi bi-file-earmark-text text-muted me-2" />
                        View
                    </Link>
                </li> }
                { !!id && !isEdit && <li>
                    <Link className="dropdown-item" to={`/my/monitors/${id}/edit`}>
                        <i className="bi bi-gear me-2 text-muted" />
                        Edit
                    </Link>
                </li> }
                { selection?.length > 0 && <li>
                    <Link className="dropdown-item" to={ "./delete?id=" + selection.join(",") }>
                        <i className="bi bi-trash me-2 text-danger" />
                        Delete Selected
                    </Link>
                </li> }
                { !isCreate && <>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <Link className="dropdown-item" to="/my/monitors/new?type=http">
                            <i className="bi bi-plus-circle text-muted me-2" />
                            Create <b>HTTP</b> Monitor
                        </Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="/my/monitors/new?type=port">
                            <i className="bi bi-plus-circle text-muted me-2" />
                            Create <b>Port</b> Monitor
                        </Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="/my/monitors/new?type=ping">
                            <i className="bi bi-plus-circle text-muted me-2" />
                            Create <b>Ping</b> Monitor
                        </Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="/my/monitors/new?type=domain">
                            <i className="bi bi-plus-circle text-muted me-2" />
                            Create <b>Domain</b> Monitor
                        </Link>
                    </li>
                    { user.role === "admin" && <li>
                        <Link className="dropdown-item" to="/my/monitors/new?type=browser">
                            <i className="bi bi-plus-circle text-muted me-2" />
                            Create <b>Browser</b> Monitor <span className="badge text-bg-warning">Demo</span> 
                        </Link>
                    </li> }
                </> }
                { !!id && !isDelete && <>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <Link className="dropdown-item" to={`/my/monitors/${id}/delete`}>
                            <i className="bi bi-trash me-2 text-danger" />
                            Delete
                        </Link>
                    </li>
                </> }
            </ul>
        </div>
    )
}