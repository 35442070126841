import { classList } from "../../../lib";

export default function ValidatedInput(props: React.InputHTMLAttributes<HTMLInputElement>)
{
    const error = props["aria-errormessage"]
    const invalid = props.value && !!error
    return (
        <>
            <input
                type="text"
                { ...props }
                className={ classList({ [props.className]: true, "is-invalid": invalid })}
            />
            { invalid && <div className="small text-danger">{error}</div> }
        </>
    );
}