import { NameValuePairsList } from "../../../lib";
import ParamList from "./ParamList";


export default function RequestBodyEditor({
    type = "none",
    value,
    onChange
}: {
    type?: "none" | "raw" | "x-www-form-urlencoded"
    value: string | null
    onChange: (x: { type: typeof type; value: typeof value }) => void
}) {
    return (
        <>
            <div className="mt-1">
                <label className="form-label me-4"><b>Body Type</b>:</label>
                <label className="form-label me-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        autoComplete="off"
                        checked={ type === "none" }
                        onChange={() => onChange({ type: "none", value })}
                    /> none
                </label>
                <label className="form-label me-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        autoComplete="off"
                        checked={ type === "raw" }
                        onChange={() => onChange({ type: "raw", value })}
                    /> raw
                </label>
                <label className="form-label me-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        autoComplete="off"
                        checked={ type === "x-www-form-urlencoded" }
                        onChange={() => onChange({ type: "x-www-form-urlencoded", value })}
                    /> x-www-form-urlencoded
                </label>
            </div>
            { type === "raw" && <textarea
                className="form-control"
                rows={4}
                placeholder="Body contents..."
                value={ value || "" }
                onChange={ e => onChange({ type, value: e.target.value }) }
            />}
            { type === "x-www-form-urlencoded" && <ParamList
                params={ NameValuePairsList.fromURLSearchParams(new URLSearchParams(value || "")) }
                onChange={ value => onChange({ type, value: NameValuePairsList.toURLSearchParams(value).toString() })}
            /> }
        </>
    )
}
