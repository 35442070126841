import Page from "../Page"

export function HomePage() {
    return <Page header="Home Page">
        <p>
            Get started today and experience the peace of mind that comes with
            knowing your web services are being thoroughly monitored and
            maintained with our HTTP Monitor App. Whether for development,
            testing, or production environments, this tool is your ultimate
            solution for web reliability and performance monitoring.
        </p>
        <br />
        <h4>Why Choose Our HTTP Monitor App?</h4>
        <ul>
            <li>
                <h5>User-Friendly Interface</h5>
                <p>Intuitive and easy to navigate, making it accessible for users of all skill levels.</p>
            </li>
            <li>
                <h5>Comprehensive Monitoring</h5>
                <p>Cover all bases with extensive monitoring capabilities that ensure your web services are up and running smoothly.</p>
            </li>
            <li>
                <h5>Customizable and Flexible</h5>
                <p>Tailor the app to fit your specific needs, from request methods to response validations.</p>
            </li>
            <li>
                <h5>Reliability and Control</h5>
                <p>Maintain full control over your monitoring activities with the ability to enable, disable, and configure every aspect of the monitoring process.</p>
            </li>
        </ul>
    </Page>
}