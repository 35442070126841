import { useLocation, useParams } from "react-router";
// import { useAuth } from "../../../auth";
import MenuButton from "./MenuButton";
import ViewAllMonitorsButton from "./ViewAllMonitorsButton";
import ViewMonitorButton from "./ViewMonitorButton";
import CreateMonitorMenuButton from "./CreateMonitorMenuButton";
import EditMonitorButton from "./EditMonitorButton";
import DeleteSelectedMonitors from "./DeleteSelectedMonitors";
import DeleteMonitorButton from "./DeleteMonitorButton";
import "./NavigationButtons.scss"


export default function NavigationButtons({selection}: { selection?: (number | string)[] })
{
    // const { user } = useAuth()
    const params   = useParams()
    // const { pathname } = useLocation()

    const { id }   = params
    // console.log("params:", params)
    // console.log("pathname:", pathname)

    const isListAll = params["*"] === ""
    const isEdit    = params["*"] === id + "/edit"
    const isDelete  = params["*"] === id + "/delete"
    const isCreate  = params["*"] === "new"
    const isView    = params["*"] === id

    return (
        <>
            <div className="d-none d-md-flex navigation-buttons">
                { !isListAll && <ViewAllMonitorsButton /> }
                { !!id && !isView && <ViewMonitorButton id={id} /> }
                { !isCreate && <CreateMonitorMenuButton /> }
                { !!id && !isEdit && <EditMonitorButton id={id} /> }
                { !!id && !isDelete && <DeleteMonitorButton id={id} /> }
                { selection?.length > 0 && <DeleteSelectedMonitors selection={selection} /> }
            </div>
            <div className="d-flex d-md-none">
                <MenuButton selection={selection} />
            </div>
        </>
    )
}