import Form               from "./Form"
import { createEditPage } from "../../Components/generic/Scaffolding/EndpointEditWrapper"
import { Team }           from "../../types"


export default function Edit()
{
    return createEditPage<Team>({
        endpoint    : "/api/v1/teams",
        namePlural  : "Teams",
        nameSingular: "Team",
        renderForm  : ({ loading, data, onSubmit }) => <>
            <Form data={data} onSubmit={onSubmit} />
        </>
    });
}
