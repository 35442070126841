import { PasswordInput }      from "./PasswordInput";
import { PasswordValidation } from "./PasswordValidation";


interface CreatePasswordProps {
    password1: string
    password2: string
    onPassword1Change: (p: string) => void
    onPassword2Change: (p: string) => void
    disabled?: boolean
}

export default function CreatePassword(props: CreatePasswordProps) {
    const {
        password1 = "",
        password2 = "",
        disabled = false,
        onPassword1Change,
        onPassword2Change
    } = props;

    return (
        <>
            <div className="row">
                <div className="col py-3">
                    <PasswordInput
                        id="password"
                        label="New Password *"
                        name="newPassword1"
                        autoComplete="new-password"
                        value={password1}
                        onChange={e => onPassword1Change(e.target.value)}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col py-3">
                    <PasswordInput
                        id="password2"
                        label="Repeat Password *"
                        name="newPassword2"
                        autoComplete="new-password"
                        value={password2}
                        onChange={e => onPassword2Change(e.target.value)}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="row mb-5">
                <div className="col text-secondary small">
                    <PasswordValidation password1={password1} password2={password2} />
                </div>
            </div>
        </>
    )
}
