import { User } from "../../types"


export default function NotifiedUsersList({ owner }: { owner: User })
{
    const users = {}

    function addUser(u: User) {
        const methods = []
        if (u.settings?.email?.enabled !== false) {
            methods.push("Email")
        }
        if (u.settings?.slack?.channel) {
            methods.push("Slack")
        }
        if (methods.length && !users[u.id]) {
            users[u.id] = { name: u.name, methods }
        }
    }

    addUser(owner)

    owner.OwnTeams?.forEach(team => {
        team.Members?.forEach(member => {
            addUser(member)
        })
    })

    return <div className="d-inline-block">{ Object.keys(users).map(id => {
        const rec = users[id]
        return (
            <span key={id} className="d-inline-block ps-2 pe-3 py-1 m-1 bg-light border rounded-pill text-nowrap">
                <i className="bi bi-person text-primary-emphasis me-1"/>
                <b className="text-primary-emphasis">{ rec.name }</b>
                <span className="text-muted"> - {rec.methods.join(", ")}</span>
            </span>
        )
    }) }</div>
}
