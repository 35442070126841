import { Link } from "react-router-dom"

export default function DeleteSelectedMonitors({ selection }: { selection: (string | number)[] }) {
    if (!selection.length) {
        return null
    }
    return (
        <Link className="btn btn-sm btn-virtual" to={ "./delete?id=" + selection.join(",") }>
            <i className="bi bi-trash me-1 text-danger" />
            Delete Selected
        </Link>
    )
}