import { classList } from "../../../lib"

export default function Alert({
    type = "light",
    children,
    dismissible = false,
    heading     = null,
    icon        = null,
    className 
}: {
    type       ?: "primary"|"secondary"|"success"|"danger"|"warning"|"info"|"light"|"dark"
    children    : React.ReactNode
    dismissible?: boolean
    icon       ?: React.ReactNode
    heading    ?: React.ReactNode
    className  ?: string
}) {
    return (
        <div className={ classList({
            [`alert alert-${type} fade show`]: true,
            [className]: true
        })} role="alert">
            { (icon || heading || dismissible) && (
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="alert-heading m-0">
                            { icon }
                            { heading }
                        </h5>
                        { dismissible && <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> }
                    </div>
                    <hr/>
                </>
            )}
            <div className="d-flex flex-column">
            { children }
            </div>
        </div>
    )
}