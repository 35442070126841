import { FormEvent, useState } from "react"
import { useLocation }         from "react-router-dom"
import ReadyToLogIn            from "./ReadyToLogIn"
import CreatePassword          from "./CreatePassword"
import Loader                  from "../generic/Loader"
import ErrorAlert              from "../generic/ErrorAlert"
import { request }             from "../../lib"
import { validatePasswordSet } from "../../../../isomorphic/validation/password-validation"


export default function ResetPasswordStage2() {
    const { search } = useLocation()
    const query      = new URLSearchParams(search)
    const code       = query.get("code")

    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [error    , setError    ] = useState<Error | string | null>(null)
    const [loading  , setLoading  ] = useState<boolean>(false)
    const [success  , setSuccess  ] = useState<boolean>(false)

    const validationError = validatePasswordSet(password1, password2);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        setError(null)

        try {
            const { response, body } = await request("/auth/reset-password", {
                method : "POST",
                headers: { "content-type": "application/json" },
                body   : JSON.stringify({ password1, password2, code })
            })

            if (response.status !== 200) {
                setError(body.message || body)
            } else {
                setSuccess(true)
            }

        } catch (ex) {
            setError(ex)
        }

        setLoading(false)
    }

    if (success) {
        return <ReadyToLogIn message="Password Changed Successfully!" />
    }

    return (
        <form onSubmit={onSubmit} style={{ maxWidth: "30em", margin: "auto" }}>
            <div className="text-center mt-5 mb-4">
                <h2>Choose New Password</h2>
                <hr />
                <p className="text-muted">Please create new password to activate your account</p>
            </div>
            { error && <ErrorAlert>{ error }</ErrorAlert> }
            <CreatePassword password1={password1} password2={password2} onPassword1Change={setPassword1} onPassword2Change={setPassword2} disabled={loading} />
            <hr />
            <div className="row mb-4">
                <div className="col col-12 text-center">
                    <button type="submit" className="btn px-4 btn-primary m-auto" disabled={loading || !!validationError}>
                        { loading ? <Loader small color="white" className="me-2" /> : <i className="bi bi-floppy2-fill me-2" /> }Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
