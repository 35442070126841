import PageHeader from "../../Components/PageHeader"
import Collapse from "../../Components/generic/Collapse"
import Page from "../Page"

export function FAQ() {
    const serviceName = "Vigilant"
    return <Page header={<PageHeader title="Frequently Asked Questions"/>}>
        <Collapse header={`What is ${serviceName}?`} collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                {serviceName} is an uptime monitoring service that provides
                HTTP monitoring, domain name and SSL certificate expiration
                monitoring, port monitoring, and ping monitoring to ensure
                your websites and services are up and running. We help you
                identify and resolve downtime issues quickly.
            </section>
        </Collapse>
        <Collapse header={`How does ${serviceName} work?`} collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                Our service continuously checks the availability and
                performance of your websites and services by sending HTTP
                requests, pinging IP addresses, and monitoring ports and
                domain names. If any issues are detected, you will be
                notified immediately.
            </section>
        </Collapse>
        <Collapse header="What types of monitoring do you offer?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                <p>
                    We offer four types of monitoring:
                </p>
                <ul>
                    <li><b>HTTP Monitoring:</b> Checks the availability and response time of your web pages.</li>
                    <li><b>Domain Name Monitoring:</b> Ensures your domain names are resolving correctly and are not about to expire soon.</li>
                    <li><b>Port Monitoring:</b> Verifies that specific ports are open and accessible.</li>
                    <li><b>Ping Monitoring:</b> Measures the latency and packet loss to your servers.</li>
                </ul>
            </section>
        </Collapse>
        <Collapse header="How often are the checks performed?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                The frequency of checks can be customized based on your needs.
                By default, checks are performed every minute, but you can
                adjust this interval in your settings.
            </section>
        </Collapse>
        <Collapse header="How will I be notified of downtime?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                You can choose to receive notifications via email or Slack.
                Other notification methods are currently being developed.
                Notifications can be configured for different alert levels and
                escalation policies to ensure the right people are informed promptly.
            </section>
        </Collapse>
        <Collapse header={`Can I monitor multiple websites and services?`} collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                Yes, you can monitor multiple websites and services under a
                single account. There are no limits on the number of monitors
                you can create, and each monitor can be customized with its
                own settings and alert policies.
            </section>
        </Collapse>
        <Collapse header="How do I set up monitoring for my website or service?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                Setting up monitoring is easy. After creating an account,
                log in to your dashboard and follow these steps:
                <ol>
                    <li>Click on "Add Monitor."</li>
                    <li>Select the type of monitor (HTTP, Domain, Port, Ping).</li>
                    <li>Enter the required details, such as URL or IP address, and customize the settings.</li>
                    <li>Save the monitor and start receiving alerts.</li>
                </ol>
            </section>
        </Collapse>
        <Collapse header="What should I do if I receive a downtime alert?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                If you receive a downtime alert, you should:
                <ul>
                    <li>Check your website or service to confirm the issue.</li>
                    <li>Investigate the cause of the downtime (e.g., server issues, network problems).</li>
                    <li>Resolve the issue as quickly as possible to minimize impact.</li>
                    <li>Monitor the service to ensure it returns to normal operation.</li>
                </ul>
            </section>
        </Collapse>
        <Collapse header={`Can I integrate ${serviceName} with other tools?`} collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                Yes, we offer integrations with various tools and platforms,
                including Slack, PagerDuty, and more. You can configure
                these integrations in your dashboard to streamline your
                alert management and incident response.
            </section>
        </Collapse>
        <Collapse header="Is there a free trial available?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                Yes, we offer a [number of days] free trial for new users.
                During the trial period, you can access all features of the
                service to evaluate its suitability for your needs.
            </section>
        </Collapse>
        <Collapse header="How do I cancel my subscription?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                You can cancel your subscription at any time by logging into
                your account and navigating to the subscription settings. If
                you need assistance, please contact our support team.
            </section>
        </Collapse>
        <Collapse header="What kind of support do you offer?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                We offer [24/7 email support, live chat support, or other
                types of support]. Our support team is available to help you
                with any questions or issues you may have.
            </section>
        </Collapse>
        <Collapse header={`Is my data secure with ${serviceName}?`} collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                Yes, we take data security very seriously. We use
                industry-standard encryption and security practices to protect
                your data. For more details, please refer to our Privacy Policy.
            </section>
        </Collapse>
        <Collapse header="How can I contact support?" collapsed headerClassName="pt-2 fs-5 fw-medium text-brand-3">
            <section className="mb-4 mt-1">
                You can contact our support team by [email, phone, or live chat].
                Visit our Contact Us page for more information and support hours.
            </section>
        </Collapse>
    </Page>
}