export function validatePassword(p: string) {
    if (p.length < 8) {
        return "Passwords must be at least 8 characters long"
    }
    if (p.length > 100) {
        return "Passwords must be not more than 100 characters long"
    }
    if (!p.match(/[A-Z]/)) {
        return "Passwords must contain at least one uppercase letter"
    }
    if (!p.match(/[a-z]/)) {
        return "Passwords must contain at least one lowercase letter"
    }
    if (!p.match(/[0-9]/)) {
        return "Passwords must contain at least one number"
    }
    if (!p.match(/(\$|@|,|#|-|!|%|~|&|\*|\^)/)) {
        return "Passwords must contain at least one special character ($ @ , - # ! % ~ & * ^)"
    }
}

export function validatePasswordSet(p1: string, p2: string) {
    const errorP1 = validatePassword(p1)
    if (errorP1) {
        return errorP1
    }
    if (p2 !== undefined && p1 !== p2) {
        return "The two passwords must match"
    }
}