import { FormEvent, useState }              from "react"
import validator                            from "validator"
import FrequencySelector                    from "./FrequencySelector"
import { MonitorModel, PingMonitorOptions } from "../../../types"
import ValidatedInput                       from "../../../Components/generic/ValidatedInput"
import Toggle                               from "../../../Components/generic/Toggle"


export function Form({
    data = {
        frequency: 60 * 60 * 12,
        options: {
            type: "ping",
            host: "",
        }
    },
    onSubmit
}: {
    data?: Partial<MonitorModel<PingMonitorOptions>>
    onSubmit: (props: typeof data) => void
}) {
    const [rec, setRec] = useState(data)

    function handleSubmit(e: FormEvent) {
        e.preventDefault()
        onSubmit(rec)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="text-secondary mb-5">
                A ping monitor is a tool used to check the availability and
                responsiveness of network devices or servers by sending ICMP
                echo requests (pings) and receiving ICMP echo replies. It works
                by periodically sending ping requests to the target device and
                measuring the round-trip time for the response. The main purpose
                of a ping monitor is to detect network connectivity issues,
                latency, and packet loss.
            </div>
            <div className="row">
                <div className="mb-4 col-md-12 col-lg">
                    <label htmlFor="name" className="form-label">
                        <b className="text-primary-emphasis">Name</b>
                    </label> 
                    <input
                        className="form-control"
                        id="name"
                        type="text"
                        name="name"
                        maxLength={100}
                        value={rec.name || ""}
                        onChange={e => setRec({ ...rec, name: e.target.value })}
                    />
                    <div className="form-text small">Give your monitor a descriptive name</div>
                </div>
                <div className="mb-4 col-md">
                    <label htmlFor="host" className="form-label">
                        <b className="text-primary-emphasis">Host or IP</b>
                    </label>
                    <ValidatedInput
                        value={ rec.options?.host }
                        onChange={e => setRec({ ...rec, options: { ...rec.options, host: e.target.value }})}
                        className="form-control"
                        aria-errormessage={validator.isIP(rec.options?.host ?? "") || validator.isFQDN(rec.options?.host ?? "") ? undefined : "Invalid Host or IP Address"}
                    />
                    <div className="form-text small">Enter a hostname or IP address to PING</div>
                </div>
                <div className="mb-4 col-md-auto">
                    <label className="form-label text-primary-emphasis"><b>Monitor Status</b></label>
                    <div>
                        <Toggle
                            offLabel={ <span data-tooltip="Use this to temporarily stop your monitor">Paused</span> }
                            onLabel="Active"
                            checked={rec.enabled}
                            onChange={enabled => setRec({ ...rec, enabled })}
                            className="text-primary"
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <label htmlFor="description" className="form-label">
                    <b className="text-primary-emphasis">Description</b>
                </label>
                <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    rows={3}
                    value={rec.description || ""}
                    onChange={e => setRec({ ...rec, description: e.target.value })}
                />
                <div className="form-text small">Give your monitor a short description</div>
            </div>
            <div className="mb-4">
                <label className="form-label">
                    <b className="text-primary-emphasis">Check Frequency</b>
                </label>
                <FrequencySelector value={ rec.frequency } onChange={frequency => setRec({ ...rec, frequency })} options={[
                    { frequency: 60 * 60 * 24 * 7 },
                    { frequency: 60 * 60 * 24 },
                    { frequency: 60 * 60 * 12 },
                    { frequency: 60 * 60      },
                    { frequency: 60 * 30, label: <span className="badge rounded-pill d-block text-bg-warning text-truncate" data-tooltip="Included with the Advanced or the Pro plan">Advanced</span> },
                    { frequency: 60 * 5 , label: <span className="badge rounded-pill d-block text-bg-danger  text-truncate" data-tooltip="Included with the Pro plan">Pro</span> },
                ]} />
            </div>
            <div className="mb-4 text-center bg-body-secondary bg-gradient border-secondary-subtle border-top rounded-bottom rounded-bottom-3 p-3">
                <button className="btn btn-primary bg-gradient px-5">Save</button>
            </div>
        </form>
    )
}
