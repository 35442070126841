
import { Link, NavLink } from "react-router-dom"
import logo              from "../../../isomorphic/images/logo.svg"
import { useAuth }       from "../auth"
import "../style.scss"


export function UserNavbar()
{
    return (
        <nav className="navbar navbar-expand-lg bg-primary-subtle bg-gradient sticky-top">
            <div className="container-xxl">
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive"><span className="navbar-toggler-icon" /></button>
                <Link className="navbar-brand" to="/" style={{ textShadow: "0 1px 0px #FFFC, 0 0 0 #000", color: "#049C" }}>
                    <img src={logo} style={{ height: "1.5em", width: "1.5em", verticalAlign: "bottom", lineHeight: "inherit" }} /> VIGILANT
                </Link>
                {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button> */}
                <div className="collapse navbar-collapse justify-content-md-between">
                    <ul className="navbar-nav">
                        {/* <li><NavLink className="nav-link text-primary-emphasis" to="/monitoring">Monitoring</NavLink></li> */}
                    </ul>
                    <input type="search" className="form-control" placeholder="Search" style={{ opacity: 0.3, width: "80%" }} />
                    <ul className="navbar-nav ps-2">
                        <li><Link className="nav-link text-primary-emphasis bg-primary bg-opacity-25 bg-gradient px-3 rounded" to="/logout">Log&nbsp;out</Link></li>
                    </ul>
                </div>
                <span />
            </div>
        </nav>
    )
}

export function UserSidebar()
{
    const { user } = useAuth()

    return (
        <div className="vi-sidebar" key={window.location.href}>
            <div
                className="offcanvas-lg offcanvas-start"
                tabIndex={-1}
                id="offcanvasResponsive"
                aria-labelledby="offcanvasResponsiveLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">Navigation</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body flex-column p-3 ps-lg-0 pe-lg-4">
                    {/* <h5 className="mt-2 mb-0 text-primary-emphasis"><i className="bi bi-person-fill me-1" />Me</h5> */}
                    {/* <hr className="mt-1 mb-2"/> */}
                    <nav className="nav nav-pills flex-column">
                        <NavLink className="nav-link bg-gradient" to="/my/monitors">My Monitors</NavLink>
                        <NavLink className="nav-link bg-gradient" to="/my/account">My Account</NavLink>
                        <NavLink className="nav-link bg-gradient" to="/my/teams">My Teams</NavLink>
                        <NavLink className="nav-link bg-gradient" to="/my/billing">Billing</NavLink>
                        <NavLink className="nav-link bg-gradient" to="/my/invoices">Invoices</NavLink>
                        <NavLink className="nav-link bg-gradient" to="/my/notifications">Notifications</NavLink>
                        <NavLink className="nav-link bg-gradient link-danger d-lg-none" to="/logout"><b>Log out <i className="bi bi-box-arrow-right ms-2" /></b></NavLink>
                    </nav>
                    { (user?.role === "admin" || user?.role === "manager") && <>
                        <h5 className="mt-4 mb-1 px-3 text-secondary">Administration</h5>
                        {/* <hr className="mt-1 mb-2 mx-3 color-primary h-2"/> */}
                        <nav className="nav nav-pills flex-column">
                            <NavLink className="nav-link bg-gradient" to="/admin/monitors">Monitors</NavLink>
                            <NavLink className="nav-link bg-gradient" to="/admin/history">History</NavLink>
                            <NavLink className="nav-link bg-gradient" to="/admin/incidents">Incidents</NavLink>
                            <NavLink className="nav-link bg-gradient" to="/admin/dashboard">Dashboard</NavLink>
                            <NavLink className="nav-link bg-gradient" to="/admin/config">Configuration</NavLink>
                        </nav>
                    </> }
                </div>
            </div>
        </div>
    )
}

export function UserFooter() {
    return (
        <div className="bg-light border-top">
            <div className="container-xxl py-4">
                <div className="d-flex flex-wrap justify-content-center">
                    <Link className="d-block p-2 text-decoration-none" to="/pricing">Pricing</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/affiliate">Affiliate&nbsp;program</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/terms">Terms&nbsp;&amp;&nbsp;Privacy</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/faq">FAQ</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/contact">Contact&nbsp;us</Link>
                    {/* <Link className="d-block p-2 text-decoration-none" to="/api">API</Link>
                    <Link className="d-block p-2 text-decoration-none" to="/help">Help&nbsp;center</Link> */}
                </div>
            </div>
        </div>
    )
}

export function UserLayout({ children })
{
    return (
        <>
            <UserNavbar/>
            <div className="d-flex flex-grow-1 container-xxl">
                <UserSidebar/>
                <div className="d-flex flex-grow-1 py-3 flex-column" id="main">
                    { children }
                </div>
            </div>
            <UserFooter />
        </>
    );
}
