import { Link }   from "react-router-dom"
import Page       from "../Page"
import Hr         from "../../Components/generic/Hr"
import PageHeader from "../../Components/PageHeader"


export function PortMonitoring() {
    return (
        <Page header={
            <PageHeader
                style={{ padding: "1rem 0" }}
                title="Introducing Your Ultimate Port Monitoring Solution"
                description="A port monitor is a network monitoring tool used to
                check the availability and status of network ports on devices
                within a network. Its primary function is to verify whether
                specific ports on target devices are open or closed, allowing
                network administrators to detect connectivity issues, service
                availability, and potential security vulnerabilities."
            />
        }>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className=" text-brand-3">IP and Hostname Monitoring</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Run checks against either IP addresses or hostnames,
                        ensuring comprehensive coverage of your network. This
                        flexibility makes it easy to monitor all your critical
                        endpoints.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Port Status Verification</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Verify whether a port is open or closed to maintain
                        optimal network performance. By regularly checking port
                        status, you can quickly identify and address potential
                        issues before they impact your operations.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Configurable Frequency</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Set the monitoring frequency to fit your specific needs.
                        Whether you need updates every minute or once a day,
                        Vigilant gives you full control over the timing of your
                        checks.
                    </p>
                </div>
            </div>
            <div className="row gx-2 my-3">
                <h4 className="bi bi-check-circle-fill col-auto text-brand-2" />
                <div className="col pe-2">
                    <h5 className="text-brand-3">Enable/Disable Functionality</h5>
                    <p className="text-primary-emphasis opacity-75">
                        Easily turn monitoring on or off with a simple toggle.
                        This feature allows you to pause and resume monitoring
                        at your convenience without losing your configurations.
                    </p>
                </div>
            </div>
            <Hr className="my-4 text-secondary opacity-25 fade-out"/>
            <div className="text-center">
                <Link className="btn btn-brand-2 bg-gradient px-4" to="/my/monitors/new?type=port">Create Port Monitor</Link>
            </div>
        </Page>
    )
}