
import { useCallback }            from "react"
import { Link }                   from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useParams }              from "react-router"
import { request }                from "../../../lib"
import { useBackend }             from "../../../hooks"
import ErrorAlert                 from "../ErrorAlert"
import Breadcrumbs                from "../Breadcrumbs"
import Loader                     from "../Loader"


export default function EndpointViewWrapper({
    endpoint,
    query,
    children
}: {
    endpoint: string
    query?: string
    children: (data: any) => JSX.Element
})
{
    const { id } = useParams()

    let url = `${endpoint}/${id}`

    if (query) {
        url += "?" + encodeURI(query.replace(/^\?/, ""))
    }
    
    let { result, loading, error } = useBackend(
        useCallback(signal => request(url, { signal }), [url]),
        true
    )

    if (loading) {
        return <Loader message="Loading data..." />
    }

    if (error) {
        return <ErrorAlert>{ error }</ErrorAlert>
    }

    if (!result) {
        return <ErrorAlert>Failed fetching data from the server</ErrorAlert>
    }

    return children(result.body)
}

export function createViewPage<T extends { createdAt: string, updatedAt: string }>({
    namePlural,
    endpoint,
    basePath,
    nameField = "name",
    renderView,
    icon = null,
    canUpdate = true,
    canDelete = true,
    query
}: {
    namePlural  : string
    endpoint    : string
    basePath    : string
    nameField  ?: string
    query      ?: string
    icon       ?: JSX.Element | null
    renderView  : (data: T) => JSX.Element
    canUpdate  ?: boolean
    canDelete  ?: boolean
})
{
    return (
        <EndpointViewWrapper endpoint={ endpoint } query={ query }>
            {(data: T) => {
                const name = data[nameField as keyof T] + ""
                return (
                    <>
                        <HelmetProvider>
                            <Helmet>
                                <title>{ name }</title>
                            </Helmet>
                        </HelmetProvider>
                        <Breadcrumbs links={[
                            { name: "Home", href: "/" },
                            { name: namePlural, href: basePath },
                            { name }
                        ]} />
                        <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
                            <h4 className="pe-2 text-truncate my-0 text-primary-emphasis">
                                { icon && <span className="me-2">{ icon }</span> }{ name }
                            </h4>
                            <div className="d-flex">
                                <Link to="../../" className="btn btn-sm btn-virtual">
                                    <i className="bi bi-card-list text-primary me-1" />
                                    View All
                                </Link>
                                <div className="vr m-1" />
                                <Link to="./edit" className="btn btn-sm btn-virtual">
                                    <i className="bi bi-pencil-square text-primary me-1" />
                                    Edit
                                </Link>
                                <div className="vr m-1" />
                                <Link to="./delete" className="btn btn-sm btn-virtual">
                                    <i className="bi bi-trash text-danger me-1" />
                                    Delete
                                </Link>
                            </div>
                        </div>
                        <hr className="mt-1" style={{ marginBottom: 1 }} />
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 bg-dark small bg-opacity-10 bg-gradient py-1 px-2 rounded-bottom opacity-75" style={{
                            textShadow: "0 1px 0 #FFF"
                        }}>
                            <div className="nowrap me-1">
                                <span className="text-muted">Created: </span>
                                <span className="color-brand-2">{ new Date(data.createdAt).toLocaleString() }</span>
                            </div>
                            <div className="nowrap">
                                <span className="text-muted">Updated: </span>
                                <span className="color-brand-2">{ new Date(data.updatedAt).toLocaleString() }</span>
                            </div>
                        </div>
                        { renderView(data) }
                    </>
                )
            }}
        </EndpointViewWrapper>
    )
}