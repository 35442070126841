import { Link } from "react-router-dom"


export default function ReadyToLogIn({ message }: { message: string }) {
    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <div className="flex-grow-1 text-center card shadow-sm" style={{ maxWidth: "30em" }}>
                <div className="card-body p-5">
                <h2>{ message }</h2>
                <hr/>
                <p className="my-4">You are now ready to login with your newly created password.</p>
                <Link to="/login" className="btn btn-primary px-5">Login</Link>
                </div>
            </div>
        </div>
    )
}
