import { Route, Routes } from "react-router"
import NotificationsForm from "./Form"


export default function Endpoint() {
    return (
        <Routes>
            <Route path="/" element={ <NotificationsForm /> } />
            <Route path="/slack/redirect" element={ <NotificationsForm /> } />
        </Routes>
    )
}