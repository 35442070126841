import { Fragment }         from "react/jsx-runtime"
import { ReactNode }        from "react"
import { humanizeDuration } from "../../../lib"


type FrequencyOption = {
    frequency: number
    label   ?: ReactNode
}

export default function FrequencySelector({
    value = 300,
    options = [
        { frequency: 60 * 60 * 24 * 7 },
        { frequency: 60 * 60 * 24 },
        { frequency: 60 * 60 },
        { frequency: 60 * 30 },
        { frequency: 60 * 5, label: <span className="badge rounded-pill d-block text-bg-warning text-truncate" data-tooltip="Included with the Advanced or Pro plans">Advanced</span> },
        { frequency: 60    , label: <span className="badge rounded-pill d-block text-bg-danger  text-truncate" data-tooltip="Included with the Pro plan">Pro</span> },
        { frequency: 10    , label: <span className="badge rounded-pill d-block text-bg-danger  text-truncate" data-tooltip="Included with the Pro plan">Pro</span> },
    ],
    onChange
}: {
    value?: number,
    options?: FrequencyOption[]
    onChange: (n: number) => void
}) {
    const cnt = options.length
    return (
        <div className="mb-1" style={{ background: "#0000" }}>
            <input type="range" className="form-range" min={0} max={cnt - 1}
                onChange={(e) => onChange(options[e.target.valueAsNumber].frequency)}
                value={options.findIndex(o => o.frequency === value)}
            />
            <div className="user-select-none position-relative d-flex justify-content-between mx-2 my-0 pb-5 text-center">
                { options.map((o, i) => {
                    const pct = i / (cnt - 1) * 100
                    return (
                        <Fragment key={i}>
                            <div  className="bg-body-secondary" style={{ width: 1, margin: "-0.5rem 0 0", height: "0.5rem" }} />
                            <small className="text-secondary" style={{
                                position: "absolute",
                                // top: "0.5rem",
                                left: pct + "%",
                                height: "auto",
                                // lineHeight: 2,
                                transform: i === 0 ? "translateX(-0.5rem)" : i === cnt - 1 ? "translateX(calc(-100% + 0.5rem))" : "translateX(-50%)",
                                // width: "3%",
                                // background: "#0002",
                                // margin: "0 0.5px 0 -0.5px",
                                // height: 10,
                                // background: "#CCC",
                                // padding: "10px 0 0 0",
                                // textAlign: "center",
                                // overflow: "visible"
                                // justifyItems: "center",
                            }}>
                                <small style={{ cursor: "pointer" }} onClick={() => onChange(o.frequency)}>{ humanizeDuration(o.frequency*1000, { short: true }) }</small>
                                {o.label}
                            </small>
                        </Fragment>
                    )
                }) }
            </div>
        </div>
    )
}
