import { Team }           from "../../types"
import { createViewPage } from "../../Components/generic/Scaffolding/EndpointViewWrapper"
import StaticDataGrid     from "../../Components/generic/DataGrid/StaticDataGrid"


export default function View() {
    return createViewPage<Team>({
        endpoint    : "/api/v1/teams",
        namePlural  : "Teams",
        icon        : <i className="bi bi-people text-primary-emphasis" />,
        // query       : "creator=true&graphs=true&subscriptions=true",
        basePath    : "/teams",
        canUpdate   : true,
        canDelete   : true,
        renderView  : data => <>
            { data.description && <p>{ data.description }</p> }
            <div className="row gap-2 mt-2 wrap">
                <div className="col mb-2">
                    <h5><i className="bi bi-people-fill text-primary-emphasis me-2" />Team Members</h5>
                    <hr className="m-0"/>
                    <StaticDataGrid
                        columns={[
                            {
                                propName: "name",
                                sortProp: "name",
                                label   : "Name",
                                dataType: "string"
                            },
                            {
                                propName: "email",
                                label   : "Email",
                                dataType: "string",
                                renderCell: row => <span className="text-primary">{ row.email }</span>
                            },
                            {
                                propName: "TeamMember.status",
                                label   : "Status",
                                dataType: "string",
                                renderCell: row => row.TeamMember.status === "pending" ?
                                    <span className="badge text-bg-warning">pending</span> :
                                    row.TeamMember.status === "rejected" ?
                                        <span className="badge text-bg-danger">rejected</span> :
                                        <span className="badge text-bg-success">accepted</span>
                            },
                            {
                                propName: "TeamMember.createdAt",
                                label   : "Invited",
                                dataType: "string",
                                renderCell: row => new Date(row.TeamMember.createdAt).toLocaleDateString()
                            }
                        ]}
                        rows={ data.Members }
                    />
                </div>
            </div>
        </>
    })
}
