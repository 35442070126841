import { Route, Routes } from "react-router-dom"
import AllTypes from "./AllTypes"
import { HttpMonitoring } from "./HttpMonitoring"
import { PingMonitoring } from "./PingMonitoring"
import { PortMonitoring } from "./PortMonitoring"
import { DomainMonitoring } from "./DomainMonitoring"

export default function Admin() {
    return (
        <Routes>
            <Route path="/">
                <Route index       Component={ AllTypes } />
                <Route path="http" Component={ HttpMonitoring } />
                <Route path="ping" Component={ PingMonitoring } />
                <Route path="port" Component={ PortMonitoring } />
                <Route path="domain" Component={ DomainMonitoring } />
            </Route>
        </Routes>
    )
}

