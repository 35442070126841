import { Helmet, HelmetProvider } from "react-helmet-async"
import { Link }                   from "react-router-dom"
import Breadcrumbs                from "../Breadcrumbs"


export default function ListPage({
    namePlural,
    children,
    icon = null,
    canCreate = true
}: {
    namePlural   : string
    icon        ?: JSX.Element | null
    canCreate   ?: boolean
    children     : JSX.Element
})
{
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>{ namePlural }</title>
                </Helmet>
            </HelmetProvider>
            <Breadcrumbs links={[
                { name: "Home", href: "/" },
                { name: namePlural }
            ]} />
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
                <h4 className="pe-2 text-truncate my-0 text-primary-emphasis">
                    { icon && <span className="me-2">{ icon }</span> }{ namePlural }
                </h4>
                <div className="d-flex">
                    <Link to="./new" className="btn btn-sm btn-virtual">
                        <i className="bi bi-plus-circle me-1 text-success" />
                        Create
                    </Link>
                </div>
            </div>
            <hr className="mt-1 mb-4" />
            { children }
        </div>
    )
}