
const STATUS_CODES = {
    '100': 'Continue',
    '101': 'Switching Protocols',
    '102': 'Processing',
    '103': 'Early Hints',
    '200': 'OK',
    '201': 'Created',
    '202': 'Accepted',
    '203': 'Non-Authoritative Information',
    '204': 'No Content',
    '205': 'Reset Content',
    '206': 'Partial Content',
    '207': 'Multi-Status',
    '208': 'Already Reported',
    '226': 'IM Used',
    '300': 'Multiple Choices',
    '301': 'Moved Permanently',
    '302': 'Found',
    '303': 'See Other',
    '304': 'Not Modified',
    '305': 'Use Proxy',
    '307': 'Temporary Redirect',
    '308': 'Permanent Redirect',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '402': 'Payment Required',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '406': 'Not Acceptable',
    '407': 'Proxy Authentication Required',
    '408': 'Request Timeout',
    '409': 'Conflict',
    '410': 'Gone',
    '411': 'Length Required',
    '412': 'Precondition Failed',
    '413': 'Payload Too Large',
    '414': 'URI Too Long',
    '415': 'Unsupported Media Type',
    '416': 'Range Not Satisfiable',
    '417': 'Expectation Failed',
    '418': "I'm a Teapot",
    '421': 'Misdirected Request',
    '422': 'Unprocessable Entity',
    '423': 'Locked',
    '424': 'Failed Dependency',
    '425': 'Too Early',
    '426': 'Upgrade Required',
    '428': 'Precondition Required',
    '429': 'Too Many Requests',
    '431': 'Request Header Fields Too Large',
    '451': 'Unavailable For Legal Reasons',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
    '505': 'HTTP Version Not Supported',
    '506': 'Variant Also Negotiates',
    '507': 'Insufficient Storage',
    '508': 'Loop Detected',
    '509': 'Bandwidth Limit Exceeded',
    '510': 'Not Extended',
    '511': 'Network Authentication Required'
}

export default function StatusCodesSelector({
    data,
    onChange
}: {
    data: Record<string, boolean>
    onChange: (d: typeof data) => void
}) {
    function allTrue(level: string) {
        Object.keys(STATUS_CODES).forEach(code => {
            if (code.startsWith(level)) {
                data[code] = true
            }
        })
        onChange(data)
    }
    
    function allFalse(level: string) {
        Object.keys(STATUS_CODES).forEach(code => {
            if (code.startsWith(level)) {
                data[code] = false
            }
        })
        onChange(data)
    }
    
    function allUnset(level: string) {
        Object.keys(STATUS_CODES).forEach(code => {
            if (code.startsWith(level)) {
                delete data[code]
            }
        })
        onChange(data)
    }

    return ["2", "3", "4", "5"].map(n => {
        const xxCode = n + "XX"
        return (
            <div key={xxCode} className="row">
                <div className="col-12">
                    <div className="border-bottom border-2 mt-3 mb-1 small py-1 border-secondary-subtle">
                        <div className="btn py-0 btn-sm btn-outline-primary disabled">{ xxCode }</div>
                        <div className="btn py-0 btn-sm btn-link text-success" title={ `Mark all ${xxCode} responses as successful` } onClick={() => allTrue(n)}>OK</div>
                        <div className="btn py-0 btn-sm btn-link text-danger" title={ `Mark all ${xxCode} responses as failures` } onClick={() => allFalse(n)}>Fail</div>
                        <div className="btn py-0 btn-sm btn-link text-secondary" title={ `Reset all ${xxCode} response code settings` } onClick={() => allUnset(n)}>Unset</div>
                    </div>
                </div>
                { Object.keys(STATUS_CODES).filter(code => code.startsWith(n)).map((code, i) => {
                    const name = STATUS_CODES[code]
                    return (
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6" key={i}>
                            <div className="row align-items-center" style={{ padding: "1px 0" }}>
                                <div className="col-auto small">
                                    <div className="btn-group btn-group-sm w-100">
                                        <input className="btn-check" id={code + "-1" } type="radio" checked={data[code] === true} onChange={() => onChange({ ...data, [code]: true })} />
                                        <label className="btn btn-outline-success py-0" htmlFor= { code + "-1" }>OK</label>
                                        <input className="btn-check" id={code + "-0" } type="radio" checked={data[code] === false}  onChange={() => onChange({ ...data, [code]: false })} />
                                        <label className="btn btn-outline-danger py-0" htmlFor= { code + "-0" }>Fail</label>
                                    </div>
                                </div>
                                <div className="col text-nowrap ps-0 small text-truncate">
                                    <b>{code}</b> <span>{name}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    })
}
