import { classList } from "../../../lib"

export default function Pagination({
    offset,
    limit,
    total,
    onChange
}: {
    offset: number
    limit : number
    total : number
    onChange: (offset: number) => void
}) {

    const curPageIndex = Math.floor(offset / limit)
    const totalPages   = Math.ceil(total / limit)

    if (totalPages < 2) {
        return null
    }

    return (
        <nav>
            <ul className="pagination justify-content-center m-0">
                <li className={ classList({ "page-item": true, disabled: offset < limit })}>
                    <button type="button" className="page-link" onClick={() => onChange(offset - limit)}>Prev</button>
                </li>

                {[curPageIndex - 3, curPageIndex - 2, curPageIndex - 1].filter(i => i >= 0).map(i => (
                    <li className="page-item" key={i}>
                        <button type="button" className="page-link" onClick={() => onChange(limit * i)}>{ i + 1 }</button>
                    </li>
                ))}

                <li className="page-item">
                    <span className="page-link disabled">{curPageIndex + 1}</span>
                </li>

                {[curPageIndex + 1, curPageIndex + 2, curPageIndex + 3].filter(i => i < totalPages).map(i => (
                    <li className="page-item" key={i}>
                        <button type="button" className="page-link" onClick={() => onChange(limit * i)}>{ i + 1 }</button>
                    </li>
                ))}
                
                <li className={ classList({ "page-item": true, disabled: offset + limit >= total })}>
                    <button type="button" className="page-link" onClick={() => onChange(offset + limit)}>Next</button>
                </li>
            </ul>
        </nav>
    )
}