import { FormEvent, useState } from "react"
import { request } from "../../lib"
import Loader from "../generic/Loader"
import { Link } from "react-router-dom"


export default function ResetPasswordStage1() {
    const [email   , setEmail   ] = useState("")
    const [deadline, setDeadline] = useState("")
    const [loading , setLoading ] = useState(false)
    const [error   , setError   ] = useState<Error | string | null>(null)

    async function handleSubmit(e: FormEvent) {
        e.preventDefault()

        setLoading(true)
        setError(null)

        try {
            const { response, body } = await request("/auth/reset", {
                method : "POST",
                headers: { "content-type": "application/json" },
                body   : JSON.stringify({ email })
            })

            if (response.status !== 200) {
                setError(body.message || body)
            } else {
                // console.log(body)
                setDeadline(body.activateUntil)
            }

        } catch (ex) {
            setError(ex)
        }

        setLoading(false)
    }

    if (deadline) {
        return <ResetPasswordEmailSent email={email} expires={deadline} />
    }

    return (
        <form
            className="d-flex flex-grow-1 align-self-center align-items-center"
            autoCapitalize="none"
            onSubmit={handleSubmit}
        >
            <div>
                <div className="justify-content-center">
                    <div className="mx-3" style={{ width: "30em", maxWidth: "90vw" }}>
                        <div className="card-body p-2 p-sm-5">
                            <h2 className="my-0 text-center text-danger">Reset Password</h2>
                            <hr />
                            <p className="text-muted">
                                Enter the email you used to login with and we
                                will send you a link to reset your password.
                            </p>
                            { error ? (
                                <div className="alert alert-danger small py-2">{ error + "" }</div>
                            ) : null}
                            <div className="input-group">
                                <input className="form-control" type="email" name="email" placeholder="Email address" value={email} onChange={e => setEmail(e.target.value)} disabled={loading} />
                                <button className="btn btn-primary bg-gradient px-3" type="submit" disabled={loading}>
                                    { loading ? <Loader small color="white" className="me-2" /> : <i className="bi bi-envelope me-2" /> }Send
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="pt-3 d-flex justify-content-center flex-wrap">
                        <Link to="/login" className="my-2 mx-4">Log-in</Link>
                        <Link to="/register" className="my-2 mx-4">Create&nbsp;Account</Link>
                    </div>
                </div>
            </div>
        </form>
    )
}

export function ResetPasswordEmailSent({ email, expires } : { email: string, expires: string }) {
    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <div className="flex-grow-1" style={{ maxWidth: "30em" }}>
                <div className="card shadow-sm">
                    <div className="card-body p-4">
                        <h3 className="text-start">
                            <i className="bi bi-envelope me-2 text-secondary opacity-75" />
                            Check Your Email
                        </h3>
                        <hr />
                        <p className="text-muted small">
                            We sent an email to <b className="text-primary">{email}</b> containing
                            a link that you need to follow to reset your password. Please also
                            check your <b>spam</b> folders.
                        </p>
                        <p className="text-muted small">
                            The password reset link is valid until: <b>{new Date(expires).toLocaleString()}</b>.
                        </p>
                    </div>
                </div>
                <div className="py-4 d-flex justify-content-center flex-wrap">
                    <Link to="/login" className="my-2 mx-4">Log-in</Link>
                    <Link to="/register" className="my-2 mx-4">Create&nbsp;Account</Link>
                </div>
            </div>
        </div>
    )
}