import { useState } from "react"
import { classList } from "../../lib"
import { validatePassword } from "../../../../isomorphic/validation/password-validation"


export interface PasswordInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label: string
    id: string
    validate?: boolean
}

export function PasswordInput(props: PasswordInputProps) {
    const [visible, setVisible] = useState(false)

    const { id, label, validate, ...rest } = props

    const validationError = validate && rest.value ? validatePassword(rest.value + "") : ""

    return (
        <>
            <label htmlFor={id} className="form-label">
                <b className="text-primary-emphasis">{ label }</b>
            </label>
            <div className="input-group">
                <input
                    id={id}
                    className={ classList({
                        "form-control": true,
                        // "is-valid"    : validate && rest.value && !validationError,
                        "is-invalid"  : validate && rest.value && !!validationError
                    })}
                    { ...rest }
                    type={ visible ? "text" : "password" }
                />
                <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible(!visible)}
                    data-tooltip={ visible ? "Click to hide password" : "Click to show password" }
                >
                    <i className={ visible ? "bi bi-eye-fill" : "bi bi-eye-slash-fill" } />
                </span>
            </div>
            { validate && validationError && <div className="small text-danger py-1">{validationError}</div>}
        </>
    )
}
