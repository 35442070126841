import React                     from "react"
import { CurrentUser }           from "./types"
import { Navigate, useLocation } from "react-router-dom"
import { request } from "./lib"


interface AuthProvider {
    user: CurrentUser | null
    error: Error | null
    loading: boolean
    login(formData: FormData, cb: VoidFunction): Promise<void>
    logout(): Promise<void>
}

let AuthContext = React.createContext<AuthProvider>(null!);

export function useAuth() {
    return React.useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode })
{
    let storedUser = JSON.parse(sessionStorage.getItem("user") || "null") as CurrentUser | null;

    const [user   , setUser   ] = React.useState<CurrentUser|null>(storedUser);
    const [error  , setError  ] = React.useState<Error|null>(null);
    const [loading, setLoading] = React.useState(false);

    async function _setUser(u: CurrentUser|null) {
        sessionStorage.setItem("user", JSON.stringify(u));
        setUser(u)
    }

    async function login(formData: FormData, cb: VoidFunction) {
        setLoading(true)
        try {
            const { response, body } = await request("/auth/login", {
                method: "POST",
                body: JSON.stringify(Object.fromEntries(formData)),
                credentials: "include",
                headers: { "content-type": "application/json" }
            })
            if (response.status === 200) {
                try {
                    _setUser(body)
                    setError(null)
                    cb()
                } catch (ex) {
                    _setUser(null)
                    setError(ex)
                }
            }
        } catch(ex) {
            _setUser(null)
            setError(ex)
        }
        // console.log(4)
        setLoading(false)
        // console.log(5)
    }

    async function logout() {
        if (user) {
            setLoading(true)
            await fetch("/auth/logout", { credentials: "include" }).catch(console.error)
            _setUser(null)
            setError(null)
            setLoading(false)
        }
    }

    return (
        <AuthContext.Provider value={{ user, login, logout, error, loading }}>
            {children}
        </AuthContext.Provider>
    );
}

export function RequireAuth({ children, roles }: { children: React.ReactNode, roles?: string[] }) {
    let auth     = useAuth();
    let location = useLocation();
  
    if (!auth.user || (roles && !roles.includes(auth.user.role))) {
        // Redirect them to the /login page, but save the current location they
        // were trying to go to when they were redirected. This allows us to
        // send them along to that page after they login, which is a nicer user
        // experience than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
}

