import { useState }         from "react"
import { Link }             from "react-router-dom"
import Breadcrumbs          from "../../Components/generic/Breadcrumbs"
import DataGrid             from "../../Components/generic/DataGrid"
import { humanizeDuration } from "../../lib"
import NavigationButtons    from "./NavigationButtons"


export default function MonitorsList() {

    const [selection, setSelection] = useState<(number | string)[]>([])

    return (
        <div>
            <Breadcrumbs links={[
                {
                    name: "Home",
                    href: "/"
                },
                {
                    name: "My Monitors"
                }
            ]}/>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="pe-2 text-truncate my-0">Monitors</h2>
                <NavigationButtons selection={selection} />
            </div>
            <hr className="my-1" />

            <DataGrid
                url="/api/v1/checks?attributes=id,name,options,lastRun,createdAt"
                selection={ selection }
                onSelectionChange={ setSelection }
                identity="id"
                className="table-sm table-hover"
                columns={[
                    {
                        label     : "Name",
                        propName  : "name",
                        sortProp  : "name",
                        dataType  : "string",
                        renderCell: rec => <Link className="d-block link-underline link-underline-opacity-0 link-underline-opacity-75-hover" to={`${rec.id}`}>{ rec.name }</Link>,
                        tdProps: { style: { maxWidth: "100%" }}
                    },
                    {
                        label     : "Frequency",
                        propName  : "frequency",
                        sortProp  : "frequency",
                        dataType  : "number",
                        renderCell: row => humanizeDuration(row.frequency * 1000, { short: true }),
                        tdProps: { className: "d-none d-md-table-cell" },
                        thProps: { className: "d-none d-md-table-cell" }
                    },
                    {
                        label     : "Last Run",
                        propName  : "lastRun",
                        sortProp  : "lastRun",
                        dataType  : "date",
                        renderCell: rec => rec.lastRun ? new Date(rec.lastRun).toLocaleString() : <span className="text-secondary">-</span>,
                        tdProps: { className: "d-none d-md-table-cell" },
                        thProps: { className: "d-none d-md-table-cell" }
                    },
                    {
                        label     : "Created",
                        propName  : "createdAt",
                        sortProp  : "createdAt",
                        dataType  : "date",
                        renderCell: row => new Date(row.createdAt).toLocaleDateString(),
                        tdProps: { className: "d-none d-md-table-cell" },
                        thProps: { className: "d-none d-md-table-cell" }
                    }
                ]}
            />

            {/* <div className="alert">You don't currently have any monitors configured!</div> */}
        </div>
    );
}