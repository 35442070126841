import { Point } from "./types"

export default class Rectangle
{
    x1: number
    y1: number
    x2: number
    y2: number

    constructor(x1: number, y1: number, x2 : number, y2: number)
    {
        this.x1 = x1
        this.y1 = y1
        this.x2 = x2
        this.y2 = y2
    }

    get width()
    {
        return this.x2 - this.x1
    }

    get height()
    {
        return this.y2 - this.y1
    }

    containsPoint(p: Point)
    {
        return (
            p.x >= this.x1 &&
            p.x <= this.x2 &&
            p.y >= this.y1 &&
            p.y <= this.y2
        )
    }
}