import { Link } from "react-router-dom"
import Section from "../../Components/generic/Section"
// import { useAuth } from "../../auth"


export default function Info()
{
    // const { user } = useAuth()

    return (
        <>
            <Section className="mt-1 mb-5" title="Account Info">
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            <b className="text-primary-emphasis">Name</b>
                        </label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Full name or alias"
                            maxLength={100}
                            // value={rec.name || ""}
                            // onChange={e => setRec({ ...rec, name: e.target.value })}
                        />
                        <div className="form-text small text-muted">
                            Your full name or alias -  used for display purposes
                            and within any communication
                        </div>
                    </div>
                </div>
            </Section>

            <Section className="mt-1 mb-5" title="Contact Info">
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                            <b className="text-primary-emphasis">Email</b>
                        </label>
                        <input
                            id="email"
                            type="email"
                            name="name"
                            className="form-control"
                            placeholder="Email"
                            disabled
                            // value={rec.name || ""}
                            // onChange={e => setRec({ ...rec, name: e.target.value })}
                        />
                        <div className="form-text small">
                            Your primary email used for display purposes and as a primary notification method.
                        </div>
                    </div>
                    {/* <div className="col-md-6 mt-4 mt-md-0">
                        <label htmlFor="phone" className="form-label">
                            <b className="text-primary-emphasis">Phone</b>
                        </label>
                        <input
                            id="phone"
                            type="text"
                            name="phone"
                            className="form-control"
                            placeholder="Phone number"
                            // value={rec.name || ""}
                            // onChange={e => setRec({ ...rec, name: e.target.value })}
                        />
                        <div className="form-text small text-muted">
                            Phone number for SMS / text message notifications
                        </div>
                    </div> */}
                </div>
            </Section>
            
            <Section className="mt-1 mb-5" title="Security">
                <p className="text-muted">You can change your password without having to log out.</p>
                <Link className="btn btn-primary" to="password">Change Password</Link>
            </Section>

            <Section className="mt-1 mb-5" title="Delete Account" color="danger">
                <p className="text-muted">
                    We will send you an email so that you can confirm your intention.
                    Once you do that we well delete all data associated with your account.
                    Please note that there will be no way to recover your data after that!
                </p>
                <Link className="btn btn-danger" to="password">Delete Account</Link>
            </Section>

            {/* <pre style={{ maxWidth: "92vw" }}>
            Current User Info<br/>
            <br/>
            - Full Name: { user.name }<br/>
            - Email: { user.email }<br/>
            { JSON.stringify(user, null, 4) }
            </pre> */}
        </>
    )
}