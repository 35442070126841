import { useState } from "react"
import Dialog       from "../../Components/generic/Dialog"


export default function InviteMemberDialog({
    onClose
}: {
    onClose: (data?: any) => void
}) {
    const [email, setEmail] = useState("")
    return <Dialog
        header={ <b className="fw-medium"><i className="bi bi-people-fill me-2"/>Invite Team Member</b> }
        onClose={ () => onClose() }
        body={ <div>
            {/* <div className="alert alert-danger py-2 px-3">This is a test error message</div> */}
            <p style={{ lineHeight: "normal" }} className="text-muted">
                An invitation email will be sent to the specified address.
                If and when the email owner accepts the invitation they
                will be added to your team.
            </p>
            <input
                type="email"
                className="form-control mb-2"
                placeholder="Email"
                autoFocus
                value={ email }
                onChange={ e => setEmail(e.target.value) }
            />
        </div> }
        footer={ <>
            <button className="btn btn-light px-4 w-25 border border-secondary-subtle" onClick={() => onClose()} type="button">Cancel</button>
            <button className="btn btn-primary px-4 w-25" disabled={ !email } onClick={() => onClose(email)}>Invite</button>
        </> }
    />
}
