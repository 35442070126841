import { useCallback, useEffect, useState } from "react"
import StaticDataGrid                       from "./StaticDataGrid"
import { DataGridProps }                    from "./types"
import { getPath }                          from "../../../lib"
import "./DataGrid.scss"


function matchesWhere(
    currentValue: string | number,
    operator: "eq"|"ne"|"iLike"|"notILike"|"gt"|"gte"|"lt"|"lte"|"in"|"notIn"|string,
    value: string
): Boolean {
    switch (operator) {
        case "eq":
            return value === currentValue
        case "ne":
            return value !== currentValue
        case "iLike":
            try {
                const re = new RegExp(value, "i")
                return !!String(currentValue || "").match(re)
            } catch {
                return false
            }
        case "notILike":
            try {
                const re = new RegExp(value, "i")
                return !String(currentValue || "").match(re)
            } catch {
                return false
            }
        case "gt":
            return currentValue > value;
        case "gte":
            return currentValue >= value;
        case "lt":
            return currentValue < value;
        case "lte":
            return currentValue <= value;
        case "in":
            return value.split(/\s*,\s*/).includes(currentValue + "");
        case "notIn":
            return !value.split(/\s*,\s*/).includes(currentValue + "");
    }
    return false
}

export default function LocalDataGrid(props: DataGridProps) {
    
    const {
        onSelectionChange,
        identity,
        selection,
        className,
        columns
    } = props
    
    const [sortColumn , setSortColumn] = useState("id")
    const [sortDir    , setSortDir   ] = useState<"asc"|"desc">("asc")
    const [offset     , setOffset    ] = useState(0)
    const [limit      , setLimit     ] = useState(props.limit || 15)
    const [where      , setWhere     ] = useState<any>({})
    const [error      , setError     ] = useState<Error | null>(null)
    const [data       , setData      ] = useState<any[]>(props.rows || [])
    

    const fetch = useCallback((options: {
        sortColumn?: string
        sortDir   ?: "asc"|"desc"
        offset    ?: number
        limit     ?: number
        where     ?: Record<string, { operator: string; value: string }>
    } = {}) => {
        let _rows = [...(props.rows || [])];

        // where ---------------------------------------------------------------
        if (options.where) {
            _rows = _rows.filter(row => {
                let hadSearch = false
                for (const col in options.where) {
                    const _value = getPath(row, col)
                    if (_value !== undefined) {
                        const { operator, value } = options.where[col]
                        if (value === "") {
                            continue
                        }
                        hadSearch = true
                        if (matchesWhere(_value, operator, value)) {
                            return true
                        }
                    }
                }
                return !hadSearch
            })
            setWhere(options.where)
        }

        // sort ----------------------------------------------------------------
        if (options.sortColumn) {
            const _sortColumn = options.sortColumn
            const _sortDir    = options.sortDir || sortDir
            const col = columns.find(c => c.sortProp === _sortColumn)!
            _rows = _rows.sort((a: any, b: any) => {
                const _a = getPath(a, _sortColumn)
                const _b = getPath(b, _sortColumn)
                const _d = _sortDir === "asc" ? -1 : 1
                if (col.dataType === "number") {
                    return (_b - _a) * _d
                }
                return String(_a || "").localeCompare(String(_b || "")) * _d
            })
            setSortColumn(_sortColumn)
            setSortDir(_sortDir)
        }
        
        // offset --------------------------------------------------------------
        if (options.offset || options.offset === 0) {
            _rows = _rows.slice(options.offset)
            setOffset(options.offset)
        }
        
        // limit ---------------------------------------------------------------
        if (options.limit) {
            _rows = _rows.slice(0, options.limit)
            setLimit(options.limit)
        }

        setData(_rows)
    }, [])

    useEffect(fetch, [])

    return <StaticDataGrid
        columns={ columns }
        className={ className }
        count={ (props.rows || []).length }
        offset={ offset }
        limit={ limit }
        rows={ data }
        sortColumn={ sortColumn }
        sortDir={ sortDir }
        selection={ selection }
        onSelectionChange={ onSelectionChange }
        identity={ identity }
        filters={ where }
        onPaginationChange={ offset => fetch({ offset }) }
        onSortChange={ (sortColumn, sortDir) => fetch({ sortColumn, sortDir, offset: 0 }) }
        onFilterChange={ where => fetch({ where }) }
        error={ error }
    />
}
